
import { useTeamState } from '@/modules/team/states';
import ZAvatarGradient from '@/shared/components/avatarGradient';
import { AvatarGroup, Tooltip } from '@nextui-org/react';
import { memo, useMemo } from 'react';
import { useControlViewersStore } from '@/modules/agent/states/control-viewers';
import { useLoggedInMember } from '@/modules/auth/states';

interface UserAvatarGroupProps {
  sessionId: string;
  control: string;
  className?: string;
}

const UserAvatarGroup = memo(({ sessionId, control, className }: UserAvatarGroupProps) => {
  const { team } = useTeamState();
  const { viewersMap } = useControlViewersStore();
  const loggedInUser  = useLoggedInMember();

  
  const controlViewers = useMemo(() => {
    const sessionViewers = viewersMap.get(sessionId);
    const viewers = sessionViewers?.get(control) || [];
    return viewers.filter(viewer => viewer.user_id !== loggedInUser?.member_id);
  }, [viewersMap, sessionId, control, loggedInUser?.member_id]);

  const viewerDetails = useMemo(() => 
    controlViewers.map(viewer => ({
      viewer,
      memberDetails: team.find(member => member.member_id === viewer.user_id)
    }))
  , [controlViewers, team]);

  if (controlViewers.length === 0) return null;

  const maxVisible = 3;
  const remainingCount = viewerDetails.length - maxVisible;

  return (
    <div className={'flex items-center'}>
      <AvatarGroup
        className="gap-1"
        max={maxVisible}
      >
        {viewerDetails.slice(0, maxVisible).map(({ viewer, memberDetails }) => (
          <Tooltip
            key={viewer.user_id}
            content={memberDetails?.name || 'Unknown User'}
            showArrow={true}
            placement="top"
            delay={0}
            closeDelay={0}
            classNames={{
              content: "bg-black/80 backdrop-blur-md text-white text-tiny",
              arrow: "bg-black/80 backdrop-blur-md",
            }}
          >
            <div className={`cursor-pointer ${className}`}>
              <ZAvatarGradient
                name={memberDetails?.name || ''} 
                size={24}
                textSize={10}
                
              />
            </div>
          </Tooltip>
        ))}
      </AvatarGroup>
      
      {remainingCount > 0 && (
        <Tooltip
          showArrow={true}
          placement="top"
          delay={0}
          closeDelay={0}
          classNames={{
            content: "bg-black/80 backdrop-blur-md text-white text-tiny p-2",
            arrow: "bg-black/80 backdrop-blur-md",
          }}
          content={
            <div>
              {viewerDetails.slice(maxVisible).map(({viewer, memberDetails}) => (
                <div key={viewer.user_id} className="whitespace-nowrap py-0.5">
                  {memberDetails?.name || 'Unknown User'}
                </div>
              ))}
            </div>
          }
        >
          <div className="cursor-pointer ml-1">
            <ZAvatarGradient
              name={`+${remainingCount}`}
              size={24}
              textSize={10}
              backgroundColor="#71717A"
              textColor="text-white"
            />
          </div>
        </Tooltip>
      )}
    </div>
  );
});

export default UserAvatarGroup;
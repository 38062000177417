import { ResponseModes } from "@/modules/agent/types";
import {
  QAFillerRequest,
  QuestionnaireMapping,
  QuestionnaireStructure,
  QuestionnaireStructureRequest,
} from "@/modules/agent/types/questionnaire";
import {
  QAReviewResponseCompletedResponse,
  RiskAssessmentComplete,
} from "@/modules/app/hooks/use-app-socket";
import { AiResponseType } from "@/shared/types/user";

import { DoraReviewResponse, RiskReviewResponse, SOC2GapReviewResponse } from "@/modules/agent/types/risk-and-gap";
import { VendorRiskResponseItem } from "@/modules/agent/types/vendorAssessment";
import { VendorIntakeFormData } from "@/modules/agent/types/vendorIntakeForm";

export enum AgentSessionStatus {
  PENDING = "pending",
  QUEUED = "queued",
  IN_PROGRESS = "in_progress",
  INPUT_NEEDED = "input_needed",
  COMPLETE = "complete",
  UNDER_REASSESSMENT = 'reassessment_in_progress'
}

export enum AgentSessionStepType {
  LOAD_TEMPLATE = "load_template",
  EXTRACT_CONTROLS = "extract_controls",
  GENERATE_RISK_ASSESSMENT_RESPONSE = "generate_risk_assessment_responses",
  EDIT_RESPONSE = "edit_response",
  PREPARE_REPORT = "prepare_report",
  QA_FILLER = "qa_filler",
  QA_EXCEL_STRUCTURE_MAPPING = "qa_structure",
  MANUAL_EXCEL_STRUCTURE_MAPPING = "manual_qa_structure",
  EXTRACT_QUESTIONS = "extract_questions",
  SOC2_TYPE2_AUDIT = 'soc2_type2_audit',  // old variants
  SOC2_TYPE2 = 'soc2_type2',              // new variants 
  SOC2_TYPE1 = 'soc2_type1',
  DORA_ASSESSMENT = "dora_assessment",
  MULTI_FILE_VENDOR_ASSESSMENT = "multi_file_vendor_assessment",
  MULTI_VENDOR_PROFILE = "mfva_vendor_profile",
  CONTROL_SELECTION = 'control_selection',
}

export enum AgentSessionType {
  RISK_ASSESSMENT = "risk_assessment",
  QA_FILLER = "qa_filler",
  SOC2_GAP = 'soc2_type2_audit',            // old
  SOC2_GAP_TYPE2 = 'soc2_type2',            // new
  SOC2_GAP_TYPE1 = 'soc2_type1',
  DORA_GAP = "dora_assessment",
  MULTI_FILE_VENDOR_ASSESSMENT = "multi_file_vendor_assessment",
}


export interface RiskAgentSession extends BaseAgentSession {
  type: AgentSessionType.RISK_ASSESSMENT;
  name: string;
  risk_assessment: RiskSessionCreateRequest;
}

export interface SOC2GapAgentSession extends BaseAgentSession {
  type: AgentSessionType.SOC2_GAP;
  name: string;
  soc2_type2_audit: CommonRagCreateRequest;
}

export interface SOC2Type2NewGapAgentSession extends BaseAgentSession {
  type: AgentSessionType.SOC2_GAP_TYPE2;
  name: string;
  soc2_type2: CommonRagCreateRequest;
}

export interface SOC2Type1GapAgentSession extends BaseAgentSession {
  type: AgentSessionType.SOC2_GAP_TYPE1;
  name: string;
  soc2_type1: CommonRagCreateRequest;
}

export interface DoraAgentSession extends BaseAgentSession {
  type: AgentSessionType.DORA_GAP;
  name: string;
  dora_assessment: CommonRagCreateRequest;
}

export interface MultiVendorAgentSession extends BaseAgentSession {
  type: AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT;
  name: string;
  multi_file_vendor_assessment: MultiFileVendorCreateRequest;
}

export interface QaAgentSession extends BaseAgentSession {
  type: AgentSessionType.QA_FILLER;
  name: string;
  qa_filler: QAFillerRequest;
  qa_structure: QuestionnaireStructureRequest;
}

export type QAFillerSessionStep = {
  type: AgentSessionStepType.QA_FILLER;
  data: {
    input: QAFillerRequest;
    output: QAReviewResponseCompletedResponse["qa_filler"];
  };
};

export type GenerateRiskAssessmentResponseStep = {
  type: AgentSessionStepType.GENERATE_RISK_ASSESSMENT_RESPONSE;
  data: {
    input: RiskSessionCreateRequest;
    output: RiskAssessmentComplete["risk_assessment_response"];
  };
};

export type EditResponseAgentSessionStep = {
  type: AgentSessionStepType.EDIT_RESPONSE;
  data: { url: string };
};

export type PrepareReportAgentSessionStep = {
  type: AgentSessionStepType.PREPARE_REPORT;
  data: { url: string };
};

export type CustommizeControlsAgentSessionStep = {
  type: AgentSessionStepType.CONTROL_SELECTION;
  data: { url: string };
};

export type ManualQAAgentStep = {
  type: AgentSessionStepType.MANUAL_EXCEL_STRUCTURE_MAPPING;
  data: {
    input?: QuestionnaireMapping;
    output?: QAFillerRequest;
  };
};

export type QAStructureAgentStep = {
  type: AgentSessionStepType.QA_EXCEL_STRUCTURE_MAPPING;
  data: {
    input: {
      excel_url: string;
    };
    output: { structure: QuestionnaireStructure[] };
  };
};

export type SOC2Type2AuditAgentStep = {
  type: AgentSessionStepType.SOC2_TYPE2_AUDIT;
  data: {
    input: CommonRagCreateRequest;
    output: {
      file_url: string;
      file_type: string;
    };
  };
};

export type SOC2Type1AgentStep = {
  type: AgentSessionStepType.SOC2_TYPE1;
  data: {
    input: CommonRagCreateRequest;
    output: {
      file_url: string;
      file_type: string;
    };
  };
}
export type SOC2Type2AgentStep = {
  type: AgentSessionStepType.SOC2_TYPE2;
  data: {
    input: CommonRagCreateRequest;
    output: {
      file_url: string;
      file_type: string;
    };
  };
}

export type MultiVendorProfileStep = {
  type: AgentSessionStepType.MULTI_VENDOR_PROFILE;
  data: {
    input: CommonRagCreateRequest;
    output: {
      file_url: string;
      file_type: string;
    };
    vendor_intake_form?: VendorIntakeFormData;
    source_urls: string[];
  };
};

export interface MultiFileVendorAssessmentStep {
  type: AgentSessionStepType.MULTI_FILE_VENDOR_ASSESSMENT;
  data: {
    input: CommonRagCreateRequest;
    output: {
      file_url: string;
      file_type: string;
    };
    vendor_intake_form?: VendorIntakeFormData;
    source_urls: string[];
  };
  status: string;
}

export type DoraAuditAgentStep = {
  type: AgentSessionStepType.DORA_ASSESSMENT;
  data: {
    input: CommonRagCreateRequest;
    output: {
      file_url: string;
      file_type: string;
    };
  };
};

export type GenericAgentSessionStep = {
  type:
  | AgentSessionStepType.LOAD_TEMPLATE
  | AgentSessionStepType.EXTRACT_CONTROLS
  | AgentSessionStepType.EXTRACT_QUESTIONS;
  data: null;
};

export type AgentSessionStep = (
  | QAFillerSessionStep
  | GenerateRiskAssessmentResponseStep
  | EditResponseAgentSessionStep
  | CustommizeControlsAgentSessionStep
  | PrepareReportAgentSessionStep
  | GenericAgentSessionStep
  | ManualQAAgentStep
  | QAStructureAgentStep
  | DoraAuditAgentStep
  | MultiVendorProfileStep
  | MultiFileVendorAssessmentStep
  | SOC2Type2AuditAgentStep
  | SOC2Type1AgentStep
  | SOC2Type2AgentStep
) & {
  id: string;
  agent_session_id: string;
  step: number;
  status: AgentSessionStatus;
  version: number;
  created_at: string;
  updated_at: string;
};

export type AgentSession =
  | RiskAgentSession
  | DoraAgentSession
  | QaAgentSession
  | SOC2GapAgentSession
  | SOC2Type2NewGapAgentSession
  | SOC2Type1GapAgentSession
  | MultiVendorAgentSession;

export interface BaseAgentSession {
  id: string;
  tag: string;
  version: number;
  organization_id: string;
  status: AgentSessionStatus;
  permissions: {
    owner: string;
    all: boolean;
    collaborators: string[];
  };
  created_at: string;
  updated_at: string;
  created_by: string;
}

export interface AgentSessions {
  session: AgentSession;
  steps: AgentSessionStep[];
  updated_at: string;
}

export interface AgentSessionsRequest {
  page_number: number;
  page_size: number;
  search?: string;
  filters?: {
    session_type: AgentSessionType | AgentSessionType[]
  }
}

export interface AgentSessionsResponse {
  success: boolean;
  message: string;
  data: {
    page_number: number;
    page_size: number;
    total_sessions: number;
    total_pages: number;
    sessions: AgentSessions[];
  };
}

export interface AgentSessionResponse {
  success: boolean;
  message: string;
  data: AgentSessions;
}

export interface AgentSessionCreateRequest {
  type: AgentSessionType;
  name: string;
  customize?: boolean;
  [AgentSessionType.RISK_ASSESSMENT]?: RiskSessionCreateRequest;
  [AgentSessionType.QA_FILLER]?: QAFillerRequest;
  [AgentSessionType.SOC2_GAP]?: Soc2SessionCreateRequest;
  [AgentSessionType.DORA_GAP]?: DoraSessionCreateRequest;
  [AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT]?: MultiFileVendorCreateRequest;
}

export interface Soc2SessionCreateRequest extends CommonRagCreateRequest { }

export interface RiskSessionCreateRequest extends CommonRagCreateRequest { }

export interface MultiFileVendorCreateRequest extends CommonRagCreateRequest {
  vendor_name: string;
  scope_of_integration: string;
}

export interface DoraSessionCreateRequest extends CommonRagCreateRequest { }

export interface RagCreateRequest {
  agent_session_id: string;
  agent_session_step_id: string;
  response_mode: ResponseModes;
}

// soc2_type1 and type2 are new soc2 variants and soc2_type2_audit is old one
export interface Soc2RagCreateRequest extends RagCreateRequest {
  task: 'soc2_type2_audit' | 'soc2_type2' | 'soc2_type1';
  rag_input: CommonRagCreateRequest;
}

export interface NistCsf2RagCreateRequest extends RagCreateRequest {
  task: "nist_csf_2_risk_assessment";
  rag_input: CommonRagCreateRequest;
}

export interface MultiFileVendorRagCreateRequest extends RagCreateRequest {
  task: "multi_file_vendor_assessment";
  rag_input: MultiFileVendorRagCreateRequestInput;
  vendor_intake_form?: VendorIntakeFormData;
}

export interface DoraRagCreateRequest extends RagCreateRequest {
  task: "dora_assessment";
  rag_input: CommonRagCreateRequest;
}

export interface ReassessmentOption {
  retained: string[];
}

export interface CommonRagCreateRequest {
  source_urls: string[];
  controls_url?: string
  response_quality?: AiResponseType | null;
  doc_type?: "json" | "excel" | null;
  assessment_type?: string;
  reassessment?: ReassessmentOption;
}

export interface MultiFileVendorRagCreateRequestInput
  extends CommonRagCreateRequest {
  vendor_intake_form?: VendorIntakeFormData;
}

export interface AgentSessionCreateResponse {
  success: boolean;
  message: string;
  data: AgentSessions;
}

export type AgentSessionStepUpdateRequest = AgentSessionStep;

export interface AgentSessionStepUpdateResponse {
  success: boolean;
  message: string;
  data: AgentSessionStep;
}

export type AddCollaboratorsToAgentSessionRequest = {
  url: string;
  data: {
    all: boolean;
    collaborators: string[];
  };
};

export interface FinalRiskReviewResponse extends RiskReviewResponse {
  approved: boolean;
  edited: boolean;
  id: string;
}

export interface FinalDoraReviewResponse extends DoraReviewResponse {
  approved: boolean;
  edited: boolean;
  id: string;
}

interface FinalGapReviewResponse extends SOC2GapReviewResponse {
  tsc_id: string;
  approved: boolean;
  edited: boolean;
  id: string;
}

export type AgentSessionControl = {
  agent_session_id: string;
  type: string;
  risk_assessment: FinalRiskReviewResponse[] | null;
  dora_assessment: FinalDoraReviewResponse[] | null;
  soc2_audit: FinalGapReviewResponse[] | null;
  multi_file_vendor_assessment: VendorRiskResponseItem[] | null;
  id: string;
  created_at: string;
  updated_at: string;
  version: number;
}

export type AgentSessionControlsResponse = {
  success: boolean;
  message: string;
  data: AgentSessionControl[]
}

import { useEffect } from "react";
import { getZaniaSocket } from "@/infra/sockets";
import { useControlViewersStore } from "../states/control-viewers";

export const useControlEventsSubscription = (sessionId: string) => {
	const { actions } = useControlViewersStore();

	useEffect(() => {
		try {
			const socket = getZaniaSocket();

			const handlers = {
				opened: (data: {
					control_id: string;
					agent_session_id: string;
					user_id: string;
				}) => {
					if (data.agent_session_id === sessionId) {
						actions.addViewer({
							user_id: data.user_id,
							control: data.control_id,
							session_id: data.agent_session_id,
						});
					}
				},
				closed: (data: {
					agent_session_id: string;
					control_id: string;
					user_id: string;
				}) => {
					if (data.agent_session_id === sessionId) {
						actions.removeViewer(
							data.agent_session_id,
							data.control_id,
							data.user_id,
						);
					}
				},
				editStarted: (data: {
					agent_session_id: string;
					control_id: string;
					user_id: string;
				}) => {
					if (data.agent_session_id === sessionId) {
						actions.setEditor(
							data.agent_session_id,
							data.control_id,
							data.user_id,
						);
					}
				},
			};

			socket.on("shared:doc:control:opened:notification", handlers.opened);
			socket.on("shared:doc:control:closed:notification", handlers.closed);
			socket.on("shared:doc:edit:initialized", handlers.editStarted);

			return () => {
				socket.off("shared:doc:control:opened:notification", handlers.opened);
				socket.off("shared:doc:control:closed:notification", handlers.closed);
				socket.off("shared:doc:edit:initialized", handlers.editStarted);
			};
		} catch (error) {
			console.error("Error in control events subscription:", error);
			return () => {};
		}
	}, [sessionId, actions]);
};

import {
  useAuthActions,
  useIsAuthenticated,
  useIsAuthenticationInProgress,
} from "@/modules/auth/states";
import ZaniaLogoLoader from "@components/page-loader";
import { useEffect, useMemo, useRef, useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { AppProtectedRoutes, AppUnprotectedRoutes } from "../routes";
// import useLoadDataOnAppLoad from "@/shared/hooks/use-load-data-start";
import { getMemberDetailsWithOrganization, getTeam, refreshAuthToken } from "@/modules/auth/requests";
import { setLoginStep, useLoginStep } from "@/modules/auth/states/login";
import { LOGIN_STEP } from "@/modules/auth/types";
import { setTeam } from "@/modules/team/states";
import { deleteCookie, getCookie } from "@/shared/utils/cookie-helper";
import { Worker } from "@react-pdf-viewer/core";
import { version as pdfJsVersion } from "pdfjs-dist";

function App() {
  const intervalRef = useRef<number | null>(null);
  const isAuthenticated = useIsAuthenticated();
  const isAuthenticationInProgress = useIsAuthenticationInProgress();
  const { setAuth } = useAuthActions();
  const [isSessionChecked, setIsSessionChecked] = useState(false);

  const loginStep = useLoginStep();
  const routes = useMemo(() => {
    return isAuthenticated &&
      !isAuthenticationInProgress &&
      loginStep !== LOGIN_STEP.SELECT_ORG
      ? AppProtectedRoutes
      : AppUnprotectedRoutes;
  }, [isAuthenticated, isAuthenticationInProgress, loginStep]);

  const router = useMemo(() => {
    return createBrowserRouter(routes);
  }, [routes]);

  useEffect(() => {
    // add worker for refresh token
    const worker = new window.Worker(new URL("/worker.js", import.meta.url));
    worker.postMessage("start");

    const checkSession = async () => {
      const session = getCookie('intermediate_session_token');

      if (session) {
        const member = await getMemberDetailsWithOrganization();
        if (member) {
          setAuth({
            isAuthenticated: true,
            isAuthenticationInProgress: false,
            loggedInMember: member?.member,
            loggedInOrganization: member?.organization,
          });
          const members = await getTeam();
          if (members && members.team_members) {
            setTeam(members.team_members);
          }
          worker.onmessage = async (event) => {
            if (event.data === "refresh") {
              await refreshAuthToken();
            }
          };

        }
        else {
          deleteCookie('intermediate_session_token');
          setLoginStep(LOGIN_STEP.MAIN)
          setAuth({
            isAuthenticated: false,
            isAuthenticationInProgress: false,
          });
        }
      }
      else {
        setLoginStep(LOGIN_STEP.MAIN)
        setAuth({
          isAuthenticated: false,
          isAuthenticationInProgress: false,
        });
      }
      setIsSessionChecked(true);
    }
    checkSession();



    const unsubscribe = () => {
      return () => worker.terminate();
    }
    return () => {
      unsubscribe();
    };
  }, [isAuthenticated, setAuth]);
  if (isAuthenticationInProgress || !isSessionChecked) {
    return <ZaniaLogoLoader />;
  }

  return (
    <Worker
      workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfJsVersion}/pdf.worker.min.js`}
    >
      <RouterProvider router={router} fallbackElement={<ZaniaLogoLoader />} />
    </Worker>
  );
}

export default App;

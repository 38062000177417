import { AgentRoutes } from "@/modules/agent/routes";
import Authenticate from "@/modules/auth/components/authenticate-token";
import Login from "@/modules/auth/components/login";
import { AgentSessionRoutes } from "@/modules/sessions/routes";
import { SettingsRoutes } from "@/modules/settings/routes";
import { TeamRoutes } from "@/modules/team/routes";
import { ROUTES } from "@/shared/constants/routes";
import { Navigate, RouteObject } from "react-router-dom";
import MainLayout from "../components/layout";

export const AppUnprotectedRoutes: RouteObject[] = [
  {
    path: ROUTES.AUTH,
    // lazy: importLazy(() => import("@/modules/auth/components/authenticate-token"),),
    Component: Authenticate
  },
  {
    path: ROUTES.LOGIN,
    // lazy: importLazy(() => import("@/modules/auth/components/login")),
    Component: Login
  },
  {
    path: ROUTES.SELECT_ORG,
    Component: Login
  },
  {
    path: "*",
    element: <Navigate to={ROUTES.LOGIN} replace={true} />,
  },
];

export const MainRoutes: RouteObject[] = [
  ...AgentRoutes,
  ...SettingsRoutes,
  ...TeamRoutes,
  ...AgentSessionRoutes,

  {
    path: "*",
    element: <Navigate to={ROUTES.AGENT} replace={true} />,
  },
];

export const AppProtectedRoutes: RouteObject[] = [
  {
    path: "/*",
    // lazy: importLazy(() => import("../components/layout")),
    Component: MainLayout,
    children: [...MainRoutes],
  },
];

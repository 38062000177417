import { get } from "@/infra/rest";
import { ListOfOrganizationByEmail } from "../types";
import appConfig from "@/shared/config/app";

export const startSsoConnection = async (connectionId: string) => {
  try {
    const redirectUrl = `${window.location.origin}/auth`;
    const publicToken = appConfig.stytchKey;
    const stytchBaseUrl = appConfig.stytchBaseUrl;
    if (stytchBaseUrl && redirectUrl && connectionId) {
      const ssoUrl = `${stytchBaseUrl}/v1/public/sso/start?connection_id=${connectionId}&public_token=${publicToken}
      &login_redirect_url=${redirectUrl}&signup_redirect_url=${redirectUrl}`;
      window.location.href = ssoUrl;
    }
  } catch (error) {
    throw new Error((error as Error).message);
  }
};


export const authenticateSso = async (token: string) => {
  try {
    const data = await get<any>({
      url: `/v0/auth/sso-authenticate/${token}`,
      isAuthRequired: false,
    });

    return data;
  } catch (err) {
    throw new Error('Failed to authenticate');
  }
}

export const getListOfOrganizationBasedOnEmail: (
  q: string,
) => Promise<string[]> = async (q) => {
  try {
    const organizations = await get<ListOfOrganizationByEmail>({
      url: `/v0/auth/sso/orgs?user_email=${q}`,
      isAuthRequired: false,
    });

    return organizations.payload;
  } catch (err) {
    console.error(err);
    throw new Error('Failed to get organization');
  }
};

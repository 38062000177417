export const DATE_TIME_DAYJS_FORMAT = "MMMM DD, YYYY, h:mm A";

export const DATE_DAYJS_FORMAT = "MMMM DD, YYYY";

export enum ENVTYPE {
  PRODUCTION = "production",
  DEVELOPMENT = "development",
  STAGING = "staging",
}

export const STYTCH_MEMBER = "stytch_member";

import { ManagerOptions, Socket, SocketOptions, io } from "socket.io-client";
import requestConfig from "../../shared/config/request";
import { getSessionJWT } from "@/infra/auth/helper";

export const getSocketOptions = (
): Partial<SocketOptions | ManagerOptions> => ({
  transports: ["websocket"],
  upgrade: true,
  rememberUpgrade: false,
  forceNew: true,
  closeOnBeforeunload: false,
  reconnection: true,
  reconnectionAttempts: 3,
  withCredentials: true,
  path: requestConfig.socketPath
});

export let mainSocket: Socket | undefined = undefined;

export const getZaniaSocket = (): Socket => {
  const token = getSessionJWT();

  if (!token) {
    throw new Error(
      "Failed to authenticate. Please try logging in again. Contact Zania support if the issue persists.",
    );
  }

  if (mainSocket) {
    return mainSocket;
  }

  mainSocket = io(`${requestConfig.socketUrl}/`, getSocketOptions());

  return mainSocket;
};

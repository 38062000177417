interface AppConfig {
  pylonAppId: string;
  stytchBaseUrl: string;
  stytchKey: string
}

let appConfig: AppConfig = {
  stytchKey: "public-token-test-e17c4fdf-87fb-4a2b-b4f7-8a6fa09aeb8d",
  pylonAppId: "de4334d7-df28-4ecb-9540-a0ea498e2528",
  stytchBaseUrl: "https://test.stytch.com",
};

const mode = import.meta.env.MODE;

if (mode === "development") {
  appConfig = {
    ...appConfig,
    stytchKey: "public-token-test-e17c4fdf-87fb-4a2b-b4f7-8a6fa09aeb8d",
    stytchBaseUrl: "https://test.stytch.com",
  };
} else if (mode === "production") {
  appConfig = {
    ...appConfig,
    stytchKey: "public-token-live-38b25091-8fc9-4d55-9232-bb3e07ae8117",
    // stytchKey: 'public-token-test-e17c4fdf-87fb-4a2b-b4f7-8a6fa09aeb8d',
    stytchBaseUrl: "https://live.stytch.com",
  };
} else if (mode === "staging") {
  appConfig = {
    ...appConfig,
    stytchKey: "public-token-live-38b25091-8fc9-4d55-9232-bb3e07ae8117",
    // stytchKey: 'public-token-test-e17c4fdf-87fb-4a2b-b4f7-8a6fa09aeb8d',
    stytchBaseUrl: "https://live.stytch.com",
  };
} else if (mode === "dev") {
  appConfig = {
    ...appConfig,
    stytchKey: "public-token-test-e17c4fdf-87fb-4a2b-b4f7-8a6fa09aeb8d",
    stytchBaseUrl: "https://test.stytch.com",
  };
}

export default appConfig;

/* eslint-disable @typescript-eslint/no-unsafe-call */
import { ZTableColumnProps } from "@/shared/components/table";

import { ZButton } from "@/shared/components/button";
import { ZTableDeprecrated } from "@/shared/components/table-deprecrated/TableDeprecrated";
import capitalizeFirstLetter from "@/shared/utils/capitalize-first-letter";
import { cn } from "@/shared/utils/classname-merger";
import { cloneDeep } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { RenderCellTypeEnum, tableConfigs } from "../../../constants/helpers";
import {
  AGENT_TYPES,
  AgentSpecificSubTypes,
  AgentSpecificTableRowData,
  ConfidenceTypes,
  MultiVendorAssessmentTypes,
  ReviewSourceTypes,
} from "../../../types/index.ts";
import { renderTableComponents } from "../../table-components";
import { useVendorRiskAssessment } from "../useVendorRiskAssessment/useVendorRiskAssessment";
import { useVendorRiskAssessmentStore } from "../useVendorRiskAssessment/useVendorRiskAssessmentStore";
import AddSubServicesPopup from "./AddSubServicesPopup";

import { useAgentSelector, useAgentStepData } from "@/modules/agent/states";
import { VENDOR_SUPPLY_CHAIN_SUBSERVICES, VendorRiskResponseItemForVendorSupplyChain, VendorSupplyChainInfrastructureItem, VendorSupplyChainSoftwareItem } from "@/modules/agent/types/vendorSupplyChain.ts";
import { AgentSessionStatus, AgentSessionStepType } from "@/modules/sessions/types";
import { addNotification } from "@/shared/states/notification";
import { useParams } from "react-router-dom";
import { IdParams } from "../Overview/Overview";

type AddServicesPopupMode =
  | { mode: "CLOSED" }
  | { mode: "ADD" }
  | {
    mode: "EDIT",
    subserviceType: typeof VENDOR_SUPPLY_CHAIN_SUBSERVICES[keyof typeof VENDOR_SUPPLY_CHAIN_SUBSERVICES],
    indexInSubserviceArray: number,
  }

const SupplyChain = () => {
  const { id } = useParams<keyof IdParams>() as IdParams;

  const [addServicesPopupMode, setAddServicesPopupMode] = useState<AddServicesPopupMode>({ mode: "CLOSED" });

  const { vendorSupplyChainData } = useVendorRiskAssessment();
  const { setVendorSupplyChain } = useVendorRiskAssessmentStore();
  const stepData = useAgentStepData(id);
  const { setSelectedId } = useAgentSelector.useActions();

  const isReportReady = useMemo(() => {
    return stepData?.find((step) => step.type === AgentSessionStepType.PREPARE_REPORT)?.status === AgentSessionStatus.COMPLETE;
  }, [stepData]);

  const handleDelete = useCallback((item: VendorRiskResponseItemForVendorSupplyChain, indexInValueArray: number) => {
    if (isReportReady) {
      return;
    }

    const temp = cloneDeep(vendorSupplyChainData);
    const findkeyIndex = temp.findIndex((d) => d.key === item.key);
    if (findkeyIndex > -1) {
      temp[findkeyIndex].value.value.splice(indexInValueArray, 1);
      setSelectedId(id, temp[findkeyIndex].key);
    }
    setVendorSupplyChain(temp);
    addNotification({
      type: "success",
      message: "Subservice deleted successfully",
    });
  }, [isReportReady, setVendorSupplyChain, vendorSupplyChainData]);

  const handleEdit = useCallback((item: VendorRiskResponseItemForVendorSupplyChain, indexInValueArray: number) => {
    if (isReportReady) {
      return;
    }

    setAddServicesPopupMode({
      mode: "EDIT",
      subserviceType: item.key,
      indexInSubserviceArray: indexInValueArray,
    });
  }, [isReportReady]);

  const rows = useMemo(() => {
    const newRowList: ISupplyChain[] = [];

    vendorSupplyChainData.forEach((categoryWiseSupplyChainItemList) => {
      categoryWiseSupplyChainItemList.value.value.forEach((supplyChainItem, idx) => {
        let subService: string;
        if (categoryWiseSupplyChainItemList.key === 'infrastructure') {
          subService = (supplyChainItem as VendorSupplyChainInfrastructureItem).infrastructure_provider;
        } else {
          subService = (supplyChainItem as VendorSupplyChainSoftwareItem).software;
        }

        const rowItem = {
          id: `${categoryWiseSupplyChainItemList.key};${idx}`,
          confidence: supplyChainItem.confidence,
          purpose: supplyChainItem.purpose as string,
          sources: supplyChainItem.sources?.map((src: ReviewSourceTypes) => src) ?? [],
          subServices: subService,
          type: capitalizeFirstLetter(categoryWiseSupplyChainItemList.key),
          handleDelete: () => handleDelete(categoryWiseSupplyChainItemList, idx),
          handleEdit: () => handleEdit(categoryWiseSupplyChainItemList, idx),
          isReportReady,
        } as ISupplyChain;

        newRowList.push(rowItem);
      });
    });

    return newRowList;
  }, [vendorSupplyChainData, handleDelete, handleEdit, isReportReady]);

  const tableColumns: ZTableColumnProps<
    AgentSpecificTableRowData<
      AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
      MultiVendorAssessmentTypes.VENDOR_SUPPLY_CHAIN_TABLE
    >
  >[] = useMemo(() => {
    return tableConfigs[MultiVendorAssessmentTypes.VENDOR_SUPPLY_CHAIN_TABLE].map((column) => ({
      ...column,
      renderCell:
        column.renderType === RenderCellTypeEnum.CUSTOM
          ? column.renderCell
          : (vendorSupplyChainData: AgentSpecificTableRowData<AGENT_TYPES, AgentSpecificSubTypes<AGENT_TYPES>>) =>
            renderTableComponents({
              renderType: column.renderType,
              data: vendorSupplyChainData,
              metaData: {
                approved: [],
                edited: [],
                agentId: "",
              },
              onClickAvatar: () => { },
              fieldName: column.fieldName,
            }),
    }));
  }, []);

  return (
    <div className="h-full w-full overflow-hidden flex flex-col pb-3 bg-white">
      {/* header */}
      <div className="flex flex-row justify-between p-6 pb-4">
        <div className="flex flex-row gap-2 items-center justify-center">
          <span className="text-sm font-medium">Subservices</span>
          <div className="p-1 bg-[#F4F4F5] px-2 rounded-lg">
            <p className="text-[10px] text-[#52525B] leading-4 font-medium">
              {rows.length}
            </p>
          </div>
        </div>
        {!isReportReady && (
          <div>
            <ZButton
              size="sm"
              color="primary"
              variant="bordered"
              onClick={() => setAddServicesPopupMode({ mode: "ADD" })}
              className="text-tiny p-0 px-2 h-7 rounded-md"
            >
              Add New
            </ZButton>
          </div>
        )}
      </div>

      {/* /table */}

      <div className="grow overflow-hidden flex">
        <div className="flex-1 flex flex-col bg-white overflow-auto px-6">
          <ZTableDeprecrated<ISupplyChain>
            className="border-1 border-[#E4E4E7] rounded-lg overflow-auto scroll-auto text-[#333333]"
            loading={false}
            rows={[...rows] as ISupplyChain[]}
            columns={tableColumns}
            isHeaderSticky={true}
            isCompact={true}
            removeWrapper={true}
            tableHeaderProps={{
              className: "shadow-none rounded-none",
            }}
            tableRowProps={(_, rowIndex) => {
              return {
                className: cn(
                  rowIndex === 0 ? "border-none" : "border-y",
                  "hover:bg-[#F2F2F2]",
                  "border-b-1 border-b-[#E4E4E7]",
                ),
                id: `table-row-${rowIndex}`,
              };
            }}
            classNames={{
              wrapper: "shadow-none rounded-none ",
            }}
            aria-label="Third-Party Supply Chain"
          />
        </div>
      </div>
      {addServicesPopupMode.mode === "ADD" && (
        <AddSubServicesPopup
          handleClose={() => setAddServicesPopupMode({ mode: "CLOSED" })}
          type="ADD"
        />
      )}
      {addServicesPopupMode.mode === "EDIT" && (
        <AddSubServicesPopup
          handleClose={() => setAddServicesPopupMode({ mode: "CLOSED" })}
          type="EDIT"
          editKey={addServicesPopupMode.subserviceType}
          editIdx={addServicesPopupMode.indexInSubserviceArray}
        />
      )}
    </div>
  );
};

export default SupplyChain;

export interface ISupplyChain {
  id: string;
  type: string;
  subServices: string;
  purpose: string;
  confidence: ConfidenceTypes;
  sources: ReviewSourceTypes[];

  // handler for the rows
  handleDelete: () => void;
  handleEdit: () => void;
  isReportReady: boolean;
}

import appConfig from "@/shared/config/app";

export const signInWithGoogleOauth = async () => {
  try {
    const publicToken = appConfig.stytchKey;
    const redirectUrl = `${window.location.origin}/auth`;
    const stytchBaseUrl = appConfig.stytchBaseUrl;
    if (stytchBaseUrl && redirectUrl && publicToken) {
      const stytchGoogleOauthUrl = `${stytchBaseUrl}/v1/b2b/public/oauth/google/discovery/start?public_token=${publicToken}&discovery_redirect_url=${redirectUrl}`;
      window.location.href = stytchGoogleOauthUrl;
    }
  } catch (error) {
    throw new Error((error as Error).message);
  }
};
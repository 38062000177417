import { useEffect } from "react";
import { getZaniaSocket } from "@/infra/sockets";
import { AgentSocketEvents } from "../types/events";
import { getAgentData, getAgentStateActions } from "../states";
import { addNotification } from "@/shared/states/notification";

export const useAgentNotifications = (agentId: string) => {
	const socket = getZaniaSocket();
	const { updateAgentMainData } = getAgentStateActions();

	useEffect(() => {
		if (!socket) return;

		const handleApprovalNotification = (data: {
			user_id: string;
			task: string;
			file_url: string;
			control_ids: string[];
			agent_session_id: string;
			agent_session_step_id?: string;
		}) => {
			const agentData = getAgentData(agentId);
			if (!agentData || data.agent_session_id !== agentId) return;

			updateAgentMainData(agentId, {
				...agentData.mainData,
				approvedIds: [
					...new Set([...agentData.mainData.approvedIds, ...data.control_ids]),
				],
				lastUpdate: {
					timestamp: Date.now(),
					isRemoteUpdate: true,
				},
			});

			if (data.control_ids.length > 1) {
				addNotification({
				  title: `${data.control_ids.length} Responses accepted`,
				  message: "Zania will auto-fill these in final report",
				  type: "success",
				});
			  } else {
				addNotification({
				  title: "Response accepted",
				  message: "Loading next response...",
				  type: "success",
				});
			  }
		};

		socket.on(
			AgentSocketEvents.CONTROL_APPROVED_NOTIFICATION,
			handleApprovalNotification,
		);

		return () => {
			socket.off(
				AgentSocketEvents.CONTROL_APPROVED_NOTIFICATION,
				handleApprovalNotification,
			);
		};
	}, [socket, agentId, updateAgentMainData]);
};

import { cn } from "@/shared/utils/classname-merger";
import { Spinner, Tooltip } from "@nextui-org/react";
import { Info, Sparkle } from "lucide-react";

export interface GenerateWithAIProps {
  contextText: string;
  existingText: string;
  handleClickForRefineControls: () => Promise<void>;
  handleClickForGenerateControls: () => Promise<void>;
  isLoadingAIResponse: boolean;
  type: "TESTING_PROCEDURE" | "CONTROL_DESCRIPTION";
  showRefine: boolean;
  showGenerate: boolean;

  disableRefine: boolean;
  disableRefineTooltipMessage: string | string[];

  disableGenerate: boolean;
  disableGenerateTooltipMessage: string | string[];
}

const GenerateWithAI = ({
  handleClickForRefineControls,
  handleClickForGenerateControls,
  showGenerate,
  showRefine,
  isLoadingAIResponse,
  contextText,
  disableGenerate,
  disableGenerateTooltipMessage,
  disableRefine,
  disableRefineTooltipMessage,
  existingText,
  type,
}: GenerateWithAIProps) => {
  // const [showAcceptAndRejectPopover, setShowAcceptAndRejectPopover] = useState(
  //   false
  // );
  // const [isError, setIsError] = useState(false);

  const handleClickRefine = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (!disableRefine) handleClickForRefineControls();
  };
  const handleClickGenerate = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();

    if (!disableGenerate) handleClickForGenerateControls();
  };

  return isLoadingAIResponse ? (
    <div>
      <Spinner size="sm" />
    </div>
  ) : (
    <div className="flex flex-row gap-4">
      {showGenerate && (
        // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
        <div
          className="cursor-pointer flex items-center gap-2"
          onClick={handleClickGenerate}
          onMouseDown={handleClickGenerate}
        >
          <div>
            {disableGenerate && disableGenerateTooltipMessage.length > 0 ? (
              <div>
                <DisableTooltipForRefineAndGenerate
                  tooltipMessage={disableGenerateTooltipMessage}
                  crossOffset={10}
                />
              </div>
            ) : (
              <Sparkle
                size={12}
                color={disableGenerate ? "#A1A1AA" : "#006FEE"}
              />
            )}
          </div>
          <div
            className={cn(
              "text-[12px] leading-5 text-[#006FEE] font-medium",
              disableGenerate && "text-[#A1A1AA]"
            )}
          >
            Create with AI
          </div>
        </div>
      )}

      {showRefine && (
        // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
        <div
          className="cursor-pointer flex items-center gap-[6px]"
          onClick={handleClickRefine}
          onMouseDown={handleClickRefine}
          // onKeyDown={handleClick}
        >
          <div>
            {disableRefine && disableRefineTooltipMessage.length > 0 ? (
              <div>
                <DisableTooltipForRefineAndGenerate
                  tooltipMessage={disableRefineTooltipMessage}
                  crossOffset={10}
                />
              </div>
            ) : (
              <Sparkle
                size={12}
                color={disableRefine ? "#A1A1AA" : "#006FEE"}
              />
            )}
          </div>
          <div
            className={cn(
              "text-[12px] leading-5 text-[#006FEE] font-medium",
              disableRefine && "text-[#A1A1AA]"
            )}
          >
            Refine with AI
          </div>
          {type === "CONTROL_DESCRIPTION" && (
            <div
              className={cn(
                "text-[12px] leading-5 text-[#A5A5A5]",
                disableRefine && "text-[#A1A1AA]"
              )}
            >
              (Highly Recommended)
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GenerateWithAI;

const DisableTooltipForRefineAndGenerate = ({
  tooltipMessage,
  crossOffset,
}: {
  tooltipMessage: string | string[];
  crossOffset?: number;
}) => {
  return (
    <Tooltip
      showArrow={true}
      // content={<div>{tooltipMessage}</div>}
      content={
        typeof tooltipMessage === "string" ? (
          tooltipMessage
        ) : (
          <div className="flex flex-col">
            {tooltipMessage.map((val) => (
              <div key={val}>{val}</div>
            ))}
          </div>
        )
      }
      delay={500}
      closeDelay={0}
      classNames={{
        content:
          "bg-black bg-opacity-80 backdrop-blur-md text-white text-tiny max-w-[600px]",
        arrow: "bg-black bg-opacity-80 backdrop-blur-md",
      }}
      crossOffset={crossOffset}
      placement="bottom-end"
    >
      <span>
        <Info size={16} strokeWidth={1.5} color="#A5A5A5" />
      </span>
    </Tooltip>
  );
};

import {
  AgentSessionStatus,
  AgentSessionStepType,
} from "@/modules/sessions/types";
import usePrevious from "@/shared/hooks/use-previous";
import { addNotification } from "@/shared/states/notification";
import { cn } from "@/shared/utils/classname-merger";
import { ZTooltip } from "@components/ZTooltip/ZTooltip";
import { Button } from "@nextui-org/react";
import { debounce } from "lodash";
import { Loader, XIcon } from "lucide-react";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { confidenceHelper } from "../../constants/helpers";
import {
  getAgentData,
  getAgentStateActions,
  useAgentSelector,
  useAgentStepData,
  useApprovedIds,
  useEditedIds,
  useSelectedReviewResponse,
} from "@/modules/agent/states";
import {
  AGENT_TYPES,
  AgentSubTypes,
  ConfidenceTypes,
  GapAssessmentTypes,
} from "@/modules/agent/types/index.ts";
import { handleAgentAutoSave } from "@/modules/agent/utils/autosave";
import { cleanConfidence } from "@/modules/agent/utils/clean-confidence";
import { getTagAndRiskString, getTagColor } from "@/modules/agent/utils/risk-control";
import type { IdParams } from "@/modules/agent/components/layout/header";
import EditSection from "@/modules/agent/components/review-responses/edit-section";
import UserAvatarGroup from "@/shared/components/avatar-group/userAvatarGroup";
import { useControlViewersStore } from "@/modules/agent/states/control-viewers";
import { useLoggedInMember } from "@/modules/auth/states";
import { useControlEvents } from "@/modules/agent/hooks/useControlEvents.ts";
import { getControlId } from "../../utils/get-control-id";
import { useControlEditStatus } from "@/modules/agent/hooks/useControlEditStatus";
import { useControlApproval } from "../../hooks/useControlApproval";

interface AgentResponseProps {
  closeSplitView: () => void;
  onClickExpand: () => void;
  isSelectedRowVisible?: boolean;
  selectedSourceIndex?: number | null;
  agentId: string;
  agentType: AGENT_TYPES;
  agentSubType: AgentSubTypes;
}

const AgentResponse = ({
  closeSplitView,
  onClickExpand,
  selectedSourceIndex,
  agentId,
  agentType,
  agentSubType,
  isSelectedRowVisible,
}: AgentResponseProps) => {
  const loggedInUser = useLoggedInMember();
  const { emitApproval } = useControlApproval(agentId);

  const { viewersMap } = useControlViewersStore();

  const responseContentRef = useRef<HTMLDivElement>(null);
  const { setApproved } = useAgentSelector.useActions();
  const { id } = useParams<keyof IdParams>() as IdParams;

  const { id: selectedId = "", data: selectedData } =
    useSelectedReviewResponse(agentId);

  const isEdited = useEditedIds(agentId).includes(selectedId);
  const isApproved = useApprovedIds(agentId).includes(selectedId);

  const stepData = useAgentStepData(id);

  const prepareReportStep = stepData?.find(
    (step) => step.type === AgentSessionStepType.PREPARE_REPORT,
  );
  const isReportReady =
    prepareReportStep?.status === AgentSessionStatus.COMPLETE;

  const confidence = useMemo(() => {
    const confidence = selectedData?.find((item) => item.key === "confidence");
    if (confidence) {
      return confidence.value as ConfidenceTypes;
    }
    return null;
  }, [selectedData]);

  const prevSelectedId = usePrevious(selectedId);
  const previousSelectedData = usePrevious(selectedData);

  const [showAutoSaveForId, setShowAutoSaveForId] = useState("");

  const autoSaveUpdatedData = useCallback(
    async () => {
      try {
        const agentData = getAgentData(id);
        const lastUpdate = agentData?.mainData.lastUpdate;
      
        if (lastUpdate?.isRemoteUpdate && Date.now() - lastUpdate.timestamp < 2000) {
          return;
        }

        // Set loading state immediately
        setShowAutoSaveForId(selectedId);

        await handleAgentAutoSave(agentId, agentType, agentSubType);

         // Only clear if we're still on same control
         if (selectedId === agentData?.mainData.selectedId) {
          setShowAutoSaveForId('');
        }
        
        const { updateAgentData } = getAgentStateActions();
        if (agentData) {
          updateAgentData(agentId, {
            ...agentData,
            mainData: {
              ...agentData.mainData,
              lastEditedField: undefined  // Clear the field
            }
          });
        }

      } catch (error) {
        console.error(error);
        setShowAutoSaveForId(''); // Clear on error
        addNotification({
          type: 'error',
          title: 'Failed to auto save response',
          message:
            (error as Error).message ??
            'Could not auto save your response. Please try again later.',
        });
      }
    },
    [agentId, agentType, agentSubType, selectedId, id]
  );


  const controlId = useMemo(() => {
    return getControlId(selectedData, agentType, agentSubType, selectedId);
  }, [selectedData, agentType, agentSubType, selectedId]);

  const { emitControlOpened, emitControlClosed } = useControlEvents(
    agentId,
    controlId,
    loggedInUser?.member_id
  );
  
  useLayoutEffect(() => {
    if (controlId && loggedInUser?.member_id) {
      emitControlOpened();
      return () => {
        emitControlClosed();
      };
    }
  }, [controlId, loggedInUser?.member_id, emitControlOpened, emitControlClosed]);

  
  const { isBeingEdited, editorName } = useControlEditStatus(agentId, selectedId);

  const autoSaveUpdatedDataDebounced = useMemo(
    () => debounce(autoSaveUpdatedData, 1000, { trailing: true }),
    [autoSaveUpdatedData]
  );

  useEffect(() => {
    if (selectedData) {
      // Set loading immediately when data changes
      const agentData = getAgentData(id);
      const lastEditedField = agentData?.mainData.lastEditedField;
       const lastUpdate = agentData?.mainData.lastUpdate;
     
      if (lastEditedField && 
        (!lastUpdate?.isRemoteUpdate || Date.now() - lastUpdate.timestamp > 2000)) {
      setShowAutoSaveForId(selectedId);
      void autoSaveUpdatedDataDebounced();
    }
    }

    return () => {
      autoSaveUpdatedDataDebounced.cancel();
    };
  }, [selectedData, selectedId, autoSaveUpdatedDataDebounced, id]);

  useEffect(() => {
    if (responseContentRef.current) {
      responseContentRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [selectedId]);

  const getDoraTag = (id: string | undefined) => {
    if (!id) return "";

    let tag: string;
    const idSplit = id ? id.split(".") : "";
    if (idSplit.length === 3) {
      const chapterRomanNum = idSplit[0].toUpperCase();
      tag =
        "Ch. " +
        chapterRomanNum +
        " Art. " +
        idSplit[1] +
        " Req. " +
        idSplit[2];
    } else {
      tag = id ? id.split(".").join(" ") : "N/A";
    }

    return tag;
  };

  const renderRowData = useCallback(() => {
    switch (agentType) {
      case AGENT_TYPES.RISK_ASSESSMENT: {
        const subcategory = selectedData?.find(
          (item) => item.key === "subcategory",
        );
        if (!subcategory) return null;

        const { tag, riskString } = getTagAndRiskString(
          subcategory.value as string,
        );

        return (
          <div className="px-4 pb-4">
            <div className="w-full p-2 bg-[#D6E5F8] bg-opacity-50 rounded-lg flex items-center gap-3">
              <p
                className={cn(
                  "text-[#333333] whitespace-nowrap grow w-fit max-w-fit min-w-fit bg-gray-100 rounded-xl text-[10px] leading-4 px-2 py-1",
                  getTagColor(tag ?? ""),
                )}
              >
                {tag}
              </p>
              <ZTooltip content={riskString || ""} placement="top-end">
                <p className="truncate text-tiny">{riskString}</p>
              </ZTooltip>
            </div>
          </div>
        );
      }
      case AGENT_TYPES.QUESTIONNAIRE: {
        const text = selectedData?.find(
          (item) => item.key === "question_text",
        )?.value;
        return (
          <div className="px-4 pb-4">
            <div className="w-full p-2 bg-[#D6E5F8] bg-opacity-50 rounded-lg flex items-center gap-3">
              <ZTooltip content={text || ""} placement="top-end">
                <p className="truncate text-tiny">{text}</p>
              </ZTooltip>
            </div>
          </div>
        );
      }
      case AGENT_TYPES.GAP_ASSESSMENT: {
        switch (agentSubType) {
          case GapAssessmentTypes.SOC2: {
            const soc2ControlLabel = selectedData?.find(
              (item) => item.key === "control",
            )?.value;
            return (
              <div className="px-4 pb-4">
                <div className="w-full p-2 bg-[#D6E5F8] bg-opacity-50 rounded-lg flex items-center gap-3">
                  <p
                    className={cn(
                      "text-[#333333] whitespace-nowrap grow w-fit max-w-fit min-w-fit bg-gray-100 rounded-xl text-[10px] leading-4 px-2 py-1",
                    )}
                  >
                    {
                      selectedData?.find((item) => item.key === "trust_id")
                        ?.value
                    }
                  </p>
                  <ZTooltip content={soc2ControlLabel} placement="top-end">
                    <p className="truncate text-tiny">{soc2ControlLabel}</p>
                  </ZTooltip>
                </div>
              </div>
            );
          }
          case GapAssessmentTypes.DORA: {
            const requirementMaybe = selectedData?.find(
              (item) => item.key === "requirement",
            );
            const controlIdMaybe = selectedData?.find(
              (item) => item.key === "control_id",
            );

            if (!requirementMaybe?.value) return null;

            const requirement = requirementMaybe.value as string;
            const tag = controlIdMaybe?.value
              ? getDoraTag(controlIdMaybe.value as string)
              : "";

            return (
              <div className="px-4 pb-4">
                <div className="w-full p-2 bg-[#D6E5F8] bg-opacity-50 rounded-lg flex items-center gap-3">
                  <p
                    className={cn(
                      "text-[#333333] whitespace-nowrap grow w-fit max-w-fit min-w-fit bg-gray-100 rounded-xl text-[10px] leading-4 px-2 py-1",
                    )}
                  >
                    {tag}
                  </p>
                  <ZTooltip content={requirement} placement="top-end">
                    <p className="truncate text-tiny">{requirement}</p>
                  </ZTooltip>
                </div>
              </div>
            );
          }
          default: {
            return null;
          }
        }
      }
      case AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT: {
        const vendorQuestionCategory = selectedData?.find(
          (item) => item.key === "key",
        )?.value as string;
        const vendorQuestion = selectedData?.find(
          (item) => item.key === "question",
        )?.value as string;

        return (
          <div className="px-4 pb-4">
            <div className="w-full p-2 bg-[#D6E5F8] bg-opacity-50 rounded-lg flex items-center gap-3">
              <p
                className={cn(
                  "text-[#333333] whitespace-nowrap grow w-fit max-w-fit min-w-fit bg-gray-100 rounded-xl text-[10px] leading-4 px-2 py-1",
                )}
              >
                {vendorQuestionCategory}
              </p>
              <ZTooltip content={vendorQuestion} placement="top-end">
                <p className="truncate text-tiny">{vendorQuestion}</p>
              </ZTooltip>
            </div>
          </div>
        );
      }
    }
  }, [agentType, agentSubType, selectedData]);

  return (
    <div className="w-full flex flex-col rounded-md h-full overflow-hidden border border-border ">
      <div className="bg-[#FEFEFE] flex flex-col justify-between border-b border-[#E4E4E7] ">
        
        <div className="flex pl-2 px-4 py-3 justify-between">
          <div className="flex items-center gap-2">
            <Button
              isIconOnly
              className="bg-transparent p-0 rounded-md"
              size="sm"
              onClick={closeSplitView}
            >
              <XIcon size={18} />
            </Button>
            <p className="text-sm font-medium leading-5 text-[#171717]">
              Response
            </p>
            {confidence && (
              <p className="pl-[6px] truncate pr-[8px] text-[10px] bg-white py-[2px] rounded text-[#67657A] border border-[#E4E4E7]">
                <span
                  className="rounded min-w-2 min-h-2 inline-block text-center p-[2px] mr-[4px]"
                  style={{
                    background:
                      confidenceHelper[cleanConfidence(confidence)]?.color,
                  }}
                ></span>
                {confidenceHelper[cleanConfidence(confidence)]?.label}
              </p>
            )}
            {isEdited && showAutoSaveForId !== selectedId && (
              <p className="text-tiny text-[#A5A5A5]">Edited</p>
            )}
            {showAutoSaveForId === selectedId && (
              <span className="flex items-center gap-1">
                <Loader className="w-4 h-4 animate-spin" />
                <p className="text-tiny text-[#A5A5A5] text-[8px]">Saving</p>
              </span>
            )}
          </div>
          <div className="flex gap-2">
            <UserAvatarGroup 
              sessionId={agentId} 
              control={controlId || ""} 
              className={isBeingEdited ? "p-1 rounded-full bg-gradient-to-r from-[#FF6496] to-[#812DD6]" : ""}
            />
            <Button
              size="sm"
              variant="bordered"
              color="primary"
              onClick={onClickExpand}
              className="text-tiny p-0 px-2 h-7 rounded-md"
            >
              Expand
            </Button>
            {!isReportReady && (
              <Button
                size="sm"
                color="primary"
                className="text-tiny p-0 px-2 1 h-7 rounded-md"
                isDisabled={isApproved}
                onClick={async () => {
                  await emitApproval(selectedId);
                  setApproved(agentId, { id: selectedId, setNext: true }); 
                }}
              >
                Accept Response
              </Button>
            )}
          </div>
        </div>
        {!isSelectedRowVisible && renderRowData()}
        {isBeingEdited && (
          <div className="bg-[#FFF3E0] h-[32px] rounded-md mx-4 mb-1 flex items-center justify-center">
            <p className="text-xs text-[#62420E]">
              This response is being edited by {editorName}. New edits from you might be lost.
            </p>
          </div>

        )}
      </div>
      <div
        className="grow bg-[#FAFAFA] overflow-auto max-h-[calc(100vh-200px)]"
        ref={responseContentRef}
      >
        {selectedData && (
          <div className="p-2 bg-[#FAFAFA] min-h-full overflow-auto flex flex-col gap-4">
            <EditSection
              selectedSourceIndex={selectedSourceIndex}
              agentId={agentId}
              agentType={agentType}
              agentSubType={agentSubType}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AgentResponse;


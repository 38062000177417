import PlusCircledIcon from "@/shared/icons/plus-circled";
import { addNotification } from "@/shared/states/notification";
import { cn } from "@/shared/utils/classname-merger";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Chip,
  Divider,
  Popover,
  PopoverContent,
  PopoverTrigger
} from "@nextui-org/react";
import { RotateCcw } from "lucide-react";
import { useEffect, useState } from "react";
import { FilterConfig } from "../../constants/filters";
import {
  useAgentData,
  useAgentSelector,
  useReviewResponseFilters,
} from "../../states";
import { AGENT_TYPES, AgentSubTypes, FilterOption } from "../../types/index.ts";
import { ZTooltip } from "@/shared/components/ZTooltip/ZTooltip.tsx";
import { isEqual } from "lodash";

interface FiltersProps {
  agentId: string;
  agentType: AGENT_TYPES;
  agentSubType: AgentSubTypes;
}

const AgentFilters = ({ agentId, agentSubType }: FiltersProps) => {
  const filters = useReviewResponseFilters(agentId);
  const { setFilters } = useAgentSelector.useActions();

  const [showFilter, setShowFilter] = useState(false);
  const [currentFilters, setCurrentFilters] = useState<FilterOption[]>(filters);

   // Reset filters when filter popover is closed
   useEffect(() => {
    if (showFilter === false) {
      setCurrentFilters(filters);
    }
  }
    , [showFilter]
  );

  const applyFilterHandler = () => {
    setFilters(agentId, currentFilters);
    setShowFilter(false);
    addNotification({
      type: "success",
      title: "Filter Applied",
      message: "Filter has been applied successfully",
    });
  };

  const resetFilters = () => {
    setCurrentFilters([]);
    setFilters(agentId, []);
  };

  const agentdata = useAgentData(agentId);

  return (
    <div className="rounded-md items-center max-h-9 h-9 border border-border flex min-w-80 max-w-[600px] hide-scrollbar">
      <Popover
        placement="bottom-start"
        offset={10}
        isOpen={showFilter}
        onOpenChange={(open) => {
          setShowFilter(open);
        }}
      >
        <PopoverTrigger>
          <Button
            variant="light"
            className=" rounded-none text-[#A1A1AA]"
            startContent={<PlusCircledIcon />}
          >
            Filter
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-4 rounded-md ">
          <div className="flex flex-col ">
            <div className="grow flex justify-between gap-32">
              {FilterConfig[agentSubType].map(({ category, options }) => {
                return (
                  <div key={category}>
                    <CheckboxGroup
                      label={category}
                      classNames={{
                        label: "text-sm font-medium",
                      }}
                      value={currentFilters.map((option) => option.id)}
                    >
                      {options.map((option) => {
                        return (
                          <Checkbox
                            key={option.label}
                            value={option.id}
                            className="text-sm"
                            classNames={{
                              base: cn(
                                "inline-flex max-w-md w-full bg-content1 m-0",
                                "hover:bg-content2 items-center justify-start",
                                "cursor-pointer rounded-lg gap-2 p-4 py-1 border-2 border-transparent"
                              ),
                              label: "w-full",
                            }}
                            onChange={() => {
                              setCurrentFilters((prev) => {
                                const updated = [...prev];
                                const index = updated.findIndex(
                                  (filter) => filter.id === option.id
                                );
                                if (index > -1) {
                                  updated.splice(index, 1);
                                } else {
                                  updated.push(option);
                                }
                                return updated;
                              });
                            }}
                            isSelected={currentFilters.includes(option)}
                          >
                            <p
                              className={cn(
                                "text-sm flex items-center gap-1 p-1 px-2 rounded",
                                option.className
                              )}
                            >
                              {option.label}
                            </p>
                          </Checkbox>
                        );
                      })}
                    </CheckboxGroup>
                  </div>
                );
              })}
            </div>
            <div className="flex justify-end mt-4 gap-2">
              <Button
                variant="bordered"
                size="sm"
                isDisabled={filters.length ? false : true}
                onClick={resetFilters}
                className="border-[1px] border-[#F31260] text-[#F4286F] text-sm rounded-[6px] px-3 items-center font-medium"
              >
                Reset
              </Button>
              <Button
                color="primary"
                size="sm"
                isDisabled={!isEqual(currentFilters, filters) ? false : true}
                onClick={applyFilterHandler}
                className="border-[1px] text-sm rounded-[6px] px-3 items-center font-medium"
              >
                Apply
              </Button>
            </div>
          </div>
        </PopoverContent>
      </Popover>
      <Divider orientation="vertical" />
      <div className="grow flex items-center gap-2 px-3 w-[360px] overflow-hidden">
        {filters.length > 0 ? (
          <>
            <div className="flex justify-between items-center gap-x-6 w-full">
              <div className="gap-x-2 items-center flex flex-row">
                <div className="flex gap-x-[4px]">
                  {filters.slice(0, 2).map((filter) => {
                    const style = `border-[1px] `;
                    return (
                      <Chip
                        key={filter.id}
                        onClose={() => {
                          let updatedFilters: typeof filters = [];
                          setCurrentFilters((prev) => {
                            const updated = [...prev];
                            const index = updated.findIndex(
                              (filter_) => filter_.id === filter.id
                            );
                            if (index > -1) {
                              updated.splice(index, 1);
                            }
                            updatedFilters = updated;
                            return updated;
                          });
                          setFilters(agentId, updatedFilters);
                        }}
                        style={{
                          borderColor: "#7A788E",
                          background: "#F2F2F4",
                          color: "#7A788E",
                          paddingTop: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          paddingRight: "4px",
                          fontSize: "10px",
                          lineHeight: "12px",
                        }}
                        className={style}
                      >
                        <span>{filter.label}</span>
                      </Chip>
                    );
                  })}
                </div>
                <div className="h-full flex items-center">
                  {filters.length > 2 && (
                    <ZTooltip
                      showArrow={true}
                      content={filters.slice(2).map(({ label, id }) => (
                        <p key={id} className="text-white text-tiny">
                          {label}
                        </p>
                      ))}
                      placement="top"
                      delay={0}
                    >
                      <p className="text-[#006fee] text-[12px] font-medium">
                        +{filters.length - 2}
                      </p>
                    </ZTooltip>
                  )}
                </div>
              </div>
              <div>
                <RotateCcw
                  className="hover:cursor-pointer"
                  onClick={resetFilters}
                  size={16}
                  color={"#A1A1AA"}
                />
              </div>
            </div>
          </>
        ) : (
          <p className="text-[#A1A1AA] text-sm">Select</p>
        )}
      </div>
    </div>
  );
};

export default AgentFilters;

import { Button } from "@nextui-org/react";
import { getAgentData, getAgentStateActions, useAgentSelector } from "../../states";
import { useControlApproval } from "../../hooks/useControlApproval";
import { addNotification } from "@/shared/states/notification";
interface AgentActionsProps {
  selectedRows: string[];
  resetSelectedRows: () => void;
  agentId: string;
  isApproved: boolean;
  selectedId: string;
}

const AgentActions = ({
  selectedRows,
  resetSelectedRows,
  agentId,
  isApproved,
  selectedId,
}: AgentActionsProps) => {
  const { emitApproval } = useControlApproval(agentId);
  const { setApproved } = useAgentSelector.useActions();
  const { updateAgentMainData } = getAgentStateActions();

  const disabled = selectedRows.length === 0 && isApproved;

  const approveAll = selectedRows.length > 1;

  const handleApprove = async () => {
    if (selectedRows.length > 0) {
      await emitApproval(Array.from(selectedRows));
      const agentData = getAgentData(agentId);
      if (agentData) {
        updateAgentMainData(agentId, {
          ...agentData.mainData,
          approvedIds: [...new Set([...agentData.mainData.approvedIds, ...selectedRows])],
          lastUpdate: {
            timestamp: Date.now(),
            isRemoteUpdate: false
          }
        });
      }
      resetSelectedRows();
      return;
    }
  };

  return (
    <>
      <div className="flex gap-2">
        {/* <Button
        onClick={resetSelectedRows}
        color="primary"
        variant="bordered"
        className="rounded-md text-sm leading-5 px-3 py-1  h-7"
        isDisabled={disabled}
      >
        Clear Selection
      </Button> */}
        <Button
          onClick={handleApprove}
          color="primary"
          className="rounded-md text-sm leading-5 px-3 py-1  h-7"
          isDisabled={disabled}
        >
          {approveAll
            ? "Accept all"
            : isApproved
              ? "Accepted"
              : "Accept Response"}
        </Button>
      </div>
    </>
  );
};

export default AgentActions;

import { Checkbox, CheckboxProps, cn } from "@nextui-org/react";
import {
  SortDescriptor,
  Table,
  TableBody,
  TableBodyProps,
  TableCell,
  TableCellProps,
  TableColumn,
  TableColumnProps,
  TableHeader,
  TableHeaderProps,
  TableProps,
  TableRow,
  TableRowProps,
} from "@nextui-org/table";
/* eslint-disable @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unnecessary-type-assertion */
import {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import ErrorBoundary from "../error-boundary";

export type ZTableValidRowModel = {
  [key: string | symbol]: any;
};

export type ZTableRowId = string | number;

export type ZTableRowSelectionModel = ZTableRowId[];

export interface ZTableRowParams<T> {
  /**
   * The row model of the row that the current cell belongs to.
   */
  row: T;
}

export interface ZTableColumnProps<
  T extends ZTableValidRowModel = ZTableValidRowModel
> extends Omit<TableColumnProps<T>, "children"> {
  /**
   * The type of the column. It will be used to determine the sort order for un-controlled sorting.
   */
  type?: "string" | "number" | "date" | "custom";
  /**
   * The unique identifer of the current column
   */
  fieldName: string;
  /**
   * The column title to rendered in the header
   */
  headerName?: string;
  /**
   * Renderer function for the header column title, will override the headerName field if passed
   * @param {Omit<ZTableColumnProps<T>, 'renderHeader' | 'renderCell'>} column The column object
   */
  renderHeader?: (
    column: Omit<ZTableColumnProps<T>, "renderHeader" | "renderCell">
  ) => ReactNode;
  /**
   * Renderer function for the cell value, will override the fieldName field if passed
   * @param {T} row The row object
   */
  renderCell?: (row: T) => ReactNode;
  /**
   * Determines if a column can be sorted.
   */
  sortable?: boolean; // This will determine if column will be sortable or not
  /**
   * The sort value for the column.
   * @param {T} row The row object.
   * @returns {any} The value to sort.
   */
  getSortValue?: (row: T) => string | number | Date;
  /**
   * The sort comparator function for the column.
   * @param {T} a The first row object.
   * @param {T} b The second row object.
   * @returns {number} The comparison result.
   */
  sortComparator?: (a: T, b: T) => number;
}

export interface ZTableProps<
  T extends ZTableValidRowModel = ZTableValidRowModel
>
  extends Omit<
    TableProps,
    "children" | "collection" | "disabledKeys" | "showSelectionCheckboxes"
  > {
  /**
   * The rows of the table.
   * @param {T[]} rows An array of type T.
   */
  rows: T[];
  /**
   * The columns of the table.
   * @param {ZTableColumnProps<T>[]} columns An array of [[ZTableColumnProps<T>]].
   */
  columns: ZTableColumnProps<T>[];
  /**
   * The row id of the row.
   * @param {T} row The row object.
   * @returns {ZTableRowId} The row id.
   * @default row['id']
   */
  getRowId?: (row: T) => ZTableRowId;
  /**
   * Determines if the table is loading.
   */
  loading?: boolean;

  /**
   * Determines if a column is sorted.
   * @param {SortDescriptor} sortDescriptor With all the sorted columns [[SortDescriptor]].
   */
  sortDescriptor?: SortDescriptor;
  /**
   * Determines if the table is report ready.
   */
  isReportReady?: boolean;
  /**
   * Callback fired when the sort model changes.
   * @param {SortDescriptor} sortDescriptor With all the sorted columns [[SortDescriptor]].
   * @returns {void}
   */
  onSortModelChange?: (sortDescriptor: SortDescriptor) => void;
  /**
   * Determines if a row can be selected.
   * @param {ZTableRowParams} params With all properties from [[ZTableRowParams]].
   * @returns {boolean} A boolean indicating if the cell is selectable.
   */
  isRowSelectable?: ({ row }: ZTableRowParams<T>) => boolean;
  /**
   * Determines if a row is disabled.
   * @param {ZTableRowParams} params With all properties from [[ZTableRowParams]].
   * @returns {boolean} A boolean indicating if the cell's elements such as button, checkbox etc. are disabled.
   */
  isRowDisabled?: ({ row }: ZTableRowParams<T>) => boolean;
  /**
   * Callback fired when a row is clicked.
   * @param {ZTableRowParams} params With all properties from [[ZTableRowParams]].
   * @returns {void}
   */
  onRowClick?: ({ row }: ZTableRowParams<T>) => void;
  /**
   * Determines if a row is active.
   * @param {ZTableRowParams} params With all properties from [[ZTableRowParams]].
   * @returns {boolean} A boolean indicating if the cell is active.
   */
  isRowActive?: ({ row }: ZTableRowParams<T>) => boolean;
  /**
   * Sets the row selection model of the Data ZTable.
   */
  rowSelectionModel?: ZTableRowSelectionModel;
  /**
   * Callback fired when the selection state of one or multiple rows changes.
   * @param {ZTableRowSelectionModel} rowSelectionModel With all the row ids [[ZTableSelectionModel]].
   * @returns {void}
   */
  onRowSelectionModelChange?: (
    rowSelectionModel: ZTableRowSelectionModel
  ) => void;
  tableHeaderProps?: Omit<TableHeaderProps<T>, "columns" | "children">;
  tableColumnProps?:
    | Omit<TableColumnProps<T>, "key" | "allowsSorting" | "children">
    | ((
        column: ZTableColumnProps<T>,
        columnIndex: number
      ) => Omit<TableColumnProps<T>, "key" | "allowsSorting" | "children">);
  tableBodyProps?: Omit<TableBodyProps<T>, "items" | "children">;
  tableRowProps?:
    | Omit<
        TableRowProps,
        | "key"
        | "UNSTABLE_childItems"
        | "hasChildItems"
        | "textValue"
        | "children"
      >
    | ((
        row: T,
        rowIndex: number
      ) => Omit<
        TableRowProps,
        | "key"
        | "UNSTABLE_childItems"
        | "hasChildItems"
        | "textValue"
        | "children"
      >);
  tableCellProps?:
    | Omit<TableCellProps, "key" | "textValue" | "children">
    | (({
        row,
        column,
      }: {
        row: T;
        rowIndex: number;
        column: ZTableColumnProps<T>;
        columnIndex: number;
      }) => Omit<TableCellProps, "key" | "textValue" | "children">);
  tableRowSelectionCheckboxProps?:
    | Omit<
        CheckboxProps,
        | "onChange"
        | "isSelected"
        | "disabled"
        | "isDisabled"
        | "isIndeterminate"
        | "checked"
        | "isReadonly"
        | "isInvalid"
        | "required"
      >
    | (({
        row,
        rowIndex,
      }: {
        row: T;
        rowIndex: number;
      }) => Omit<
        CheckboxProps,
        | "onChange"
        | "isSelected"
        | "disabled"
        | "isDisabled"
        | "isIndeterminate"
        | "checked"
        | "isReadonly"
        | "isInvalid"
        | "required"
      >);
  showCheckboxIfDisabled?: boolean;
  visibleColumns?: ZTableColumnProps<T>[];
}

const ZTable = <T extends ZTableValidRowModel = ZTableValidRowModel>({
  rows,
  columns,
  getRowId,
  loading,
  sortDescriptor: defaultSortModel,
  onSortModelChange,
  isRowSelectable,
  isRowDisabled,
  isRowActive,
  rowSelectionModel: defaultRowSelectionModel,
  onRowSelectionModelChange,
  tableHeaderProps,
  tableColumnProps,
  tableBodyProps,
  tableRowProps,
  tableCellProps,
  tableRowSelectionCheckboxProps,
  showCheckboxIfDisabled = true,
  visibleColumns,
  isReportReady,
  onRowClick,
  ...zTableProps
}: ZTableProps<T>) => {
  const [sortDescriptor, setSortModel] = useState<SortDescriptor | undefined>(
    defaultSortModel
  );
  const [rowSelectionModel, setRowSelectionModel] = useState<
    ZTableRowSelectionModel | undefined
  >(defaultRowSelectionModel);

  const selectableRows = useMemo(() => {
    const filteredRows = rows.filter((row) => {
      const disabled = isRowDisabled?.({ row });
      const selectable = isRowSelectable?.({ row });
      return !(disabled || selectable === false);
    });
    return filteredRows;
  }, [rows, isRowDisabled, isRowSelectable]);

  const shouldRenderSelectionColumn = useMemo(() => {
    return (
      !isReportReady &&
      (typeof isRowSelectable === "function" ||
        Array.isArray(rowSelectionModel) ||
        typeof onRowSelectionModelChange === "function")
    );
  }, [
    rows,
    isRowSelectable,
    rowSelectionModel,
    onRowSelectionModelChange,
    isReportReady,
  ]);

  useLayoutEffect(() => {
    document.querySelector("[data-row-active='true']")?.scrollIntoView({
      block: "center",
    });
  }, []);

  useEffect(() => {
    if (defaultSortModel) {
      setSortModel(defaultSortModel);
    }
  }, [defaultSortModel, setSortModel]);

  useEffect(() => {
    if (defaultRowSelectionModel) {
      setRowSelectionModel(defaultRowSelectionModel);
    }
  }, [defaultRowSelectionModel, setRowSelectionModel]);

  const sortedRows = useMemo(() => {
    if (!sortDescriptor?.column || !sortDescriptor?.direction) {
      return rows;
    }
    const column = columns.find(
      (column) => column.fieldName === sortDescriptor?.column
    );
    if (!column) {
      return rows;
    }
    const sortComparator =
      typeof column.sortComparator === "function"
        ? column.sortComparator
        : (a: T, b: T) => {
            // extracts the sort value from the row
            const extractSortValue = (x: any) => {
              if (typeof column.getSortValue === "function") {
                return column.getSortValue(x);
              } else if (sortDescriptor?.column) {
                return x[sortDescriptor?.column];
              } else {
                return undefined;
              }
            };

            // returns true if value is undefined or null
            const nullUndefinedCheck = (value: any) => {
              if (value === undefined || value === null) return true;
              return false;
            };

            // makes sure all values are of the same type
            const allTypesSame = (
              values: any[],
              type?: string,
              instanceOfType?: any
            ) => {
              if (
                instanceOfType &&
                values.every((value) => value instanceof instanceOfType)
              ) {
                return true;
              } else if (
                type &&
                values.every((value) => typeof value === type)
              ) {
                return true;
              } else {
                return false;
              }
            };

            if (nullUndefinedCheck(a) && nullUndefinedCheck(b)) {
              return 0;
            }

            const aValue = extractSortValue(a);
            const bValue = extractSortValue(b);

            if (nullUndefinedCheck(aValue) && nullUndefinedCheck(bValue)) {
              return 0;
            }
            if (nullUndefinedCheck(aValue)) {
              return 1;
            }
            if (nullUndefinedCheck(bValue)) {
              return -1;
            }

            if (allTypesSame([aValue, bValue], "string")) {
              return aValue.localeCompare(bValue);
            }
            if (allTypesSame([aValue, bValue], "number")) {
              return aValue - bValue;
            }
            if (allTypesSame([aValue, bValue], undefined, Date)) {
              return aValue.getTime() - bValue.getTime();
            }

            return String(aValue).localeCompare(String(bValue));
          };
    const sortedRows = [...rows].sort(sortComparator);
    return sortDescriptor?.direction === "descending"
      ? sortedRows.reverse()
      : sortedRows;
  }, [rows, columns, sortDescriptor]);

  const handleSortClick = useCallback(
    (columnSortModel: SortDescriptor) => {
      const newSortDescriptor: SortDescriptor = {
        column: columnSortModel.column,
        direction:
          sortDescriptor?.column === columnSortModel.column
            ? sortDescriptor?.direction === "ascending"
              ? "descending"
              : "ascending"
            : "ascending",
      };
      setSortModel(newSortDescriptor);
      onSortModelChange?.(newSortDescriptor);
    },
    [sortDescriptor, onSortModelChange]
  );

  const handleSelectAllRowClick = useCallback(() => {
    const newSelection =
      selectableRows?.length === rowSelectionModel?.length
        ? []
        : selectableRows?.map((row) => getRowId?.(row) ?? row?.id) ?? [];
    onRowSelectionModelChange?.(newSelection);
  }, [selectableRows, rowSelectionModel, getRowId, onRowSelectionModelChange]);

  const handleSelectRowClick = useCallback(
    (rowId: ZTableRowId) => {
      setRowSelectionModel((prev) => {
        return prev?.includes(rowId)
          ? prev.filter((id) => id !== rowId)
          : [...(prev ?? []), rowId];
      });
      onRowSelectionModelChange?.(
        rowSelectionModel?.includes(rowId)
          ? rowSelectionModel.filter((id) => id !== rowId)
          : [...(rowSelectionModel ?? []), rowId]
      );
    },
    [rowSelectionModel, onRowSelectionModelChange]
  );

  const handleOnRowClick = useCallback(
    (
      e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
      row: T,
      rowIndex: number
    ) => {
      const rowId = getRowId?.(row) ?? row?.id;

      if (rowSelectionModel && rowSelectionModel.length > 0) {
        e.preventDefault(); // Prevent default row click behavior
        handleSelectRowClick(rowId);
      } else if (onRowClick) {
        e.preventDefault();
        onRowClick({ row });
      } else {
        if (!tableRowProps) return;
        if (typeof tableRowProps === "function")
          tableRowProps(row, rowIndex).onClick?.(e);
        else tableRowProps?.onClick?.(e);
      }
    },
    [
      rowSelectionModel,
      getRowId,
      handleSelectRowClick,
      tableRowProps,
      onRowClick,
    ]
  );
  const tableColumnsProps = [
    ...(shouldRenderSelectionColumn
      ? [
          {
            fieldName: "selection",
            renderHeader: () => {
              return (
                <div className="w-[75px] flex justify-center items-center px-5">
                  <Checkbox
                    disabled={loading}
                    isDisabled={loading || selectableRows?.length === 0}
                    isSelected={
                      rowSelectionModel?.length === selectableRows?.length &&
                      selectableRows?.length > 0
                    }
                    isIndeterminate={
                      rowSelectionModel &&
                      rowSelectionModel?.length > 0 &&
                      rowSelectionModel?.length < selectableRows?.length
                    }
                    onChange={handleSelectAllRowClick}
                    data-component="z-table-header-select"
                    classNames={{
                      icon: "m-0",
                      wrapper: "m-0",
                      base: "m-0",
                      label: "m-0",
                    }}
                    className="after:bg-foreground after:text-background text-background m-0"
                  />
                </div>
              );
            },
          } as ZTableColumnProps<T>,
        ]
      : []),
    ...(visibleColumns || columns),
  ].map((col, columnIndex) => ({ ...col, columnIndex }));

  const tableRowsProps = sortedRows.map((item, idx) => ({ ...item, idx }));

  return (
    <ErrorBoundary fallback={<ErrorComponent />}>
      <Table
        {...zTableProps}
        data-component="z-table"
        sortDescriptor={sortDescriptor}
        onSortChange={handleSortClick}
      >
        <TableHeader
          {...tableHeaderProps}
          columns={tableColumnsProps}
          data-component="z-table-header"
        >
          {(column) => {
            const renderHeader = () => {
              return typeof column?.renderHeader === "function"
                ? column?.renderHeader(column)
                : column?.headerName ?? "";
            };
            return (
              <TableColumn
                key={column.fieldName || column.columnIndex}
                {...(typeof tableColumnProps === "function"
                  ? tableColumnProps(column, column.columnIndex)
                  : tableColumnProps)}
                data-component={column?.sortable ? undefined : "z-table-column"}
                allowsSorting={column?.sortable}
              >
                {renderHeader()}
              </TableColumn>
            );
          }}
        </TableHeader>
        <TableBody
          {...tableBodyProps}
          className="h-screen"
          items={tableRowsProps}
          data-component="z-table-body"
        >
          {(row) => {
            const disabled = isRowDisabled?.({ row });
            const selectable = isRowSelectable?.({ row });

            const active = isRowActive?.({ row });
            return (
              <TableRow
                {...(typeof tableRowProps === "function"
                  ? tableRowProps(row, row.idx)
                  : tableRowProps)}
                data-component="z-table-row"
                data-row-selected={rowSelectionModel?.includes?.(
                  getRowId?.(row) ?? row?.id
                )}
                data-row-disabled={disabled}
                data-row-selectable={selectable}
                data-row-active={active}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOnRowClick(e, row, row.idx);
                }}
              >
                {(columnKey) => {
                  const columnIndex = tableColumnsProps.findIndex(
                    (col) => col.fieldName === columnKey
                  );
                  const column = tableColumnsProps.find(
                    (col) => col.fieldName === columnKey
                  );
                  if (column) {
                    if (columnKey === "selection") {
                      const rowId = getRowId?.(row) ?? row?.id;

                      return (
                        <TableCell
                          key={columnIndex}
                          {...(typeof tableCellProps === "function"
                            ? tableCellProps({
                                row,
                                rowIndex: row.idx,
                                column,
                                columnIndex,
                              })
                            : tableCellProps)}
                          className="w-[75px] ml-2 py-6 px-5 flex justify-center items-center"
                          data-component="z-table-cell"
                        >
                          <Checkbox
                            disabled={disabled}
                            isDisabled={disabled}
                            isSelected={
                              ((showCheckboxIfDisabled && disabled) ||
                                rowSelectionModel?.includes?.(row.id)) ??
                              false
                            }
                            classNames={{
                              base: cn(
                                "group",
                                disabled && "opacity-50",
                                "m-0"
                              ),
                              wrapper: cn(
                                "group-data-[disabled=true]:border-default",
                                "after:group-data-[disabled=true]:bg-default-100",
                                "m-0"
                              ),
                              icon: "group-data-[disabled=true] m-0",
                            }}
                            className={cn(
                              "group-hover:visible invisible table-checkbox",
                              ((showCheckboxIfDisabled && disabled) ||
                                rowSelectionModel?.includes?.(row.id)) ??
                                false
                                ? "visible"
                                : ""
                            )}
                            onChange={() => {
                              if (!disabled) {
                                handleSelectRowClick(rowId);
                              }
                            }}
                            data-component="z-table-row-select"
                            color={disabled ? "default" : "primary"}
                            {...tableRowSelectionCheckboxProps}
                          />
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell
                        // key={columnIndex}
                        {...(typeof tableCellProps === "function"
                          ? tableCellProps({
                              row,
                              rowIndex: row.idx,
                              column,
                              columnIndex,
                            })
                          : tableCellProps)}
                        data-component="z-table-cell"
                      >
                        {typeof column.renderCell === "function"
                          ? column.renderCell(row)
                          : typeof column.fieldName === "string"
                          ? row[column.fieldName]
                          : ""}
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell key={-1} data-component="z-table-cell">
                        -
                      </TableCell>
                    );
                  }
                }}
              </TableRow>
            );
          }}
        </TableBody>
      </Table>
    </ErrorBoundary>
  );
};

export { ZTable };

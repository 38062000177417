import { ZButton } from "@/shared/components/button";
import FileUpload from "@/shared/components/custom/file-upload";
import { ZInput } from "@/shared/components/input";
import {
  ZModal,
  ZModalBody,
  ZModalContent,
  ZModalFooter,
  ZModalHeader,
} from "@/shared/components/modal";
import { UploadedFile } from "@/shared/types/file-upload";

import { addNotification } from "@/shared/states/notification";
import { cn, Select, SelectItem, Switch, Textarea } from "@nextui-org/react";
import { AxiosError } from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { simplifyFileTypes } from "../../../../shared/components/custom/file-upload/index";
import {
  AGENT_TYPES,
  GapAssessmentTypes,
  UploadDialogState,
} from "../../types/index.ts";
import { helper } from "./helper.ts";
import { processDataBasedOnAssessment } from "./util.ts";

// Add filetypes from simplifyFileTypes keys.
const DEFAULT_ALLOWED_FILE_TYPES = simplifyFileTypes([]).map(
  (key: string) => key
);

// Define a type for the error messages
type ErrorMessages = {
  session?: string;
  [key: string]: string | undefined; // allows for dynamic keys
};

export default function UploadDialog({
  data,
  setIsOpen,
}: {
  data: UploadDialogState;
  setIsOpen: (data: UploadDialogState) => void;
}) {
  const { isOpen, type } = data;
  const helperData = helper[type as AGENT_TYPES];
  const [isLoading, setIsLoading] = useState(false);
  const [allFiles, setAllFiles] = useState<UploadedFile[]>([]);
  const [errors, setErrors] = useState<ErrorMessages>({});
  const [selectedDropdownOption, setSelectedDropdownOption] = useState<
    string
  >();
  const [isToggleSelected, setIsToggleSelected] = useState(false);
  const fileUploadRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [inputs, setInputs] = useState<{ [key: string]: string }>({});

  const navigate = useNavigate();

  useEffect(() => {
    const defaultOption = helperData?.dropdown?.options.find(
      (option: { isDefault: boolean }) => option.isDefault
    );

    if (type !== AGENT_TYPES.GAP_ASSESSMENT) {
      setSelectedDropdownOption(defaultOption?.value ?? "");
    }
  }, [helperData?.dropdown?.options, isOpen]);

  useEffect(() => {
    setIsLoading(false);
    setIsToggleSelected(false);
    setAllFiles([]);
    setErrors({});
    setInputs({});
  }, [isOpen]);

  useEffect(() => {
    setIsToggleSelected(false);
  }, [selectedDropdownOption]);

  const buttonDisabled = useMemo(() => {
    const areInputsMissing = helperData?.inputs?.some(
      (input) =>
        input.isRequired && (inputs[input.key] ?? "").trim().length === 0
    );

    if (areInputsMissing) {
      // Some required inputs are missing.
      return true;
    }
    if (!selectedDropdownOption && type === AGENT_TYPES.GAP_ASSESSMENT) {
      return true;
    }

    // check if the helperData.uploads array is empty
    const shouldHaveFiles = helperData?.uploads.length > 0;
    if (shouldHaveFiles && allFiles.length === 0) {
      // no files uploaded, but the helperData.uploads array is not empty
      return true;
    }

    let maxFilesExceeded = false;
    helperData?.uploads?.forEach((upload) => {
      const filteredAllFiles = allFiles.filter(
        (file) => file.type === upload.type
      );
      maxFilesExceeded =
        maxFilesExceeded || filteredAllFiles.length > upload.maxFiles;
    });

    if (maxFilesExceeded) {
      // the number of files exceeds the maximum allowed uploads as defined in the helperData.uploads array
      return true;
    }

    if (!type) {
      // the type is not defined; impossible to proceed
      return true;
    }

    return allFiles.some((file) => file.status !== "success");
  }, [allFiles, type, helperData, inputs, selectedDropdownOption]);

  const handleSubmit = async () => {
    setIsLoading(true);

    let isError = false;
    helperData.inputs.forEach((input: { key: string; isRequired: boolean }) => {
      if (!inputs[input.key]) {
        isError = true;
        setErrors((prevErrors) => ({
          ...prevErrors,
          [input.key]: "This field is required",
        }));
      }
    });

    if (isError) {
      setIsLoading(false);
      throw new Error("Validation error");
    }

    try {
      if (helperData?.uploads.length !== 0 && allFiles.length === 0) {
        return;
      }
      await processDataBasedOnAssessment(
        type,
        allFiles,
        navigate,
        inputs,
        selectedDropdownOption,
        isToggleSelected
      );
    } catch (error) {
      const errorType = (error as AxiosError<{ error_type: string }>).response
        ?.data.error_type;

      if (errorType === "Validation Error") {
        setErrors({ title: "Session name already exists" });
        return;
      }
      console.error(error);
      addNotification({
        message: "Error processing the uploaded file(s)",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSetFiles = (newFiles: UploadedFile[], fileType: string) => {
    setAllFiles((prevFiles) => {
      const otherTypeFiles = prevFiles.filter((file) => file.type !== fileType);
      const combinedFiles = [...otherTypeFiles, ...newFiles];
      const uniqueFiles = combinedFiles.reduce((acc, current) => {
        const existingFile = acc.find(
          (item) => item.file.name === current.file.name
        );
        if (!existingFile) {
          return [...acc, current];
        } else {
          return acc.map((item) =>
            item.file.name === current.file.name ? current : item
          );
        }
      }, [] as UploadedFile[]);

      // Scroll to the newly added file
      setTimeout(() => {
        const lastFile = newFiles[newFiles.length - 1];
        if (lastFile && fileUploadRefs.current[fileType]) {
          fileUploadRefs.current[fileType]?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
      }, 100);

      return uniqueFiles;
    });
  };

  return (
    <ZModal
      isOpen={isOpen}
      onOpenChange={() =>
        setIsOpen({
          isOpen: false,
        })
      }
      aria-label="Upload Dialog"
      className="max-h-[calc(100vh-96px)] overflow-y-auto"
    >
      <ZModalContent className="max-w-lg">
        <ZModalHeader className="flex text-lg flex-col w-full items-center pb-4">
          <p>{helperData?.title}</p>
          <p className="text-sm text-center font-normal text-[#777780]">
            {helperData?.desc}
          </p>
        </ZModalHeader>
        <ZModalBody className="pt-0 overflow-auto">
          <div className="grid gap-4 py-0">
            <div className="grid w-full items-center gap-4">
              {helperData?.inputs?.map(
                (
                  input: {
                    key: string;
                    label: string;
                    placeholder: string;
                    type: string;
                    isRequired: boolean;
                  },
                  index: number
                ) =>
                  input.type === "textarea" ? (
                    <Textarea
                      key={input.key}
                      label={input.label}
                      placeholder={input.placeholder}
                      value={inputs[input.key] ?? ""}
                      onChange={(e) => {
                        setErrors({ ...errors, [input.key]: "" });
                        setInputs({
                          ...inputs,
                          [input.key]: e.target.value.trimStart(),
                        });
                      }}
                      labelPlacement="outside"
                      errorMessage={errors[input.key]}
                      isInvalid={!!errors[input.key]}
                      variant="faded"
                      autoFocus={index === 0}
                      classNames={{
                        inputWrapper: cn(
                          "w-full shadow-none  rounded-md border-1 border-gray-300",
                          !!errors[input.key] && "border-[#F31260]"
                        ),
                      }}
                    />
                  ) : (
                    <ZInput
                      key={input.key}
                      label={input.label}
                      placeholder={input.placeholder}
                      type={input.type}
                      value={inputs[input.key] ?? ""}
                      onChange={(e) => {
                        setErrors({ ...errors, [input.key]: "" });
                        setInputs({
                          ...inputs,
                          [input.key]: e.target.value.trimStart(),
                        });
                      }}
                      labelPlacement="outside"
                      errorMessage={errors[input.key]}
                      isInvalid={!!errors[input.key]}
                      variant="faded"
                      autoFocus={index === 0}
                      className="focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm  shadow-none rounded-md"
                      classNames={{
                        inputWrapper: cn(
                          "w-full shadow-none  rounded-md border-1 border-gray-300",
                          !!errors[input.key] && "border-[#F31260]"
                        ),
                      }}
                    />
                  )
              )}
            </div>
            {helperData?.dropdown ? (
              <Select
                classNames={{
                  mainWrapper: cn(
                    "w-full shadow-none  rounded-md border-1 border-gray-300",
                    !!errors.session && "border-[#F31260]"
                  ),
                  popoverContent: cn(
                    "w-full shadow-none  rounded-md border-1 border-gray-300",
                    !!errors.session && "border-[#F31260]"
                  ),
                }}
                items={helperData.dropdown.options}
                label={helperData.dropdown.label}
                labelPlacement="outside"
                value={selectedDropdownOption}
                placeholder={helperData.dropdown.placeholder}
                onChange={(value) => {
                  if (helperData.dropdown.options.length > 1) {
                    setSelectedDropdownOption(value.target.value);
                  }
                }}
                selectedKeys={
                  selectedDropdownOption ? [selectedDropdownOption] : []
                }
              >
                {(item: { value: string; label: string }) => (
                  <SelectItem key={item.value} value={item.value}>
                    {item.label}
                  </SelectItem>
                )}
              </Select>
            ) : null}
            {helperData &&
              selectedDropdownOption &&
              selectedDropdownOption.includes(GapAssessmentTypes.SOC2) &&
              (helperData as any)?.toggle && (
                <div className="py-4 ">
                  <Switch
                    isSelected={isToggleSelected}
                    onValueChange={setIsToggleSelected}
                    size="sm"
                  >
                    <div className="pl-1 font-medium text-sm">
                      {(helperData as any)?.toggle?.label}
                    </div>
                  </Switch>
                </div>
              )}
            {helperData?.uploads?.map?.(
              ({
                title,
                allowedFileTypes,
                type,
                maxFiles,
              }: {
                title: string;
                allowedFileTypes: string[];
                type: string;
                maxFiles: number;
              }) => (
                <div className="grid w-full items-center gap-1.5" key={title}>
                  <p className="text-small">{title}</p>
                  <FileUpload
                    maxFiles={maxFiles}
                    isMultiple={true}
                    allowedFileTypes={allowedFileTypes}
                    setFiles={(files: UploadedFile[], type: string) =>
                      handleSetFiles(files, type)
                    }
                    type={type}
                    ref={(el: HTMLDivElement) =>
                      (fileUploadRefs.current[type] = el)
                    }
                  />
                </div>
              )
            )}
          </div>
        </ZModalBody>
        <ZModalFooter>
          <ZButton
            variant="bordered"
            onClick={() =>
              setIsOpen({
                isOpen: false,
              })
            }
            className="px-3 py-1 h-auto text-sm"
          >
            Cancel
          </ZButton>
          <ZButton
            type="submit"
            onClick={() => {
              void handleSubmit();
            }}
            isLoading={isLoading}
            isDisabled={isLoading || buttonDisabled}
            disabled={isLoading || buttonDisabled}
            className="px-3 py-1 h-auto text-sm"
          >
            Submit
          </ZButton>
        </ZModalFooter>
      </ZModalContent>
    </ZModal>
  );
}
const getSessionJWT = (refresh?: boolean): string => {
  const cookie = document.cookie.split('; ').find((row) => row.startsWith(`intermediate_session_token`))?.split('=')[1]
  if (!cookie) {
    throw new Error(
      "Authentication failed. Please try logging out and logging in again. Contact support if the issue persists.",
    );
  }

  const data = {session_jwt: cookie}
  if (!data) {
    throw new Error(
      "Authentication failed. Please try logging out and logging in again. Contact support if the issue persists.",
    );
  }

  return data.session_jwt;
};

export { getSessionJWT };
/* eslint-disable @typescript-eslint/require-await */
import { Button, Tooltip } from "@nextui-org/react";
import { ArrowLeft, ChevronLeft, ChevronRight, Loader } from "lucide-react";
import { useCallback, useEffect, useMemo, useState, useLayoutEffect } from "react";
import { debounce } from "lodash";

import { AgentSessionStatus } from "@/modules/sessions/types";
import { AgentSessionStepType } from "@/modules/sessions/types";
import usePrevious from "@/shared/hooks/use-previous";
import { addNotification } from "@/shared/states/notification";
import { cn } from "@/shared/utils/classname-merger";
import { useParams } from "react-router-dom";
import { confidenceHelper } from "../../constants/helpers";
import {
  getAgentData,
  getAgentStateActions,
  useAgentData,
  useAgentSelector,
  useAgentStepData,
  useApprovedIds,
  useEditedIds,
  useSelectedReviewResponse,
} from "../../states";
import {
  AGENT_TYPES,
  AgentSubTypes,
  ConfidenceTypes,
  GapAssessmentTypes,
} from "../../types/index.ts";
import { handleAgentAutoSave } from "../../utils/autosave";
import { cleanConfidence } from "../../utils/clean-confidence";
import { getTagAndRiskString, getTagColor } from "../../utils/risk-control";
import { convertSnakeToCapitalized } from "../../utils/snake-to-capital.ts";
import type { IdParams } from "@/modules/agent/components/layout/header";
import EditSection from "@/modules/agent/components/review-responses/edit-section";
import UserAvatarGroup from "@/shared/components/avatar-group/userAvatarGroup";
import { useControlEvents } from "@/modules/agent/hooks/useControlEvents.ts";
import { useLoggedInMember } from "@/modules/auth/states";
import { getControlId } from "../../utils/get-control-id.ts";
import { useControlEditStatus } from "../../hooks/useControlEditStatus.ts";
import { useControlApproval } from "../../hooks/useControlApproval.ts";

interface AgentExpandedViewProps {
  onClose: () => void;
  agentId: string;
  agentType: AGENT_TYPES;
  agentSubType: AgentSubTypes;
}

const AgentExpandedView = ({
  agentId,
  agentType,
  agentSubType,
  onClose,
}: AgentExpandedViewProps) => {
  const loggedInUser = useLoggedInMember();
  const { emitApproval } = useControlApproval(agentId);
    


  const [showAutoSave, setShowAutoSave] = useState("");

  const { setApproved, navigateRow } = useAgentSelector.useActions();

  const { id: selectedId = "", data: selectedData } =
    useSelectedReviewResponse(agentId);

    const { isBeingEdited, editorName } = useControlEditStatus(agentId, selectedId);

  const isEdited = useEditedIds(agentId).includes(selectedId);
  const isApproved = useApprovedIds(agentId).includes(selectedId);

  const prevSelectedId = usePrevious(selectedId);
  const previousRiskData = usePrevious(selectedData);

  const { id } = useParams<keyof IdParams>() as IdParams;
  const stepData = useAgentStepData(id);

  const prepareReportStep = stepData?.find(
    (step) => step.type === AgentSessionStepType.PREPARE_REPORT,
  );
  const isReportReady =
    prepareReportStep?.status === AgentSessionStatus.COMPLETE;

  const autoSaveUpdatedData = useCallback(
    async () => {
      try {
        const agentData = getAgentData(id);
        const lastUpdate = agentData?.mainData.lastUpdate;
        
        if (lastUpdate?.isRemoteUpdate && Date.now() - lastUpdate.timestamp < 2000) {
          return;
        }

        // Set loading state immediately
        setShowAutoSave(selectedId);

        await handleAgentAutoSave(agentId, agentType, agentSubType);

        const { updateAgentData } = getAgentStateActions();
        if (agentData) {
          updateAgentData(agentId, {
            ...agentData,
            mainData: {
              ...agentData.mainData,
              lastEditedField: undefined  // Clear the field
            }
          });
        }
        
        // Only clear if we're still on same control
        if (selectedId === agentData?.mainData.selectedId) {
          setShowAutoSave('');
        }
      } catch (error) {
        console.error(error);
        setShowAutoSave(''); // Clear on error
        addNotification({
          type: 'error',
          title: 'Failed to auto save response',
          message: (error as Error).message ?? 'Could not auto save your response. Please try again later.',
        });
      }
    },
    [agentId, agentType, agentSubType, selectedId, id]
  );

  
  const autoSaveUpdatedDataDebounced = useMemo(
    () => debounce(autoSaveUpdatedData, 1000, { trailing: true }),
    [autoSaveUpdatedData]
  );

  useEffect(() => {
    if (selectedData) {
      const agentData = getAgentData(id);
      const lastEditedField = agentData?.mainData.lastEditedField;
      const lastUpdate = agentData?.mainData.lastUpdate;
      
      // Only trigger autosave if:
      // 1. There was an actual edit (lastEditedField exists)
      // 2. Not a remote update
      // 3. Not just opening the control (check lastUpdate timestamp)
      if (lastEditedField && 
          (!lastUpdate?.isRemoteUpdate || Date.now() - lastUpdate.timestamp > 2000)) {
        setShowAutoSave(selectedId);
        void autoSaveUpdatedDataDebounced();
      }
    }

    return () => {
      autoSaveUpdatedDataDebounced.cancel();
    };
  }, [selectedData, selectedId, autoSaveUpdatedDataDebounced, id]);

  const getDoraTag = (id: string) => {
    let tag: string;
    const idSplit = id ? id.split(".") : "";
    if (idSplit.length === 3) {
      const chapterRomanNum = idSplit[0].toUpperCase();
      tag =
        "Ch. " +
        chapterRomanNum +
        " Art. " +
        idSplit[1] +
        " Req. " +
        idSplit[2];
    } else {
      tag = id ? id.split(".").join(" ") : "N/A";
    }

    return tag;
  };

  const TagTinyText: React.FC<{
    tag: string;
    text: string;
    tagColor?: string;
  }> = ({ tag, text, tagColor = undefined }) => (
    <div className="flex justify-start items-center gap-3 word-wrap whitespace-pre-line w-full max-w-full mx-4">
      {tagColor ? (
        <p
          className={cn(
            "text-[#333333] whitespace-nowrap grow w-fit max-w-fit min-w-fit bg-gray-100 rounded-xl text-[10px] leading-4 px-2 py-1",
            tagColor,
          )}
        >
          {tag}
        </p>
      ) : (
        <p
          className={cn(
          )}
        >
          {tag}
        </p>
      )}
      <p className="text-tiny mx-2 max-w-full">{text}</p>
    </div>
  );

  const renderRowData = useCallback(() => {
    switch (agentType) {
      case AGENT_TYPES.RISK_ASSESSMENT: {
        const subcategory = selectedData?.find(
          (item) => item.key === "subcategory",
        );
        if (!subcategory) return null;

        const { tag, riskString } = getTagAndRiskString(
          subcategory.value as string,
        );

        return (
          <div className="grid grid-cols-12 items-center">
            <div className="col-span-1">
              <p
                className={cn(
                  "flex items-center text-[#333333] whitespace-nowrap bg-gray-100 rounded-xl text-[10px] leading-4 px-2 py-0.5 self-start",
                  getTagColor(tag ?? ""),
                )}
              >
                {tag}
              </p>
            </div>
            <div className="col-span-11">
              <p className="ml-3 text-[14px] leading-5 text-[#22181c]">
                {riskString}
              </p>
            </div>
          </div>
        );
      }
      case AGENT_TYPES.QUESTIONNAIRE: {
        const questionText = selectedData?.find(
          (item) => item.key === "question_text",
        )?.value as string;

        return <TagTinyText tag="" text={questionText ?? ""} />;
      }
      case AGENT_TYPES.GAP_ASSESSMENT: {
        switch (agentSubType) {
          case GapAssessmentTypes.SOC2_TYPE2:
          case GapAssessmentTypes.SOC2_TYPE1:
          case GapAssessmentTypes.SOC2: {
            const trustId = (selectedData?.find(
              (item) => item.key === "trust_id"
            )?.value ??
              selectedData?.find((item) => item.key === "tsc_id")?.value ??
              "") as string;

            const control = (selectedData?.find(
              (item) => item.key === "control",
            )?.value ?? "") as string;
            return <TagTinyText tag={trustId} text={control} />;
          }

          case GapAssessmentTypes.DORA: {
            const requirementMaybe = selectedData?.find(
              (item) => item.key === "requirement",
            );
            const controlIdMaybe = selectedData?.find(
              (item) => item.key === "control_id",
            );

            if (!requirementMaybe?.value) return null;

            const requirement = requirementMaybe.value as string;
            const tag = controlIdMaybe?.value
              ? getDoraTag(controlIdMaybe.value as string)
              : "";

            return <TagTinyText tag={tag} text={requirement} />;
          }
          default: {
            return null;
          }
        }
      }
      case AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT: {
        const vendorQuestionCategory = selectedData?.find(
          (item) => item.key === "key",
        )?.value as string;
        const vendorQuestion = selectedData?.find(
          (item) => item.key === "question",
        )?.value as string;

        return (
          <p className=" text-[14px] leading-5 text-[#22181c]">
            <span className="font-medium pr-1">
              {convertSnakeToCapitalized(vendorQuestionCategory)}:
            </span>
            {vendorQuestion}
          </p>
        );
      }
      default: {
        return null;
      }
    }
  }, [agentType, agentSubType, selectedData]);

  const confidence = useMemo(() => {
    if (!selectedData) return null;
    const confidence = selectedData.find((item) => item.key === "confidence");
    if (confidence) {
      return confidence.value as ConfidenceTypes;
    }
    return null;
  }, [selectedData]);

  if (!selectedData) return null;

  const controlId = useMemo(() => {
    return getControlId(selectedData, agentType, agentSubType, selectedId);
  }, [selectedData, agentType, agentSubType, selectedId]);

  const { emitControlOpened, emitControlClosed } = useControlEvents(
    agentId,
    controlId,
    loggedInUser?.member_id
  );

  useLayoutEffect(() => {
    if (controlId && loggedInUser?.member_id) {
      emitControlOpened();
      return () => {
        emitControlClosed();
      };
    }
  }, [controlId, loggedInUser?.member_id, emitControlOpened, emitControlClosed]);

  return (
    <div className="h-full w-full overflow-hidden flex flex-col">
      <div className="flex flex-col bg-white border-b border-border">
        <div className="bg-white bg-opacity-50 flex p-4 pb-3  justify-between">
          <div className="flex gap-2 items-center">
            <Button
              isIconOnly
              className="bg-transparent p-0"
              size="sm"
              onClick={onClose}
            >
              <ArrowLeft size={18} />
            </Button>
            <p className="text-sm font-medium leading-5 text-[#171717]">
              Responses
            </p>
           
            {confidence && (
              <p className="text-[10px] bg-white py-[2px] rounded  text-[#67657A] border px-2  border-[#E4E4E7]">
                <span
                  className="rounded min-w-2 min-h-2 inline-block text-center p-[2px] mr-[4px]"
                  style={{
                    background:
                      confidenceHelper[cleanConfidence(confidence)]?.color,
                  }}
                ></span>
                {confidenceHelper[cleanConfidence(confidence)]?.label}
              </p>
            )}
            {isEdited && showAutoSave !== selectedId && (
              <p className=" text-tiny text-[#A5A5A5]">Edited</p>
            )}
            {showAutoSave === selectedId && (
              <span className="flex items-center gap-1">
                <Loader className="w-4 h-4 animate-spin" />
                <p className="text-tiny text-[#A5A5A5] text-[8px]">Saving</p>
              </span>
            )}
          </div>
          <div className="flex gap-2">
            <UserAvatarGroup sessionId={id} control={controlId || "" }
             className={isBeingEdited ? "p-1 rounded-full bg-gradient-to-r from-[#FF6496] to-[#812DD6]" : ""} />
            {!isReportReady && (
              <Button
                size="sm"
                color="primary"
                className="text-tiny p-0 px-2 1 h-7 rounded-md"
                isDisabled={isApproved}
                onClick={async () => {
                  await emitApproval(selectedId);
                  setApproved(agentId, { id: selectedId, setNext: true }); 
                }}
              >
                Accept Response
              </Button>
            )}
          </div>
        </div>
        <div className="flex px-5 pb-4 justify-between ">
          <div className="w-full p-3 bg-[#D6E5F8] bg-opacity-50 rounded-lg flex items-start justify-between">
            {renderRowData()}
           
            <div className="flex items-center gap-1">
              <Tooltip
                content="Previous"
                classNames={{
                  content:
                    "bg-black bg-opacity-80 backdrop-blur-md text-white text-tiny",
                  arrow: "bg-black bg-opacity-80 backdrop-blur-md",
                }}
              >
                <div
                  className="select-none bg-white cursor-pointer border-[1px] h-5 w-5 flex items-center justify-center rounded-[50%] border-solid border-[#E4E4E7]"
                  onClick={() => {
                    navigateRow({
                      agentId,
                      currentId: selectedId,
                      to: "previous",
                    });
                  }}
                >
                  <ChevronLeft color="#A5A5A5" size={16} />
                </div>
              </Tooltip>
              <Tooltip
                content="Next"
                classNames={{
                  content:
                    "bg-black bg-opacity-80 backdrop-blur-md text-white text-tiny",
                  arrow: "bg-black bg-opacity-80 backdrop-blur-md",
                }}
              >
                
                <div
                  className="select-none bg-white cursor-pointer border-[1px] h-5 w-5 flex items-center justify-center rounded-[50%] border-solid border-[#E4E4E7]"
                  onClick={() => {
                    navigateRow({
                      agentId,
                      currentId: selectedId,
                      to: "next",
                    });
                  }}
                >
                  <ChevronRight color="#A5A5A5" size={16} />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        {isBeingEdited && (
              <div className="bg-[#FFF3E0] h-[32px] rounded-md mx-4 mb-1 flex items-center justify-center">
                <p className="text-xs text-[#62420E]">
                This response is being edited by {editorName}. New edits from you might be lost.
                </p>
              </div>
            )}
      </div>
      <div className="grow overflow-auto bg-[#FAFAFA] flex p-2">
        <EditSection
          agentId={agentId}
          agentType={agentType}
          agentSubType={agentSubType}
          expandedView
        />
      </div>
    </div>
  );
};

export default AgentExpandedView;

import { authenticateInviteMagicLink, generateJWTTokenFromInitialAuth, getInitialOrganizationListFromAuth, getOrganizationList } from "@/modules/auth/requests";
import { LOGIN_STEP } from "@/modules/auth/types";
import { ROUTES } from "@/shared/constants/routes";
import { addNotification } from "@/shared/states/notification";
import { setCookie } from "@/shared/utils/cookie-helper";
import parsePromise from "@/shared/utils/parsePromise";
import ZaniaLogoLoader from "@components/page-loader";
import { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuthActions } from "../../states";
import { useLoginActions } from "../../states/login";
import { authenticateSso } from "../../use-cases/sso.use-case";

const Authenticate = () => {
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");
  const tokenType = searchParams.get("stytch_token_type");
  const { setAuth } = useAuthActions();
  const navigate = useNavigate();
  const {
    setLoginStep,
    setDiscoveredOrganizations,
  } = useLoginActions();

  const completeAuth = useCallback(() => {
    setAuth({
      isAuthenticated: true,
      isAuthenticationInProgress: false,
    });
  }, [setAuth]);

  useEffect(() => {
    setLoginStep(LOGIN_STEP.AUTHENTICATING);
  }, []);

  let count = 0;
  const authenticate = useCallback(async () => {

    if (token && tokenType) {
      // Prevent multiple calls
      count += 1;
      if (count > 1) {
        return;
      }

      switch (tokenType) {
        case "discovery":
        case "discovery_oauth": {
          const [error, data] = await parsePromise(getInitialOrganizationListFromAuth(tokenType === "discovery_oauth" ? "discover_oauth" : tokenType, token));
          if (error) {
            addNotification({
              type: "error",
              title: "Error",
              message: error.message,
            });
            setLoginStep(LOGIN_STEP.MAIN);
            navigate(`/${ROUTES.LOGIN}`);
            return;
          }

          if (data && data.intermediate_session_token) {
            document.cookie = `intermediate_session_token=${data.intermediate_session_token};path=/`
          }

          if (data?.discovered_organizations?.length && data?.discovered_organizations?.length > 1) {
            setDiscoveredOrganizations(data.discovered_organizations);
            setLoginStep(LOGIN_STEP.SELECT_ORG);
            navigate(`/${ROUTES.LOGIN}`);
          } else if (data?.discovered_organizations?.length && data?.discovered_organizations?.length === 1) {
            await generateJWTTokenFromInitialAuth(data.discovered_organizations[0].organization.organization_id, data.intermediate_session_token);
            navigate(`/${ROUTES.AGENT}`);
            completeAuth();
          } else {
            addNotification({
              type: "error",
              title: "No organizations found",
              message: "Please contact your administrator to get access to an organization",
            });
            setLoginStep(LOGIN_STEP.MAIN);
            navigate(`/${ROUTES.LOGIN}`);
          }

          break;
        }
        case "multi_tenant_magic_links": {
          const [error, data] = await parsePromise(authenticateInviteMagicLink(token));
          if (error) {
            addNotification({
              type: "error",
              title: "Error",
              message: error.message,
            });
            setLoginStep(LOGIN_STEP.MAIN);
            navigate(`/${ROUTES.LOGIN}`);
            return;
          }
          else {
            completeAuth();
            document.cookie = `intermediate_session_token=${token};path=/`
            setLoginStep(LOGIN_STEP.MAIN);
            navigate(`/${ROUTES.AGENT}`)
          }
          break;
        }
        case "sso": {
          await authenticateSso(token);
          const orgs = await getOrganizationList();
          setCookie("auth_type", "sso", 1);
          setDiscoveredOrganizations(orgs.discovered_organizations);
          if (orgs.discovered_organizations && orgs.discovered_organizations.length > 1) {
            setLoginStep(LOGIN_STEP.SELECT_ORG);
            navigate(`/${ROUTES.LOGIN}`);
          }
          else {
            completeAuth();
            setLoginStep(LOGIN_STEP.MAIN);
            navigate(`/${ROUTES.AGENT}`)
          }

          break;
        }
        default:
          break;
      }
    }
  }, [tokenType, token, completeAuth]);

  useEffect(() => {
    void authenticate();
  }, [authenticate]);

  return <ZaniaLogoLoader />;
};

export default Authenticate;

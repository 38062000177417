import { ZButton } from "@/shared/components/button";
import { ZInput } from "@/shared/components/input";
import {
  ZModal,
  ZModalBody,
  ZModalContent,
  ZModalFooter,
  ZModalHeader,
} from "@/shared/components/modal";
import { ROUTES } from "@/shared/constants/routes";
import { addNotification } from "@/shared/states/notification";
import {
  Checkbox,
  Chip,
  Select,
  SelectItem,
  SelectSection,
} from "@nextui-org/react";
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { useLoggedInMember, useLoggedInOrganization } from "@/modules/auth/states";
import { STYTCH_MEMBER } from "@/shared/constants";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { inviteTeamMember } from "../../requests";
import { getZaniaRoleLabelMap, useTeamState } from "../../states";
import { OrganizationTrustedMetaData, TeamRole } from "../../types";
import {
  atLeastOneAssessmentSelected,
  listOfAccess,
  mapIsCurrentMemberOrgAdmin,
  printErrorMessage,
} from "../../utils";

const TeamInviteDialog = () => {
  const { team } = useTeamState();
  const loggedInMember = useLoggedInMember();
  const loggedInOrganization = useLoggedInOrganization();
  const [orgMetaData, setOrgMetaData] = useState<OrganizationTrustedMetaData | undefined>(undefined);

  useEffect(() => {
    setOrgMetaData(loggedInOrganization?.trusted_metadata);
  }, [loggedInOrganization]);

  const isCurrentMemberOrgAdmin = useMemo(
    () => mapIsCurrentMemberOrgAdmin(team, loggedInMember?.member_id),
    [team, loggedInMember?.member_id],
  );

  const [inviteDetails, setInviteDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "" as TeamRole,
    access: [] as TeamRole[],
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onCloseDialog = useCallback(() => {
    navigate(`/${ROUTES.TEAM}`, { replace: true });
  }, [navigate]);

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setInviteDetails((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    },
    [setInviteDetails],
  );

  const handleOnClickInvite = async () => {
    const { firstName, lastName, email, role, access } = inviteDetails;
    if (
      typeof firstName !== "string" ||
      firstName.length === 0 ||
      typeof lastName !== "string" ||
      lastName.length === 0
    ) {
      addNotification({
        type: "error",
        title: "Invalid member name",
        message: "Please enter a valid first and last name",
      });
      return;
    } else if (
      typeof email !== "string" ||
      email.length === 0 ||
      new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(
        email,
      ) === false
    ) {
      addNotification({
        type: "error",
        title: "Invalid member email",
        message: "Please enter a valid email address",
      });
      return;
    } else if (typeof role !== "string" || role.length === 0) {
      addNotification({
        type: "error",
        title: "Invalid member role",
        message: "Please select role",
      });
      return;
    } else if (!Array.isArray(access) || access.length === 0) {
      addNotification({
        type: "error",
        title: "Invalid member access",
        message: "Please select atleast one AI Agent.",
      });
      return;
    } else if (Array.isArray(access) && atLeastOneAssessmentSelected(access)) {
      addNotification({
        type: "error",
        title: "Invalid member access",
        message: "Please select atleast one AI Agent ",
      });
      return;
    }
    setLoading(true);
    try {
      const existingMember = team.find(
        (member) => member.email_address === email,
      );
      if (existingMember) {
        if (existingMember.status === "invited") {
          addNotification({
            type: "error",
            title: "Duplicate Invitation",
            message: `This email address (${email}) already has an existing invitation. You can resend the invitation using the 'Invite Again' option.`,
          });
        } else {
          addNotification({
            type: "error",
            title: "Existing Member",
            message:
              "This email address already belongs to an active team member.",
          });
        }
        setLoading(false);
        return;
      }

      const filterValues = new Set([TeamRole.ZANIA_GAP_ASSESSMENT
        , TeamRole.ZANIA_DORA_ASSESSMENT
        , TeamRole.ZANIA_SOC2_ASSESSMENT
        , TeamRole.ZANIA_RISK_ASSESSMENT
        , TeamRole.ZANIA_VENDOR_ASSESSMENT]);

      const includedArray = access.filter(item => filterValues.has(item));
      const excludedArray = access.filter(item => !filterValues.has(item));

      await inviteTeamMember({
        user_name: [firstName, lastName].filter(Boolean).join(" "),
        user_email: email,
        // backend expects an array of roles, but we only need to send one. also don't send the role if it's stytch_member
        privilege_roles: role === STYTCH_MEMBER ? undefined : [role],
        feature_access_roles: includedArray,
        response_quality_roles: excludedArray,
        invite_redirect_url: window.location.origin+'/auth'
      });

      addNotification({
        type: "success",
        title: "Invitation Sent Successfully",
        message: `An invitation was sent to ${email} to join your team`,
      });
      onCloseDialog();
    } catch (error) {
      console.error(error);
      addNotification({
        type: "error",
        title:
          ((error as Error).cause as string) ??
          `Could not invite ${[firstName, lastName].filter(Boolean).join(" ")}`,
        message: printErrorMessage(JSON.stringify(error)),
      });
    } finally {
      setLoading(false);
    }
  };
  const currentMemberRoles = () => {
    const returnArr: Array<string> = [TeamRole.MEMBER];
    if (isCurrentMemberOrgAdmin) {
      returnArr.push(TeamRole.ADMIN);
    }
    return returnArr;
  };

  const isInviteDetailsValid = useMemo(() => {
    const responseQualityModes = [TeamRole.RESPONSE_QUALITY_ACCURACY, TeamRole.RESPONSE_QUALITY_SPEED, TeamRole.RESPONSE_QUALITY_LITE];
    let atleastOneResponseQualityIsSelected = false;
    if (inviteDetails.access.length > 0) {
      atleastOneResponseQualityIsSelected = inviteDetails.access.some(access => responseQualityModes.includes(access));
    }
    return (
      inviteDetails.access.length > 0 &&
      atleastOneResponseQualityIsSelected &&
      inviteDetails.email.length > 0 &&
      inviteDetails.firstName.length > 0 &&
      inviteDetails.lastName.length > 0
    );
  }, [
    inviteDetails.access.length,
    inviteDetails.email.length,
    inviteDetails.firstName.length,
    inviteDetails.lastName.length,
  ]);

  return (
    <ZModal defaultOpen onOpenChange={(isOpen) => !isOpen && onCloseDialog()}>
      <ZModalContent>
        <ZModalHeader className="w-full flex flex-col gap-2">
          <h4 className="text-center">Invite Member</h4>
          <p className="text-center font-normal text-sm text-[#777780]">
            Invite a member to your organization
          </p>
        </ZModalHeader>
        <ZModalBody className="w-full px-6 py-6 gap-6">
          <div className="w-full flex flex-col gap-4">
            <div className="flex items-center gap-4">
              <ZInput
                variant="faded"
                type="text"
                placeholder="John"
                onChange={handleOnChange}
                label="First Name"
                name="firstName"
                id="firstName"
                disabled={loading}
                className="focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-1 border-gray-300 shadow-none rounded-xl"
                classNames={{
                  inputWrapper: "w-full shadow-none border-none rounded-xl",
                }}
              />
              <ZInput
                variant="faded"
                type="text"
                placeholder="Doe"
                label="Last Name"
                onChange={handleOnChange}
                name="lastName"
                id="lastName"
                disabled={loading}
                className="focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-1 border-gray-300 shadow-none rounded-xl"
                classNames={{
                  inputWrapper: "w-full shadow-none border-none rounded-xl",
                }}
              />
            </div>
            <ZInput
              variant="faded"
              type="email"
              label="Email"
              onChange={handleOnChange}
              placeholder="johndoe@email.com"
              name="email"
              id="email"
              disabled={loading}
              className="focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-1 border-gray-300 shadow-none rounded-xl"
              classNames={{
                inputWrapper: "w-full shadow-none border-none rounded-xl",
              }}
            />
            <Select
              id="role"
              name="role"
              label="Role"
              labelPlacement="outside"
              placeholder="Select role"
              items={getZaniaRoleLabelMap().filter(
                ({ roleId }) => currentMemberRoles().indexOf(roleId) > -1,
              )}
              className="w-full border-1 border-gray-300 rounded-xl"
              classNames={{
                trigger: "w-full shadow-none border-none rounded-xl py-2",
              }}
              selectedKeys={[inviteDetails.role]}
              onSelectionChange={(role) => {
                setInviteDetails((prev) => ({
                  ...prev,
                  role: Array.from(role as Set<TeamRole>)[0],
                }));
              }}
              disabled={loading}
            >
              {(data) => (
                <SelectItem
                  key={data.roleId}
                  textValue={data.label}
                  value={data.roleId}
                  description={
                    typeof data.description === "string" &&
                      data.description.length > 0
                      ? data.description
                      : undefined
                  }
                >
                  {data.label}
                </SelectItem>
              )}
            </Select>
            <div className="flex flex-col gap-y-2">
              <label
                className="pointer-events-none text-small max-w-full text-ellipsis overflow-hidden"
                id="access-label"
                htmlFor="access"
              >
                Access
              </label>
              <Select
                id="access"
                name="access"
                label={null}
                aria-label="Access"
                aria-labelledby="access-label"
                placeholder="Select access"
                selectionMode="multiple"
                multiple={true}
                isMultiline={true}
                items={getZaniaRoleLabelMap().filter(({ roleId }) =>
                  listOfAccess.includes(roleId),
                )}
                className="w-full border-1 border-gray-300 rounded-xl"
                classNames={{
                  trigger: "w-full shadow-none border-none rounded-xl py-2",
                }}
                renderValue={(items) => {
                  return (
                    <div className="flex flex-wrap gap-2">
                      {items.map(({ data, textValue, key }) => (
                        <Chip
                          key={key}
                          variant="bordered"
                          radius="sm"
                          isCloseable={true}
                          onClose={() => {
                            setInviteDetails((prev) => ({
                              ...prev,
                              access: prev.access.filter(
                                (roleId) => roleId !== key,
                              ),
                            }));
                          }}
                          className="bg-white border-small"
                          isDisabled={loading}
                        >
                          {textValue}
                        </Chip>
                      ))}
                    </div>
                  );
                }}
                selectedKeys={[...inviteDetails.access]}
                onSelectionChange={(value) => {
                  setInviteDetails((prev) => ({
                    ...prev,
                    access: Array.from(value as Set<TeamRole>),
                  }));
                }}
                disabled={loading}
              >
                <SelectSection
                  title="AI Agent"
                  classNames={
                    {
                      // heading: headingClasses,
                    }
                  }
                >
                  {getZaniaRoleLabelMap()
                    .filter(({ roleId }) =>
                      [
                        ...Object.values(
                          orgMetaData?.feature_roles ?? {},
                        ),
                      ].includes(
                        roleId as
                        | TeamRole.ZANIA_GAP_ASSESSMENT
                        | TeamRole.ZANIA_DORA_ASSESSMENT
                        | TeamRole.ZANIA_SOC2_ASSESSMENT
                        | TeamRole.ZANIA_RISK_ASSESSMENT
                        | TeamRole.ZANIA_VENDOR_ASSESSMENT,
                      ),
                    )
                    .map((data) => (
                      <SelectItem
                        key={data.roleId}
                        textValue={data.label}
                        value={data.roleId}
                        hideSelectedIcon
                        className="gap-4 px-2 py-2 overflow-hidden"
                        classNames={{
                          title: "overflow-auto whitespace-normal",
                        }}
                      >
                        <div className="h-full w-full flex gap-2 items-start overflow-hidden">
                          <Checkbox
                            isSelected={inviteDetails.access.includes(
                              data.roleId,
                            )}
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                            onMouseDown={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                            onChange={({ target: { checked } }) => {
                              setInviteDetails((prev) => ({
                                ...prev,
                                access: checked
                                  ? prev.access.includes(data.roleId)
                                    ? prev.access
                                    : prev.access.concat(data.roleId)
                                  : prev.access.filter(
                                    (roleId) => roleId !== data.roleId,
                                  ),
                              }));
                            }}
                            disabled={loading}
                          />
                          <div className="h-full w-full overflow-hidden">
                            <span className="text-small">{data.label}</span>
                          </div>
                        </div>
                      </SelectItem>
                    ))}
                </SelectSection>
                <SelectSection title="Response Quality">
                  {getZaniaRoleLabelMap()
                    .filter(({ roleId }) => {
                      const responseQualityRoles = Object.values(
                        orgMetaData?.response_quality_roles ??
                        {},
                      );
                      return [
                        ...responseQualityRoles,
                        ""
                      ].includes(roleId)
                    }
                      ,
                    )
                    .map((data) => (
                      <SelectItem
                        key={data.roleId}
                        textValue={data.label}
                        value={data.roleId}
                        hideSelectedIcon
                        className="gap-4 px-2 py-2 overflow-hidden"
                        classNames={{
                          title: "overflow-auto whitespace-normal",
                        }}
                      >
                        <div className="h-full w-full flex gap-2 items-start overflow-hidden">
                          <Checkbox
                            isSelected={
                              inviteDetails.access.includes(data.roleId)
                            }
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                            onMouseDown={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                            onChange={({ target: { checked } }) => {
                              setInviteDetails((prev) => ({
                                ...prev,
                                access: checked
                                  ? prev.access.includes(data.roleId)
                                    ? prev.access
                                    : prev.access.concat(data.roleId)
                                  : prev.access.filter(
                                    (roleId) => roleId !== data.roleId,
                                  ),
                              }));
                            }}
                            disabled={loading}
                          />
                          <div className="h-full w-full overflow-hidden">
                            <span className="text-small">{data.label}</span>
                          </div>
                        </div>
                      </SelectItem>
                    ))}
                </SelectSection>
              </Select>
            </div>
          </div>
        </ZModalBody>
        <ZModalFooter className="w-full justify-stretch">
          <ZButton
            variant="bordered"
            onClick={onCloseDialog}
            disabled={loading}
            className="flex-auto"
          >
            Cancel
          </ZButton>
          <ZButton
            isLoading={loading}
            onClick={() => void handleOnClickInvite()}
            disabled={loading || !isInviteDetailsValid}
            isDisabled={!isInviteDetailsValid}
            className="flex-auto"
          >
            {loading ? "Please Wait" : "Invite to Team"}
          </ZButton>
        </ZModalFooter>
      </ZModalContent>
    </ZModal>
  );
};

export default TeamInviteDialog;

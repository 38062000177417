import { post } from "@/infra/rest";
import { getZaniaSocket } from "@/infra/sockets";
import {
  DoraRagCreateRequest,
  MultiFileVendorRagCreateRequest,
  NistCsf2RagCreateRequest,
  Soc2RagCreateRequest,
} from "@/modules/sessions/types";

export const emitRagCreate = (
  data:
    | Soc2RagCreateRequest
    | NistCsf2RagCreateRequest
    | DoraRagCreateRequest
    | MultiFileVendorRagCreateRequest,

  callback?: (...args: any[]) => void,
) => {
  const RAG_IDENTIFIER = "task:rag:create";
  const socket = getZaniaSocket();

  socket.emit(RAG_IDENTIFIER, data, callback);
};

interface RAGDataRequest {
  task: string;
  output_format: string;
  response_quality: string;
  agent_session_id: string;
}

interface RAGResponse {
  success: boolean;
  message: string;
  data: {
    file_url: string;
    output_format: string;
  };
}

export const getRAGExcelFromJSON = (data: RAGDataRequest) => {
  return post<RAGDataRequest, RAGResponse>({
    url: "/v1/rag/converter",
    data,
  });
};

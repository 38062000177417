import { getZaniaSocket } from "@/infra/sockets";
import { AgentSocketEvents, type ControlEditPayload } from "../types/events";
import { getSessionJWT } from "@/infra/auth/helper";
import { VendorRiskSectionEnum } from "../types/vendorAssessment";
import { AgentSessionType } from "@/modules/sessions/types";
import { getAgentData } from "../states";
import { AGENT_TYPES, GapAssessmentTypes } from "../types";

export const useAgentSocket = () => {
	const emitControlEdit = async (
		fileUrl: string,
		control: any,
		controlId: string,
		agentId: string,
		stepId: string,
		editedField: string,
	) => {
		try {
			const socket = getZaniaSocket();
			const token = await getSessionJWT();
			const agentData = getAgentData(agentId);
   			if (!agentData) return;

			socket.emit(
				AgentSocketEvents.CONTROL_EDIT,
				{
					file_url: fileUrl,
					control,
					...(control.section === VendorRiskSectionEnum.vendor_overview
						? { control_key: controlId }
						: { control_id: controlId }),
					agent_session_id: agentId,
					agent_session_step_id: stepId,
					field: editedField,
					task: agentData.agentType === AGENT_TYPES.GAP_ASSESSMENT
							? agentData.sessionData.type === AgentSessionType.SOC2_GAP_TYPE1
								? "soc2_type1"
								: agentData.sessionData.type === AgentSessionType.SOC2_GAP_TYPE2
								? "soc2_type2"
								: "soc2_type2_audit"
							: agentData.agentType === AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT
							? "multi_file_vendor_assessment"
							: "nist_csf_2_risk_assessment",
				},
				(error: Error) => {
					if (error) {
						console.error("Socket emit error:", error);
					} else {
						console.log("Control edit emitted successfully");
					}
				},
			);
		} catch (error) {
			console.error("Error in emitControlEdit:", error);
			throw error;
		}
	};

	return { emitControlEdit };
};

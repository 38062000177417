import ccpaImage from "../../../shared/images/CCPA.jpg";
import fedrampImage from "../../../shared/images/FR.jpg";
import gdprImage from "../../../shared/images/GDPR.jpg";
import hipaaImage from "../../../shared/images/HIPAA.jpg";
import iso27001Image from "../../../shared/images/ISO27001.jpg";
import nist80053Image from "../../../shared/images/NIST53.jpg";
import pciImage from "../../../shared/images/PCIDSS.jpg";
import socImage from "../../../shared/images/SOC.jpg";

export const VENDOR_DATA_TYPES_HANDLED = [
  { label: "PII", value: "PII" },
  { label: "PHI", value: "PHI" },
  { label: "Financial", value: "Financial" },
  { label: "Intellectual Property", value: "Intellectual Property" },
  { label: "Public", value: "Public" },
  { label: "Other", value: "Other" },
] as const;

export const VENDOR_DATA_ACCESS_SCOPE = [
  { label: "No Vendor Personnel Access", value: "No Vendor Personnel Access" },
  { label: "Limited Vendor Personnel", value: "Limited Vendor Personnel" },
  { label: "All Vendor Personnel", value: "All Vendor Personnel" },
  {
    label: "Vendors Subcontractors or Partners",
    value: "Vendors Subcontractors or Partners",
  },
  { label: "Vendors AI/ML Systems Only", value: "Vendors AI/ML Systems Only" },
  { label: "Other", value: "Other" },
] as const;

export const VENDOR_DATA_STORAGE = [
  { label: "Vendor on-premises", value: "Vendor on-premises" },
  { label: "Our on-premises", value: "Our on-premises" },
  { label: "Public Cloud", value: "Public Cloud" },
  { label: "Private Cloud", value: "Private Cloud" },
  { label: "Hybrid", value: "Hybrid" },
  { label: "N/A", value: "N/A" },
] as const;

export const VENDOR_BUSINESS_CRITICALITY = [
  {
    label: "Mission-Critical (Impacts most or all operations)",
    value: "Mission-Critical",
  },
  { label: "High Importance", value: "High Importance" },
  { label: "Moderate", value: "Moderate" },
  { label: "Low", value: "Low" },
  { label: "Negligible", value: "Negligible" },
] as const;

export const VENDOR_INCIDENTS_IN_LAST_36_MONTHS = [
  { label: "No known incidents", value: "No known incidents" },
  { label: "Minor Incidents only", value: "Minor Incidents only" },
  { label: "One major incident", value: "One major incident" },
  { label: "Multiple major incidents", value: "Multiple major incidents" },
  { label: "Unknown", value: "Unknown" },
] as const;

export const VENDOR_SYSTEM_ACCESS = [
  {
    label: "No access to any of our systems",
    value: "No access to any of our systems",
  },
  {
    label: "API access only (limited to specific data/functions",
    value: "API access only (limited to specific data/functions",
  },
  {
    label: "Read-only access to specific applications",
    value: "Read-only access to specific applications",
  },
  {
    label: "Limited write access to specific applications",
    value: "Limited write access to specific applications",
  },
  { label: "Unknown", value: "Unknown" },
] as const;

export const VENDOR_PREDEFINED_COMPLIANCE_CERTS = [
  { label: "SOC 2", logo: socImage },
  { label: "PCI DSS", logo: pciImage },
  { label: "FedRAMP", logo: fedrampImage },
  { label: "ISO 27001", logo: iso27001Image },
  { label: "GDPR", logo: gdprImage },
  { label: "NIST 800-53", logo: nist80053Image },
  { label: "HIPAA", logo: hipaaImage },
  { label: "CCPA", logo: ccpaImage },
] as const;


export const VENDOR_OVERVIEW_KEY_MAPPING ={
  risk_rating: "risk_rating",
  product_service_info: 'description_of_services',
  integration_overview : 'integration_overview',
  compliance:'compliance',
  data_types_handled : 'data_types_handled',
  security_incidents : 'security_incidents',
  company_info : "company_description"
}
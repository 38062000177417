import { useAgentSelector } from "@/modules/agent/states";
import { ConfidenceTypes } from "@/modules/agent/types";
import {
  VendorSupplyChainInfrastructureItem,
  VendorSupplyChainSoftwareItem,
} from "@/modules/agent/types/vendorSupplyChain";
import { ZButton } from "@/shared/components/button";
import { ZTextarea } from "@/shared/components/input";
import {
  ZModal,
  ZModalBody,
  ZModalContent,
  ZModalFooter,
  ZModalHeader,
} from "@/shared/components/modal";
import { addNotification } from "@/shared/states/notification";
import { Input, Select, SelectItem } from "@nextui-org/react";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { IdParams } from "../Overview/Overview";
import { useVendorRiskAssessmentStore } from "../useVendorRiskAssessment/useVendorRiskAssessmentStore";

export type IAddSubServicesPopupProps =
  | {
    handleClose: () => void;
    type: "EDIT";
    editKey: string;
    editIdx: number;
  }
  | {
    handleClose: () => void;
    type: "ADD";
  };

const AddSubServicesPopup = (props: IAddSubServicesPopupProps) => {
  const { id } = useParams<keyof IdParams>() as IdParams;
  const { handleClose } = props;
  const [selectedDropdownOption, setSelectedDropdownOption] = useState("");
  const [name, setName] = useState("");
  const [purpose, setPurpose] = useState("");
  const { setVendorSupplyChain, vendorSupplyChainData } =
    useVendorRiskAssessmentStore.getState();
  const { setSelectedId } = useAgentSelector.useActions();

  useEffect(() => {
    if (props.type === "EDIT") {
      const findkeyIndex = vendorSupplyChainData.find(
        (d) => d.key === props.editKey,
      );
      if (findkeyIndex) {
        setSelectedDropdownOption(props.editKey);
        setName(
          (
            findkeyIndex.value.value[
            props.editIdx
            ] as VendorSupplyChainInfrastructureItem
          ).infrastructure_provider ||
          (
            findkeyIndex.value.value[
            props.editIdx
            ] as VendorSupplyChainSoftwareItem
          ).software ||
          "",
        );
        setPurpose(findkeyIndex.value.value[props.editIdx].purpose);
      } else {
        handleClose();
      }
    }
  }, [handleClose, props, vendorSupplyChainData]);

  const buttonDisabled = useMemo(() => {
    return (
      selectedDropdownOption.length === 0 ||
      name.length === 0 ||
      purpose.length === 0
    )
  }, [name.length, purpose.length, selectedDropdownOption.length]);

  const handleSubmitClick = () => {
    const temp = [...vendorSupplyChainData];

    if (props.type === "ADD") {
      const vendorSupplySelectedkey = temp.find(
        (d) => d.key === selectedDropdownOption,
      );
      if (vendorSupplySelectedkey) {
        const isDuplicate = vendorSupplySelectedkey.value.value.some(item => {
          const itemName = 'infrastructure_provider' in item
            ? item.infrastructure_provider
            : item.software;
          return itemName.toLowerCase() === name.toLowerCase() &&
            item.purpose.toLowerCase() === purpose.toLowerCase();
        });

        if (isDuplicate) {
          addNotification({
            type: "error",
            message: "A subservice already exists"
          });
          return;
        }

        if (vendorSupplySelectedkey.key === "infrastructure") {
          vendorSupplySelectedkey.value.value.push({
            purpose: purpose,
            infrastructure_provider: name,
            confidence: ConfidenceTypes.DNF,
            sources: [],
          });
        }
        if (vendorSupplySelectedkey.key === "software") {
          vendorSupplySelectedkey.value.value.push({
            purpose: purpose,
            software: name,
            confidence: ConfidenceTypes.DNF,
            sources: [],
          });
        }
        setSelectedId(id, vendorSupplySelectedkey.key);
      }
    } else {
      const vendorSupplySelectedkey = temp.find((d) => d.key === props.editKey);
      if (vendorSupplySelectedkey) {
        vendorSupplySelectedkey.value.value[props.editIdx] =
          selectedDropdownOption === "software"
            ? ({
              purpose: purpose,
              software: name,
              confidence: vendorSupplySelectedkey.value.value[props.editIdx].confidence,
              sources: vendorSupplySelectedkey.value.value[props.editIdx].sources,
            } as VendorSupplyChainSoftwareItem)
            : ({
              purpose: purpose,
              infrastructure_provider: name,
              confidence: vendorSupplySelectedkey.value.value[props.editIdx].confidence,
              sources: vendorSupplySelectedkey.value.value[props.editIdx].sources,
            } as VendorSupplyChainInfrastructureItem);
        setSelectedId(id, vendorSupplySelectedkey.key);
      }
    }

    setVendorSupplyChain(temp);
    addNotification({
      type: "success",
      message:
        props.type === "ADD"
          ? "New subservice added successfully"
          : "Subservice updated successfully",
    });

    handleClose();
  };

  return (
    <ZModal
      isOpen={true}
      onOpenChange={() => {
        handleClose();
      }}
      aria-label="Add Sub Services"
      className="max-h-[calc(100vh-150px)] overflow-y-auto"
    >
      <ZModalContent className="max-w-lg flex flex-col gap-6">
        <ZModalHeader className="flex text-lg flex-col w-full items-center pb-0">
          <p>{props.type === "ADD" ? "Add" : "Edit"} Subservice</p>
        </ZModalHeader>

        <ZModalBody className="pt-0 overflow-auto flex gap-8">
          <Select
            items={[
              { key: "software", label: "Software" },
              { key: "infrastructure", label: "Infrastructure" },
            ]}
            label={"Type"}
            labelPlacement="outside"
            value={selectedDropdownOption}
            placeholder="Select Type"
            onChange={(value) => {
              setSelectedDropdownOption(value.target.value);
            }}
            selectedKeys={
              selectedDropdownOption ? [selectedDropdownOption] : []
            }
            isDisabled={props.type === "EDIT"}
          >
            {(item) => (
              <SelectItem key={item.key} value={item.key}>
                {item.label}
              </SelectItem>
            )}
          </Select>

          <Input
            label="Name"
            labelPlacement="outside"
            placeholder="Add name"
            value={name}
            onChange={(e) => setName(e.target.value.trimStart())}
            className="w-full shadow-none rounded-sm bg-white"
          />
          <ZTextarea
            label="Purpose"
            labelPlacement="outside"
            placeholder="Add purpose"
            value={purpose}
            onChange={(e) => setPurpose(e.target.value.trimStart())}
            className="w-full rounded-sm bg-white"
          />
        </ZModalBody>
        <ZModalFooter className="flex gap-4">
          <ZButton
            variant="bordered"
            onClick={() => handleClose()}
            className="px-3 py-1 text-medium flex-1"
          >
            Discard
          </ZButton>
          <ZButton
            type="submit"
            onClick={handleSubmitClick}
            isDisabled={buttonDisabled}
            className="px-3 py-1 text-medium flex-1"
          >
            Submit
          </ZButton>
        </ZModalFooter>
      </ZModalContent>
    </ZModal>
  );
};

export default AddSubServicesPopup;

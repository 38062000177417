import { getZaniaTeamMemberRoles } from "@/modules/team/states";
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import ZAvatarGradient from "@/shared/components/avatarGradient";
import appConfig from "@/shared/config/app";
import { ENVTYPE } from "@/shared/constants";
import { useUserPermissions } from "@/shared/hooks/use-user-permissions";
import { addNotification } from "@/shared/states/notification";
import { useUserStateSelector } from "@/shared/states/user";
import { AiResponseType } from "@/shared/types/user";
import { Button, Radio, RadioGroup, Switch, cn } from "@nextui-org/react";
import { useEffect, useMemo, useState } from "react";
import { addToSlack } from "../../requests";
import SlackLogo from "/slack.png";
import { useLoggedInMember } from "@/modules/auth/states";

// pylon chat support
declare global {
  interface Window {
    pylon?: any;
  }
}
const AIResponseHelper = [
  {
    title: "Lite",
    description:
      "Use an affordable model and generate a partially complete report",
    value: AiResponseType.LITE,
    notificationMessage:
      "Your AI Agent will use a cheaper model and generate a partially complete report",
  },
  {
    title: "Speed",
    description: "Use a premium model and generate a partially complete report",
    value: AiResponseType.SPEED,
    notificationMessage:
      "Your AI Agent will use a premium model and generate a partially complete report",
  },
  {
    title: "Accuracy",
    description: "Use a premium model and generate a fully complete report",
    value: AiResponseType.ACCURACY,
    notificationMessage:
      "Your AI Agent will use a premium model and generate fully complete reports",
  },
];
const mode = import.meta.env.MODE;
const SettingsMainPage = () => {
  const { isDemoActive } = useUserStateSelector.useDemo();
  const setDemoActive = useUserStateSelector.useSetDemoActive();
  const setAIResponseType = useUserStateSelector.useSetAIResponseType();
  const storedAIResponseType = useUserStateSelector.useAiResponseType();
  const [loading, setLoading] = useState(false);
  const permissions = useUserPermissions();

 const member = useLoggedInMember();
  
  useEffect(() => {
    // enable pylon chat only if logged in member is available
    if (member?.email_address) {
      window.pylon = {
        chat_settings: {
          app_id: appConfig.pylonAppId,
          email: member?.email_address,
          // pass email as name if name is not available
          name: member?.name || member?.email_address,
        },
      };
    }
    // set account_id every 10 seconds for getting new messages
    const interval = setInterval(() => {
      if (window.pylon && window.pylon.chat_settings) {
          window.pylon.chat_settings = {
              ...window.pylon.chat_settings,
              account_id: new Date().toISOString()
          };
      }
  }, 10000);
  
    //remove chat support on unmount
    return () => {
      clearInterval(interval);
      delete window.pylon;
    };
  }, [member]);
  const initialAIResponseType = useMemo(() => {
    if (
      (storedAIResponseType === AiResponseType.ACCURACY &&
        permissions?.canAccessAccuracyResponse.hasAccess) ||
      (storedAIResponseType === AiResponseType.SPEED &&
        permissions?.canAccessSpeedResponse.hasAccess) ||
      (storedAIResponseType === AiResponseType.LITE && permissions?.canAccessLiteResponse.hasAccess)
    ) {
      return storedAIResponseType;
    }
    if (mode === ENVTYPE.PRODUCTION) {
      if (permissions?.canAccessAccuracyResponse.hasAccess) {
        return AiResponseType.ACCURACY;
      } else if (permissions?.canAccessSpeedResponse.hasAccess) {
        return AiResponseType.SPEED;
      }
    }
    let defaultResponseQuality = AiResponseType.LITE;
    if (permissions?.canAccessLiteResponse) {
      defaultResponseQuality = AiResponseType.LITE;
    } else if (permissions?.canAccessSpeedResponse) {
      defaultResponseQuality = AiResponseType.SPEED;
    } else if (permissions?.canAccessAccuracyResponse) {
      defaultResponseQuality = AiResponseType.ACCURACY;
    }
    return defaultResponseQuality;
  }, [permissions, storedAIResponseType]);
  const [aiResponseTypeValue, setAiResponseTypeValue] =
    useState<AiResponseType>(initialAIResponseType);

  useEffect(() => {
    if (storedAIResponseType) {
      setAIResponseType(aiResponseTypeValue);
    }
  }, [aiResponseTypeValue, setAIResponseType, storedAIResponseType]);

  const handleAddToSlack = async () => {
    try {
      setLoading(true);
      const { install_url } = await addToSlack();
      window.open(install_url, "_blank")?.focus();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const userCurrentRole = useMemo(() => {
    const memberId = member?.member_id || "";
    const { isTeamOrgAdmin, isTeamAdmin, isTeamMember } =
      member ? getZaniaTeamMemberRoles(memberId, member) : { isTeamOrgAdmin: false, isTeamAdmin: false, isTeamMember: false };
    return isTeamOrgAdmin
      ? "Owner"
      : isTeamAdmin
        ? "Admin"
        : isTeamMember
          ? "Member"
          : "";
  }, [member?.member_id]);

  return (
    <div className="p-6 flex flex-col gap-12 w-full h-full overflow-auto">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-1">
          <h4 className="text-sm font-medium leading-5 text-[#171717]">
            Profile
          </h4>
          <p className="text-sm text-[#777780] font-normal">
            Update how your profile looks on the platform
          </p>
        </div>
        <div className="flex items-center gap-4 p-4 bg-[#FAFAFA] border-[1px] border-[#E4E4E7] rounded-[12px]">
          <div className="max-w-48">
            <ZAvatarGradient
              name={member?.name ?? ""}
              size={56}
              textSize={24}
            />
          </div>
          <div className="flex flex-col gap-2 grow">
            <h5 className="text-lg font-semibold uppercase">{member?.name}</h5>
            <div className="flex gap-9">
              <span className="flex items-center text-sm text-[#717179]">
                <div className="mx-1.5">
                  <MailIcon />
                </div>
                {member?.email_address}
              </span>
              <span className="flex items-center text-sm text-[#717179]">
                <div className="mx-1.5">
                  <IdIcon />
                </div>
                {userCurrentRole}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <h4 className="text-sm font-medium leading-5 text-[#171717]">
            Response Quality
          </h4>
          <p className="text-sm text-[#777780]">
            Optimize the response quality of your AI Agent
          </p>
        </div>
        <div className="flex gap-4 py-4">
          <div className="w-full flex justify-between gap-4">
            <RadioGroup
              classNames={{
                base: "flex-row w-full p-0",
                wrapper: "flex-row w-full flex-nowrap gap-4",
              }}
              orientation="horizontal"
              value={aiResponseTypeValue}
              onValueChange={(value) => {
                setAiResponseTypeValue(value as AiResponseType);
                setAIResponseType(value as AiResponseType);
                const data = AIResponseHelper.find(
                  (item) => item.value === (value as AiResponseType),
                );
                addNotification({
                  title: `Response Quality Changed to ${data?.title}`,
                  message: data?.notificationMessage,
                  type: "success",
                });
              }}
            >
              {AIResponseHelper.map(({ title, value, description }) => {
                if (
                  value === AiResponseType.ACCURACY &&
                  !permissions?.canAccessAccuracyResponse.hasAccess
                )
                  return null;
                if (
                  value === AiResponseType.SPEED &&
                  !permissions?.canAccessSpeedResponse.hasAccess
                )
                  return null;
                if (
                  value === AiResponseType.LITE &&
                  !permissions?.canAccessLiteResponse.hasAccess
                )
                  return null;
                return (
                  <Radio
                    value={value}
                    key={title}
                    classNames={{
                      base: cn(
                        "flex-1 m-0 bg-[#FAFAFA] !border-[1px] !border-[#E4E4E7] hover:bg-content2 items-start justify-between",
                        "cursor-pointer rounded-xl gap-0 p-4 border-2 border-transparent",
                        "data-[selected=true]:bg-white data-[selected=true]:!border-[#A982CF]",
                      ),
                    }}
                    description={description}
                  >
                    {title}
                  </Radio>
                );
              })}
            </RadioGroup>
          </div>
        </div>
      </div>
      {/* Env Condition */}
      {mode !== ENVTYPE.PRODUCTION ? (
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-1">
            <div className="flex justify-start items-start">
              <h4 className="text-sm font-medium leading-5 text-[#171717] pt-1">
                Demo Workspace
              </h4>
              <Switch
                classNames={{
                  base: "self-start px-4 mb-0",
                }}
                defaultSelected
                isSelected={isDemoActive}
                onValueChange={(value) => setDemoActive(value)}
              ></Switch>
            </div>
            <p className="text-sm text-[#777780]">
              Internal use only for sales
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* Env Condition */}
      {mode !== ENVTYPE.PRODUCTION ? (
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <h4 className="text-sm font-medium leading-5 text-[#171717]">
              Launch your AI Agent on Other Apps
            </h4>
            <p className="text-sm text-[#777780]">
              Access your AI Agent anywhere and enhance your security and
              compliance experience
            </p>
          </div>
          <div className="flex items-center gap-4 p-4 bg-[#FAFAFA] border-[1px] border-[#E4E4E7] rounded-[12px] w-fit">
            <div className="w-16 h-16 ">
              <img src={SlackLogo} alt="slack-logo" className="w-full h-full" />
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex justify-between items-center">
                <p className="font-semibold text-md">Slack</p>
                <Button
                  size="sm"
                  variant="bordered"
                  color="primary"
                  className="text-tiny p-0 px-2 h-7 rounded-md"
                  isLoading={loading}
                  onClick={() => void handleAddToSlack()}
                >
                  Add to Slack
                </Button>
              </div>
              <div className="flex text-sm">
                <p className="text-[#777780]">
                  Use the slack bot to access functionalities within your slack
                  workspace
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* Below code is for the Contact us section in the settings */}
      <div className="flex flex-col p-16 justify-center items-center border-solid border-t-1 border=[#E4E4E7]">
        <div className="flex flex-col gap-2 justify-center items-center">
          <h4 className="text-2xl font-semibold">Looking for assistance?</h4>
          <p className="text-base text-[#777780]">
          Click the icon in the bottom-right corner to chat live with support, or email {" "}
          <a href="mailto:support@zania.ai" className="text-[#006FEE]">
              support@zania.ai
            </a>
          </p>
        </div>
      </div>
      {/* End of the above code */}
    </div>
  );
};

export default SettingsMainPage;

const MailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={16}
      height={16}
      color={"#717179"}
      fill={"none"}
    >
      <path
        d="M2 6L8.91302 9.91697C11.4616 11.361 12.5384 11.361 15.087 9.91697L22 6"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M2.01577 13.4756C2.08114 16.5412 2.11383 18.0739 3.24496 19.2094C4.37608 20.3448 5.95033 20.3843 9.09883 20.4634C11.0393 20.5122 12.9607 20.5122 14.9012 20.4634C18.0497 20.3843 19.6239 20.3448 20.7551 19.2094C21.8862 18.0739 21.9189 16.5412 21.9842 13.4756C22.0053 12.4899 22.0053 11.5101 21.9842 10.5244C21.9189 7.45886 21.8862 5.92609 20.7551 4.79066C19.6239 3.65523 18.0497 3.61568 14.9012 3.53657C12.9607 3.48781 11.0393 3.48781 9.09882 3.53656C5.95033 3.61566 4.37608 3.65521 3.24495 4.79065C2.11382 5.92608 2.08114 7.45885 2.01576 10.5244C1.99474 11.5101 1.99475 12.4899 2.01577 13.4756Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const IdIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={16}
    height={16}
    color={"#717179"}
    fill={"none"}
    {...props}
  >
    <path
      d="M8.5 18C10.3135 16.0463 13.667 15.9543 15.5 18M13.9406 12C13.9406 13.1046 13.0688 14 11.9934 14C10.918 14 10.0462 13.1046 10.0462 12C10.0462 10.8954 10.918 10 11.9934 10C13.0688 10 13.9406 10.8954 13.9406 12Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M9.5 4.00195C6.85561 4.01181 5.44101 4.10427 4.52513 4.97195C3.5 5.94312 3.5 7.5062 3.5 10.6324V15.3692C3.5 18.4954 3.5 20.0584 4.52513 21.0296C5.55025 22.0008 7.20017 22.0008 10.5 22.0008H13.5C16.7998 22.0008 18.4497 22.0008 19.4749 21.0296C20.5 20.0584 20.5 18.4954 20.5 15.3692V10.6324C20.5 7.5062 20.5 5.94312 19.4749 4.97195C18.559 4.10427 17.1444 4.01181 14.5 4.00195"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.77216 3.63163C9.8681 3.21682 9.91608 3.00942 10.0082 2.84004C10.2229 2.44546 10.6188 2.15548 11.0914 2.0467C11.2943 2 11.5296 2 12 2C12.4704 2 12.7057 2 12.9086 2.0467C13.3812 2.15548 13.7771 2.44545 13.9918 2.84004C14.0839 3.00942 14.1319 3.21682 14.2278 3.63163L14.3111 3.99176C14.4813 4.72744 14.5664 5.09528 14.438 5.37824C14.3549 5.5615 14.2132 5.71842 14.031 5.82911C13.7496 6 13.3324 6 12.4981 6H11.5019C10.6676 6 10.2504 6 9.96901 5.82911C9.78677 5.71842 9.6451 5.5615 9.56197 5.37824C9.43361 5.09528 9.51869 4.72744 9.68886 3.99176L9.77216 3.63163Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

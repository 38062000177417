import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { create } from "zustand";
import { mutative } from "zustand-mutative";

export interface ControlSection {
  expanded: boolean;
  category: string;
  label: string;
}

export type SecurityControlCategory =
  | "Logical and Physical Access Controls"
  | "Risk Mitigation"
  | "Communication and Information"
  | "Change Management "
  | "Monitoring Activities"
  | "System Operations"
  | "Control Activities "
  | "Risk Assessment"
  | "Control Environment";

export const SecurityControlCategoryOption = [
  {
    value: "Control Environment",
    label: "Control Environment",
  },
  {
    value: "Communication and Information",
    label: "Information and Communication",
  },
  {
    value: "Risk Assessment",
    label: "Risk Assessment",
  },
  {
    value: "Monitoring Activities",
    label: "Monitoring Activities",
  },
  {
    value: "Control Activities",
    label: "Control Activities",
  },
  {
    value: "Logical and Physical Access Controls",
    label: "Logical and Physical Access Controls",
  },
  {
    value: "System Operations",
    label: "System Operations",
  },
  {
    value: "Change Management",
    label: "Change Management",
  },
  {
    value: "Risk Mitigation",
    label: "Risk Mitigation",
  },
];

export interface Control {
  tod: string;
  uid: string;
  category: SecurityControlCategory | "";
  tsc: TSCENUM;
  tsc_id: string;
  criteria: string;
  control_id: string;
  zania_control_id: string;
  control: string;
  toe: string;
  edited_state: "default" | "custom" | "edited" | "NEW" | "ACCORDIAN";
  changesInControl?: number; // used to define to show refine with ai
  changesInTestingProcedure?: number; // used to define to show refine with ai
  new_control?: string; // ai generated control description
  new_tod?: string; // ai generated testing procedure
  new_toe?: string; //ai generated testing procedure
  // selected: boolean;
}

export enum TSCENUM {
  SECURITY = "security",
  AVAILABILITY = "availability",
  CONFIDENTIALITY = "confidentiality",
  PROCESSING_INTEGRITY = "processing integrity",
  PRIVACY = "privacy",
}

export const TSCABBRIVATIONS = {
  [TSCENUM.AVAILABILITY]: "A",
  [TSCENUM.CONFIDENTIALITY]: "C",
  [TSCENUM.PROCESSING_INTEGRITY]: "PI",
  [TSCENUM.PRIVACY]: "P",
  [TSCENUM.SECURITY]: "CC",
} as const;

export interface CustomizeControlsStore {
  accordianOpenState: {
    [key in TSCENUM]: boolean;
  };
  selectedControls: Set<string>;
  dataState: Control[];
  selectedTab: TSCENUM;
  searchQuery: string;
  changesMade: number;
  newControlPlaceHolderUid: string;
  isSavingControls: "SAVED" | "SAVING" | "EDITED";
  noOfGenerateWithAiPopoverOpens: number;
  actions: {
    setAccordianOpenState: (key: TSCENUM, newValue: boolean) => void;
    setDataState: (newValue: Control[] | ((prev: Control[]) => Control[])) => void;
    setSelectedControls: (newVal: Set<string> | ((prev: Set<string>) => Set<string>)) => void;
    setIsSavingControls: (newVal: "SAVED" | "SAVING" | "EDITED") => void;
    setSelectedTab: (newVal: TSCENUM) => void;
    setSearchQuery: (newVal: string) => void;
    setChangesMade: (newVal: number | ((prev: number) => number)) => void;
    setNoOfGenerateWithAiPopoverOpens: (newVal: number | ((prev: number) => number)) => void;
    setNewControlPlaceHolderUid: (newVal: string) => void;
    reset: () => void;
  };
}
const initialState: Omit<CustomizeControlsStore, "actions" | "sectionWiseDataState"> = {
  accordianOpenState: {
    availability: true,
    confidentiality: true,
    "processing integrity": true,
    privacy: true,
    security: true,
  },
  searchQuery: "",
  selectedTab: TSCENUM.SECURITY,
  changesMade: 0,
  noOfGenerateWithAiPopoverOpens: 0,
  dataState: [],
  isSavingControls: "SAVED",
  newControlPlaceHolderUid: "",
  selectedControls: new Set<string>(),
};
export const transformListOfControlToSectionWiseControls = (
  dataState: Control[],
  accordianOpenState: {
    [key in TSCENUM]: boolean;
  },
) => {
  const sectionData = dataState.reduce(
    (acc, next) => {
      if (next.tsc in acc) {
        acc[next.tsc].push(next);
      } else {
        acc[next.tsc] = [next];
      }
      return acc;
    },
    {
      availability: [],
      confidentiality: [],
      "processing integrity": [],
      privacy: [],
      security: [],
    } as { [key in TSCENUM]: Control[] },
  );

  const output = [] as Control[];

  for (const key of Object.keys(sectionData)) {
    output.push({
      category: "",
      toe: "",
      tod: "",
      control: "",
      control_id: "",
      criteria: "",
      tsc: key as TSCENUM,
      tsc_id: key,
      uid: uuid(),
      zania_control_id: "",
      edited_state: "ACCORDIAN",
      // selected: false,
    });
    if (accordianOpenState[key as TSCENUM]) {
      output.push(...sectionData[key as TSCENUM]);
      output.push({
        category: "",
        toe: "",
        tod: "",
        control: "",
        control_id: "",
        criteria: "",
        tsc: key as TSCENUM,
        tsc_id: "",
        uid: uuid(),
        zania_control_id: "",
        edited_state: "NEW",
        // selected: false,
      });
    }
  }

  return output;
};
export const useCustomizeControlsStore = create<CustomizeControlsStore>()(
  mutative((set, get) => ({
    ...initialState,
    actions: {
      setAccordianOpenState: (key: TSCENUM, newValue: boolean) => {
        set({
          accordianOpenState: {
            ...get().accordianOpenState,
            [key]: newValue,
          },
          dataState: get().dataState.map((d) => ({
            ...d,
            new_control: undefined,
            new_tod: undefined,
            new_toe: undefined,
          })),
        });
      },
      setDataState: (newValue) => {
        set({
          dataState: typeof newValue === "function" ? newValue(get().dataState) : newValue,
        });
      },
      setNoOfGenerateWithAiPopoverOpens: (newValue) => {
        set({
          noOfGenerateWithAiPopoverOpens:
            typeof newValue === "function" ? newValue(get().noOfGenerateWithAiPopoverOpens) : newValue,
        });
      },
      setSelectedControls: (newValue) => {
        const newSelectedControls = typeof newValue === "function" ? newValue(get().selectedControls) : newValue;

        set({
          selectedControls: newSelectedControls,
          dataState: get().dataState.map((d) => ({
            ...d,
            selected: newSelectedControls.has(d.uid),
          })),
        });
      },
      setIsSavingControls: (newVal) => {
        set({
          isSavingControls: newVal,
        });
      },
      setSelectedTab: (newVal) => {
        set({
          selectedTab: newVal,
          dataState: get().dataState.map((ctl) => ({
            ...ctl,
            new_control: undefined,
            new_tod: undefined,
            new_toe: undefined,
          })),
          noOfGenerateWithAiPopoverOpens: 0,
        });
      },
      setSearchQuery: (newVal) => {
        set({
          searchQuery: newVal,
        });
      },
      setNewControlPlaceHolderUid: (newVal) => {
        set({
          newControlPlaceHolderUid: newVal,
        });
      },
      setChangesMade: (newVal) => {
        set({
          changesMade: typeof newVal === "function" ? newVal(get().changesMade) : newVal,
        });
      },
      reset: () => {
        set({
          ...initialState,
        });
      },
    },
  })),
);

export const useGetAccordionState = () => useCustomizeControlsStore().accordianOpenState;

export const getCustomizeControlsActions = () => useCustomizeControlsStore.getState().actions;

export const getCustomizeControls = () => useCustomizeControlsStore.getState().dataState;

export const getCustomizeControlsAiOpenStates = () =>
  useCustomizeControlsStore.getState().noOfGenerateWithAiPopoverOpens;

// export const useGetAccordionStateByTSC = (tsc: TSCENUM | undefined) =>
//   tsc === undefined
//     ? false
//     : useCustomizeControlsStore().accordianOpenState[tsc];

export const isControlValid = (control: Control): boolean => {
  if (control.tsc_id?.trim().length === 0 && control.control_id?.trim().length === 0) {
    return false;
  }
  if (control.tsc === TSCENUM.SECURITY && (control.category?.trim().length ?? 0) === 0) {
    return false;
  }
  if (control.control?.trim().length === 0) {
    return false;
  }
  if (control.toe?.trim().length === 0 || control.tod?.trim().length === 0) {
    return false;
  }

  return true;
};

export const useSubscribeCustomizeControlStoreWithSelector = (selectorFunc: any) => {
  const [value, setState] = useState(() => {
    return selectorFunc(useCustomizeControlsStore.getState());
  });

  useEffect(() => {
    const unsub = useCustomizeControlsStore.subscribe((prevState, newState) => {
      if (value !== selectorFunc(newState)) {
        setState(newState);
      }
    });
    return unsub;
  }, [value, selectorFunc]);

  return value;
};

import { useControlViewersStore } from "../states/control-viewers";

import { useTeamState } from "@/modules/team/states";
import { useLoggedInMember } from "@/modules/auth/states";
import { getAgentData } from "../states";

export const useControlEditStatus = (agentId: string, id: string) => {
	const loggedInMember = useLoggedInMember();
	const { editorsMap } = useControlViewersStore();
	const { team } = useTeamState();

	// get the control data to access id
	const agentData = getAgentData(agentId);
	const control = agentData?.mainData.reviewResponseData
		?.get(id)
		?.find((field) => field.key === "id" );
	const originalId = control?.value as string;

	// Use id for checking edit status
	const editorId = editorsMap.get(agentId)?.get(originalId);
	const isBeingEdited = !!editorId && editorId !== loggedInMember?.member_id;

	const editor = team.find((member) => member.member_id === editorId);
	const editorName = editor?.name || "Unknown User";

	return {
		isBeingEdited,
		editorId,
		editorName,
	};
};

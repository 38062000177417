import {
  AgentSessionStepType,
  AgentSessionType,
} from "@/modules/sessions/types";
import AcceptAndRejectPopOver from "@/shared/components/generate-with-ai/AcceptAndRejectPopOver";
import GenerateWithAI from "@/shared/components/generate-with-ai/GenerateWithAI";
import { AiResponseType } from "@/shared/types/user";
import { cn } from "@/shared/utils/classname-merger";
import {
  Checkbox,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Tooltip,
} from "@nextui-org/react";
import { ChevronDown, Info, Minus, PlusIcon } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getAgentData } from "../../states";
import {
  Control,
  getCustomizeControls,
  getCustomizeControlsActions,
  getCustomizeControlsAiOpenStates,
  SecurityControlCategoryOption,
  TSCABBRIVATIONS,
  TSCENUM,
} from "../../states/cusomize-controls";
import {
  refineControlsDescription,
  refineControlsTestingProcedure,
} from "../../use-cases/customize-control-ai-generate.use-case";
import {
  isInValidId,
  ZInputWithOnChangeDelay,
  ZTextAreaWithOnChangeDelay,
} from "./helpers";

interface baseProps {
  control: Control;
  searchQuery: string;
  handleChangeDataState: (
    key: keyof Control,
    uId: string,
    newValue: string,
    changesInTestingProcedure?: number
  ) => void;
}

interface CustomizeControlIdRendererProps extends baseProps {
  setAccordianOpenState: (key: TSCENUM, newValue: boolean) => void;
  accordianOpenState: {
    [key in TSCENUM]: boolean;
  };
  selectedControls: Set<string>;
  setSelectedControls: (
    newVal: Set<string> | ((prev: Set<string>) => Set<string>)
  ) => void;
  getDuplicateErrorMessage: (control: Control) => string | undefined;
}
export const CustomizeControlIdRenderer = ({
  control,
  handleChangeDataState,
  searchQuery,
  setAccordianOpenState,
  accordianOpenState,
  selectedControls,
  setSelectedControls,
  getDuplicateErrorMessage,
}: CustomizeControlIdRendererProps) => {
  const [isActive, setIsActive] = useState(false);

  const listOfid = getCustomizeControls()
    .filter((d) => d.uid !== control.uid)
    .filter(
      (ctl) =>
        control.tsc_id.replace(TSCABBRIVATIONS[ctl.tsc], "").trim().length > 0
    )
    .map((d) => d.tsc_id);

  const isDuplicateId = listOfid.includes(control.tsc_id);

  const isInIdValid =
    control.tsc_id.replace(TSCABBRIVATIONS[control.tsc], "").length > 0 &&
    isInValidId(control.tsc_id.replace(TSCABBRIVATIONS[control.tsc], ""));

  return (
    <div className={cn("flex w-[216px]")}>
      {control.edited_state === "ACCORDIAN" ? (
        <div className="flex gap-2 items-center py-[5px]">
          <div
            className="p-[1px]  rounded-[4px] cursor-pointer"
            onClick={() =>
              setAccordianOpenState(
                control.tsc,
                !accordianOpenState[control.tsc]
              )
            }
            onKeyDown={() =>
              setAccordianOpenState(
                control.tsc,
                !accordianOpenState[control.tsc]
              )
            }
          >
            {accordianOpenState[control.tsc] ? (
              <Minus size={16} color="#A5A5A5" />
            ) : (
              <PlusIcon size={16} color="#A5A5A5" />
            )}
          </div>
          <div className="text-[10px] leading-4 font-medium text-[#71717A] ">
            {control.tsc === TSCENUM.SECURITY
              ? "Security / Common Criteria".toUpperCase()
              : control.tsc.toUpperCase()}{" "}
            ({TSCABBRIVATIONS[control.tsc]})
          </div>
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="flex ">
            <Checkbox
              isSelected={selectedControls.has(control.uid)}
              classNames={{
                base: cn(
                  "group",
                  control.edited_state === "NEW" && "invisible"
                ),
                wrapper: cn(
                  "group-data-[disabled=true]:border-default",
                  "after:group-data-[disabled=true]:bg-default-100"
                ),
                icon: "group-data-[disabled=true]:text-default-300",
              }}
              onChange={() => {
                setSelectedControls((prev) => {
                  const newValue = prev;

                  if (selectedControls.has(control.uid)) {
                    newValue.delete(control.uid);
                  } else {
                    newValue.add(control.uid);
                  }
                  return newValue;
                });
              }}
              data-component="z-table-row-select"
              color={"primary"}
            />
            <ZInputWithOnChangeDelay
              id={`${control.uid}tsc_id`}
              aria-label={`${control.uid}tsc_id`}
              classNames={{
                inputWrapper:
                  "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none active:bg-white",
                base:
                  "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none active:bg-white",
                clearButton:
                  "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none active:bg-white",
                description:
                  "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none active:bg-white",
                errorMessage:
                  "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none active:bg-white",
                helperWrapper:
                  "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none active:bg-white",
                innerWrapper:
                  "bg-transparent p-0 pl-[6px] data-[hover=true]:bg-transparent shadow-none border-none active:bg-white",
                input:
                  "bg-transparent p-[6px] pl-0 data-[hover=true]:bg-transparent shadow-none border-none active:bg-white",
                label:
                  "bg-transparent p-0 data-[hover=true]:bg-transparent shadow-none border-none active:bg-white",
                mainWrapper:
                  "bg-transparent p-0  data-[hover=true]:bg-transparent shadow-none border-none active:bg-white",
              }}
              startContent={TSCABBRIVATIONS[control.tsc]}
              placeholder="Add ID"
              value={control.tsc_id
                .replace(TSCABBRIVATIONS[control.tsc], "")
                .trim()}
              onChange={(e) => {
                // if (true) {
                handleChangeDataState(
                  "tsc_id",
                  control.uid,
                  TSCABBRIVATIONS[control.tsc] + e.target.value.trim()
                );
                // }
              }}
              disabled={searchQuery.length > 0}
              isInvalid={isInIdValid || isDuplicateId}
              onFocus={() => setIsActive(true)}
              onBlur={() => setIsActive(false)}
              autoFocus={false}
            />
          </div>
          <div className="flex flex-row">
            <div className="pl-6">
              {(isInIdValid || isDuplicateId) && (
                <div className="flex flex-row gap-1 p-1.5 ">
                  {isInIdValid && (
                    <Tooltip
                      showArrow={true}
                      content={
                        <div>
                          Expected Format: {TSCABBRIVATIONS[control.tsc]} 1.2.3
                        </div>
                      }
                      delay={500}
                      closeDelay={0}
                      classNames={{
                        content:
                          "bg-black bg-opacity-80 backdrop-blur-md text-white text-tiny max-w-[600px]",
                        arrow: "bg-black bg-opacity-80 backdrop-blur-md",
                      }}
                      placement="bottom-start"
                    >
                      <span>
                        <Info size={16} strokeWidth={1.5} color="#F31260" />
                      </span>
                    </Tooltip>
                  )}
                  <div className="text-[#F31260] text-xs font-medium">
                    {isInIdValid
                      ? "Invalid Format"
                      : isDuplicateId
                      ? "Duplicate ID"
                      : ""}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

interface CustomizeControlCategoryRendererProps extends baseProps {}
export const CustomizeControlCategoryRenderer = ({
  control,
  handleChangeDataState,
  searchQuery,
}: CustomizeControlCategoryRendererProps) => {
  return (
    <div className="w-[240px] pt-3">
      {control.edited_state !== "ACCORDIAN" && (
        <div>
          <ZSelectCategory
            control={control}
            handleChangeDataState={handleChangeDataState}
            searchQuery={searchQuery}
          />
        </div>
      )}
    </div>
  );
};

interface ZSelectCategoryProps {
  control: Control;
  handleChangeDataState: (
    key: keyof Control,
    uId: string,
    newValue: string
  ) => void;
  searchQuery: string;
}

const ZSelectCategory = ({
  control,
  handleChangeDataState,
  searchQuery,
}: ZSelectCategoryProps) => {
  const [isOpen, setisOpen] = useState(false);

  return (
    <div className="flex flex-col">
      <Dropdown
        // aria-expanded={false}
        isDisabled={control.tsc !== TSCENUM.SECURITY}
        classNames={{
          trigger: cn(
            "!scale-[1]  !opacity-100 p-3 px-[6px]",
            isOpen &&
              "bg-white p-[11px] px-[5px] border-1 border-solid border-[#A5A5A5] rounded-[4px]"
          ),
        }}
        onOpenChange={(isOpenChange) => {
          setisOpen(isOpenChange);
        }}
      >
        <DropdownTrigger className="">
          <div className={cn("flex flex-row items-center justify-between")}>
            <div
              className={`text-ellipsis line-clamp-1 ${
                control.category ? "text-zinc-700" : "text-zinc-400"
              } `}
            >
              {control.category
                ? SecurityControlCategoryOption.find(
                    (c) => c.value === control.category
                  )?.label ?? ""
                : control.tsc !== TSCENUM.SECURITY
                ? "N/A"
                : "Select Category"}
            </div>

            <div>
              {control.tsc === TSCENUM.SECURITY && (
                <ChevronDown className="h-5 w-4" />
              )}
            </div>
          </div>
        </DropdownTrigger>
        <DropdownMenu
          selectedKeys={new Set([control.category])}
          selectionMode="single"
        >
          {SecurityControlCategoryOption.map((cat) => (
            <DropdownItem
              key={cat.value}
              onClick={() => {
                if (cat.value === control.category) {
                  handleChangeDataState("category", control.uid, "");
                } else {
                  handleChangeDataState("category", control.uid, cat.value);
                }
              }}
            >
              {cat.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

interface CustomizeControlDescriptionRendererProps extends baseProps {}
export const CustomizeControlDescriptionRenderer = ({
  control,
  handleChangeDataState,
  searchQuery,
}: CustomizeControlDescriptionRendererProps) => {
  const { id = "" } = useParams<{ id: string }>();

  const [isRowActive, setIsRowActive] = useState(false);

  if (control.edited_state === "ACCORDIAN") {
    return null;
  }

  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showAcceptAndRejectPopover, setShowAcceptAndRejectPopover] = useState<
    false | "SUCCESS" | "ERROR"
  >(false);

  const handleClickForRefineControls = useCallback(async () => {
    try {
      setIsLoading(true);
      const agentData = getAgentData(id);
      let response_quality =
        agentData?.sessionData.type === AgentSessionType.SOC2_GAP
          ? agentData?.sessionData.soc2_type2_audit.response_quality
          : agentData?.sessionData.type === AgentSessionType.SOC2_GAP_TYPE1
          ? agentData?.sessionData.soc2_type1.response_quality
          : agentData?.sessionData.type === AgentSessionType.SOC2_GAP_TYPE2
          ? agentData?.sessionData.soc2_type2.response_quality
          : AiResponseType.LITE;

      if (
        response_quality === undefined ||
        response_quality === null ||
        response_quality === AiResponseType.NONE
      ) {
        response_quality = AiResponseType.LITE;
      }

      const resp = await refineControlsDescription({
        task: AgentSessionStepType.SOC2_TYPE2,
        controls: [control],
        response_quality,
      });

      if (resp.data.controls[0].control) {
        handleChangeDataState(
          "new_control",
          control.uid,
          resp.data.controls[0].control
        );
      }

      setIsLoading(false);
      getCustomizeControlsActions().setNoOfGenerateWithAiPopoverOpens(
        (prev) => prev + 1
      );
      setShowAcceptAndRejectPopover("SUCCESS");
    } catch (err) {
      setIsLoading(false);
      getCustomizeControlsActions().setNoOfGenerateWithAiPopoverOpens(
        (prev) => prev + 1
      );
      setShowAcceptAndRejectPopover("ERROR");
      throw new Error("Failed to refine control descroiption ");
    }
  }, [control, handleChangeDataState, id]);

  const handleClickForAcceptRetryRejectButtons = useCallback(
    (action: "CONTINUE" | "DISCARD") => {
      const { setDataState } = getCustomizeControlsActions();

      if (action === "DISCARD") {
        setShowAcceptAndRejectPopover(false);
        getCustomizeControlsActions().setNoOfGenerateWithAiPopoverOpens(
          (prev) => prev - 1
        );
        setDataState((prev) => {
          const newValue = [...prev];

          const findIndexOfEditingControl = newValue.findIndex(
            (d) => d.uid === control.uid
          );
          if (findIndexOfEditingControl > -1) {
            newValue[findIndexOfEditingControl].new_control = undefined;
          }

          return newValue;
        });
      }
      if (action === "CONTINUE") {
        if (showAcceptAndRejectPopover === "ERROR") {
          handleClickForRefineControls();
        }
        if (showAcceptAndRejectPopover === "SUCCESS") {
          setDataState((prev) => {
            const newValue = [...prev];

            const findIndexOfEditingControl = newValue.findIndex(
              (d) => d.uid === control.uid
            );
            if (findIndexOfEditingControl > -1) {
              newValue[findIndexOfEditingControl].control =
                newValue[findIndexOfEditingControl].new_control ??
                newValue[findIndexOfEditingControl].control;
              newValue[findIndexOfEditingControl].new_control = undefined;
              newValue[findIndexOfEditingControl].changesInControl = 0;
              newValue[findIndexOfEditingControl].changesInTestingProcedure = 1;
            }

            return newValue;
          });
          setShowAcceptAndRejectPopover(false);
          getCustomizeControlsActions().setNoOfGenerateWithAiPopoverOpens(
            (prev) => prev - 1
          );
          getCustomizeControlsActions().setChangesMade((prev) => prev + 1); // to trigger autosave
        }
      }
    },
    [handleClickForRefineControls, showAcceptAndRejectPopover, control.uid]
  );

  useEffect(() => {
    const el = document.getElementById("customize-control-table");
    let t: NodeJS.Timeout;
    if (showAcceptAndRejectPopover) {
      if (el) {
        if (
          getCustomizeControlsAiOpenStates() === 0 ||
          getCustomizeControlsAiOpenStates() === 1
        ) {
          containerRef.current?.scrollIntoView({});
          el.scrollBy(0, -50);
        }
        t = setTimeout(() => {
          if (el.classList.contains("overflow-auto")) {
            el.classList.remove("overflow-auto");
            el.classList.add("overflow-hidden");
          }
        }, 500);
      }
    } else if (getCustomizeControlsAiOpenStates() === 0) {
      if (el) {
        if (el.classList.contains("overflow-hidden")) {
          el.classList.add("overflow-auto");
          el.classList.remove("overflow-hidden");
        }
      }
    }
    return () => {
      if (t) {
        clearTimeout(t);
      }
    };
  }, [showAcceptAndRejectPopover]);

  useEffect(() => {
    const focusInHandler = (event: any) => {
      if (event?.target?.id?.includes(control.uid)) {
        setIsRowActive(true);
      }
    };
    const focusOutHandler = () => {
      setIsRowActive(false);
    };
    window.addEventListener("focusin", focusInHandler);
    window.addEventListener("focusout", focusOutHandler);
    return () => {
      window.removeEventListener("focusin", focusInHandler);
      window.removeEventListener("focusout", focusOutHandler);
    };
  }, [control]);

  return (
    <div
      className="h-full min-w-[420px]"
      id={control.control_id}
      ref={containerRef}
    >
      <ZTextAreaWithOnChangeDelay
        id={`${control.uid}control`}
        aria-label={`${control.uid}control`}
        className={cn(
          "text-zinc-700 font-light bg-transparent  focus-within:text-[#000000] mb-[5px]",
          control.new_control !== undefined && "mb-0"
        )}
        classNames={{
          base:
            "shadow-none  border-none hover:border-none rounded-none truncate",
          inputWrapper: ` text-sm font-normal text-zinc-700 p-px ${
            true ? "border-none " : ""
          }  ${
            false ? "text-[#000000]" : "text-zinc-700"
          } border-1 border-[#ECECEC]`,
          input: "p-[4px]",
        }}
        forceActive={isLoading || showAcceptAndRejectPopover !== false}
        strikeContent={
          showAcceptAndRejectPopover !== false
            ? control.new_control !== undefined
            : undefined
        }
        minRows={3}
        maxRows={8}
        disabled={searchQuery.length > 0}
        variant="bordered"
        labelPlacement="outside"
        value={control.control}
        placeholder="Add Control Description"
        onChange={(e) =>
          handleChangeDataState("control", control.uid, e.target.value)
        }
        autoFocus={false}
      />

      {control.new_control !== undefined && (
        <ZTextAreaWithOnChangeDelay
          id={`${control.uid}new_control`}
          aria-label={`${control.uid}new_control`}
          className="text-zinc-700 font-light bg-transparent  focus-within:text-[#000000] mb-[5px]"
          classNames={{
            base:
              "shadow-none  border-none hover:border-none rounded-none truncate",
            inputWrapper: ` text-sm font-normal text-zinc-700 p-px ${
              true ? "border-none " : ""
            }  ${
              false ? "text-[#000000]" : "text-zinc-700"
            } border-0 border-[#ECECEC]`,
            input: "p-[4px]",
          }}
          forceActive={true}
          strikeContent={false}
          minRows={3}
          maxRows={8}
          disabled={searchQuery.length > 0}
          variant="bordered"
          labelPlacement="outside"
          value={control.new_control}
          placeholder="Add Control Description"
          onChange={(e) =>
            handleChangeDataState("new_control", control.uid, e.target.value)
          }
          autoFocus={false}
        />
      )}
      {showAcceptAndRejectPopover && (
        <div className="h-5">
          <AcceptAndRejectPopOver
            isOpen
            type={showAcceptAndRejectPopover}
            handleClickForAcceptRetryRejectButtons={
              handleClickForAcceptRetryRejectButtons
            }
            handleClose={() => setShowAcceptAndRejectPopover(false)}
          />
        </div>
      )}
      {showAcceptAndRejectPopover === false && (
        <div
          className={cn(
            "group-hover:h-5 h-0 overflow-hidden ease-in-out duration-200",
            (isRowActive || isLoading || showAcceptAndRejectPopover) && "h-5"
          )}
        >
          <GenerateWithAI
            contextText={control.control}
            existingText={control.control}
            isLoadingAIResponse={isLoading}
            handleClickForRefineControls={handleClickForRefineControls}
            handleClickForGenerateControls={async () => {}}
            showGenerate={false}
            showRefine={true}
            type="CONTROL_DESCRIPTION"
            disableGenerate={false}
            disableGenerateTooltipMessage=""
            disableRefine={
              (control.changesInControl ?? 0) === 0 ||
              control.control.trim().length === 0
            }
            // disableRefineTooltipMessage="You will be able to refine if new edits are made to control description"
            disableRefineTooltipMessage={[
              "You will be able to refine",
              "if new edits are made ",
              "to control description",
            ]}
          />
        </div>
      )}
    </div>
  );
};

interface CustomizeControlTestingProcedureRendererProps extends baseProps {}
export const CustomizeControlTestingProcedureRenderer = ({
  control,
  handleChangeDataState,
  searchQuery,
}: CustomizeControlTestingProcedureRendererProps) => {
  const { id = "" } = useParams<{ id: string }>();

  const [isRowActive, setIsRowActive] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showAcceptAndRejectPopover, setShowAcceptAndRejectPopover] = useState<
    false | "SUCCESS" | "ERROR"
  >(false);

  const refineControlPreviousCallThatFailedRef = useRef<"CREATE" | "REFINE">();
  const handleClickForRefineControls = useCallback(
    async (type: "CREATE" | "REFINE") => {
      try {
        setIsLoading(true);
        refineControlPreviousCallThatFailedRef.current = type;
        const agentData = getAgentData(id);
        let response_quality =
          agentData?.sessionData.type === AgentSessionType.SOC2_GAP
            ? agentData?.sessionData.soc2_type2_audit.response_quality
            : agentData?.sessionData.type === AgentSessionType.SOC2_GAP_TYPE1
            ? agentData?.sessionData.soc2_type1.response_quality
            : agentData?.sessionData.type === AgentSessionType.SOC2_GAP_TYPE2
            ? agentData?.sessionData.soc2_type2.response_quality
            : AiResponseType.LITE;

        if (
          response_quality === undefined ||
          response_quality === null ||
          response_quality === AiResponseType.NONE
        ) {
          response_quality = AiResponseType.LITE;
        }

        const resp = await refineControlsTestingProcedure({
          task: AgentSessionStepType.SOC2_TYPE2,
          controls: [
            {
              ...control,
              toe: type === "REFINE" ? control.toe : "",
              tod: type === "REFINE" ? control.tod : "",
            },
          ],
          response_quality,
        });

        if (resp.data.controls[0].toe) {
          const agentData = getAgentData(id);
          const newToe =
            agentData?.sessionData.type === AgentSessionType.SOC2_GAP_TYPE1
              ? resp.data.controls[0].tod
              : resp.data.controls[0].toe;
          if (control.toe) {
            handleChangeDataState("new_toe", control.uid, newToe);
            getCustomizeControlsActions().setNoOfGenerateWithAiPopoverOpens(
              (prev) => prev + 1
            );
            setShowAcceptAndRejectPopover("SUCCESS");
          } else {
            handleChangeDataState("toe", control.uid, newToe, -1);
          }
        }

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        getCustomizeControlsActions().setNoOfGenerateWithAiPopoverOpens(
          (prev) => prev + 1
        );
        setShowAcceptAndRejectPopover("ERROR");
        throw new Error("Failed to refine control descroiption ");
      }
    },
    [control, handleChangeDataState, id]
  );

  const handleClickForAcceptRetryRejectButtons = useCallback(
    (action: "CONTINUE" | "DISCARD") => {
      const { setDataState } = getCustomizeControlsActions();

      if (action === "DISCARD") {
        setShowAcceptAndRejectPopover(false);
        getCustomizeControlsActions().setNoOfGenerateWithAiPopoverOpens(
          (prev) => prev - 1
        );
        setDataState((prev) => {
          const newValue = [...prev];

          const findIndexOfEditingControl = newValue.findIndex(
            (d) => d.uid === control.uid
          );
          if (findIndexOfEditingControl > -1) {
            newValue[findIndexOfEditingControl].new_toe = undefined;
          }

          return newValue;
        });
      }
      if (action === "CONTINUE") {
        if (showAcceptAndRejectPopover === "ERROR") {
          handleClickForRefineControls(
            refineControlPreviousCallThatFailedRef.current ?? "REFINE"
          );
        }
        if (showAcceptAndRejectPopover === "SUCCESS") {
          setDataState((prev) => {
            const newValue = [...prev];

            const findIndexOfEditingControl = newValue.findIndex(
              (d) => d.uid === control.uid
            );
            if (findIndexOfEditingControl > -1) {
              newValue[findIndexOfEditingControl].toe =
                newValue[findIndexOfEditingControl].new_toe ??
                newValue[findIndexOfEditingControl].toe;
              newValue[findIndexOfEditingControl].new_toe = undefined;
              newValue[findIndexOfEditingControl].changesInTestingProcedure =
                refineControlPreviousCallThatFailedRef.current === "CREATE"
                  ? -1
                  : 0;
            }

            return newValue;
          });
          setShowAcceptAndRejectPopover(false);
          getCustomizeControlsActions().setNoOfGenerateWithAiPopoverOpens(
            (prev) => prev - 1
          );
          getCustomizeControlsActions().setChangesMade((prev) => prev + 1); // to trigger autosave
        }
      }
    },
    [handleClickForRefineControls, showAcceptAndRejectPopover, control.uid]
  );

  useEffect(() => {
    const el = document.getElementById("customize-control-table");
    let t: NodeJS.Timeout;
    if (showAcceptAndRejectPopover) {
      if (el) {
        if (
          getCustomizeControlsAiOpenStates() === 0 ||
          getCustomizeControlsAiOpenStates() === 1
        ) {
          containerRef.current?.scrollIntoView({});
          el.scrollBy(0, -100);
        }
        t = setTimeout(() => {
          if (el.classList.contains("overflow-auto")) {
            el.classList.remove("overflow-auto");
            el.classList.add("overflow-hidden");
          }
        }, 500);
      }
    } else if (getCustomizeControlsAiOpenStates() === 0) {
      if (el) {
        if (el.classList.contains("overflow-hidden")) {
          el.classList.add("overflow-auto");
          el.classList.remove("overflow-hidden");
        }
      }
    }

    return () => {
      if (t) {
        clearTimeout(t);
      }
    };
  }, [showAcceptAndRejectPopover]);

  useEffect(() => {
    const focusInHandler = (event: any) => {
      if (event?.target?.id?.includes(control.uid)) {
        setIsRowActive(true);
      }
    };
    const focusOutHandler = () => {
      setIsRowActive(false);
    };
    window.addEventListener("focusin", focusInHandler);
    window.addEventListener("focusout", focusOutHandler);
    return () => {
      window.removeEventListener("focusin", focusInHandler);
      window.removeEventListener("focusout", focusOutHandler);
    };
  }, [control]);

  if (control.edited_state === "ACCORDIAN") {
    return null;
  }

  const disableGenerate =
    control.control.trim().length === 0 ||
    (control.changesInControl ?? 0) > 0 ||
    (control.changesInTestingProcedure ?? 0) === -1;

  const disableRefine =
    control.toe.trim().length === 0 ||
    control.control.trim().length === 0 ||
    (control.changesInControl ?? 0) > 0 ||
    (control.changesInTestingProcedure ?? 0) <= 0;

  return (
    <div className="min-w-[372px]" ref={containerRef}>
      <ZTextAreaWithOnChangeDelay
        id={`${control.uid}toe`}
        aria-label={`${control.uid}toe`}
        className={cn(
          "text-zinc-700 font-light bg-transparent  focus-within:text-[#000000] mb-[5px]",
          control.new_toe !== undefined && "mb-0"
        )}
        classNames={{
          base:
            "shadow-none  border-none hover:border-none rounded-none truncate",
          inputWrapper: ` text-sm font-normal text-zinc-700 p-px  ${
            true ? "border-none " : ""
          }  ${false ? "text-[#000000]" : "text-zinc-700"}`,
          input: "p-[4px]",
        }}
        forceActive={isLoading || showAcceptAndRejectPopover !== false}
        strikeContent={
          showAcceptAndRejectPopover !== false
            ? control.new_toe !== undefined
            : undefined
        }
        minRows={3}
        maxRows={8}
        disabled={searchQuery.length > 0}
        variant="bordered"
        labelPlacement="outside"
        value={control.toe}
        placeholder="Add Testing Procedure"
        onChange={(e) =>
          handleChangeDataState("toe", control.uid, e.target.value)
        }
        autoFocus={false}
      />

      {control.new_toe !== undefined && (
        <ZTextAreaWithOnChangeDelay
          id={`${control.uid}new_toe`}
          aria-label={`${control.uid}new_control`}
          className="text-zinc-700 font-light bg-transparent  focus-within:text-[#000000] mb-[5px]"
          classNames={{
            base:
              "shadow-none  border-none hover:border-none rounded-none truncate",
            inputWrapper: ` text-sm font-normal text-zinc-700 p-px ${
              true ? "border-none " : ""
            }  ${
              false ? "text-[#000000]" : "text-zinc-700"
            } border-0 border-[#ECECEC]`,
            input: "p-[4px]",
          }}
          forceActive={true}
          strikeContent={false}
          minRows={3}
          maxRows={8}
          disabled={searchQuery.length > 0}
          variant="bordered"
          labelPlacement="outside"
          value={control.new_toe}
          placeholder="Add Control Description"
          onChange={(e) =>
            handleChangeDataState("new_toe", control.uid, e.target.value)
          }
          autoFocus={false}
        />
      )}
      {showAcceptAndRejectPopover === false && (
        <div
          className={cn(
            "group-hover:h-5 group-active:h-5 h-0 overflow-hidden ease-in-out duration-200 mb-2",
            (isRowActive || isLoading || showAcceptAndRejectPopover) && "h-5"
          )}
        >
          <GenerateWithAI
            contextText={control.control}
            existingText={control.toe}
            isLoadingAIResponse={isLoading}
            handleClickForRefineControls={async () =>
              await handleClickForRefineControls("REFINE")
            }
            handleClickForGenerateControls={async () =>
              await handleClickForRefineControls("CREATE")
            }
            showGenerate={true}
            showRefine={true}
            type="TESTING_PROCEDURE"
            disableGenerate={disableGenerate}
            disableGenerateTooltipMessage={
              (control.changesInControl ?? 0) > 0
                ? [
                    "Refine control description",
                    "with AI to create testing",
                    "procedure using AI",
                  ]
                : [
                    "You will be able to create",
                    "if new edits are made ",
                    "to control description",
                  ]
            }
            disableRefine={disableRefine}
            disableRefineTooltipMessage={
              (control.changesInControl ?? 0) > 0
                ? [
                    "Refine control description",
                    "with AI to refine testing",
                    "procedure using AI",
                  ]
                : [
                    "You will be able to refine",
                    "if new edits are made ",
                    "to testing procedure",
                  ]
            }
          />
        </div>
      )}
      {showAcceptAndRejectPopover && (
        <AcceptAndRejectPopOver
          isOpen
          type={showAcceptAndRejectPopover}
          handleClickForAcceptRetryRejectButtons={
            handleClickForAcceptRetryRejectButtons
          }
          handleClose={() => setShowAcceptAndRejectPopover(false)}
        />
      )}
    </div>
  );
};

import { getZaniaSocket } from "@/infra/sockets";
import { addNotification } from "@/shared/states/notification";
import { useCallback, useEffect, useRef } from "react";

const PING_INTERVAL = 45000;

export const useControlEvents = (
  sessionId: string,
  controlId: string | null,
  userId: string | undefined
) => {
  const pingIntervalRef = useRef<NodeJS.Timeout>();

  const emitControlOpen = useCallback(() => {
    try {
      const EVENT_ID = "shared:doc:control:opened";
      const socket = getZaniaSocket();

      if (!controlId || !userId) return;

      const payload = {
        control_id: controlId,
        agent_session_id: sessionId
      };

      socket.emit(EVENT_ID, payload, (error: Error) => {
        if (error) {
          console.error('Error emitting control opened:', error);
        }
      });
    } catch (error) {
      console.error('Error in emitControlOpen:', error);
    }
  }, [sessionId, controlId, userId]);

  const startPinging = useCallback(() => {
    if (pingIntervalRef.current) {
      clearInterval(pingIntervalRef.current);
    }
    pingIntervalRef.current = setInterval(emitControlOpen, PING_INTERVAL);
  }, [emitControlOpen]);

  const stopPinging = useCallback(() => {
    if (pingIntervalRef.current) {
      clearInterval(pingIntervalRef.current);
      pingIntervalRef.current = undefined;
    }
  }, []);

  const emitControlOpened = useCallback(() => {
    emitControlOpen();
    startPinging();
  }, [emitControlOpen, startPinging]);

  const emitControlClosed = useCallback(() => {
    try {
      stopPinging();
      const EVENT_ID = "shared:doc:control:closed";
      const socket = getZaniaSocket();

      if (!controlId || !userId) return;

      const payload = {
        control_id: controlId,
        agent_session_id: sessionId
      };

      socket.emit(EVENT_ID, payload, (error: Error) => {
        if (error) {
          console.error('Error emitting control closed:', error);
        }
      });
    } catch (error) {
      console.error('Error in emitControlClosed:', error);
    }
  }, [sessionId, controlId, userId, stopPinging]);

  useEffect(() => {
    return () => {
      stopPinging();
    };
  }, [stopPinging]);

  return {
    emitControlOpened,
    emitControlClosed
  };
};
import { ResponseSchemaVersion } from "../types/risk-and-gap";
import { convertSnakeToCapitalized } from "./snake-to-capital";

export const getFieldTitle = (key: string, version?: ResponseSchemaVersion) => {
  switch (key) {
    case "assessment_approach_presentation":
      return "Assessment Approach";
    case "sources":
      return version === "v2" ? "Other References" : "References";
    default:
      return convertSnakeToCapitalized(key);
  }
};

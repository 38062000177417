import { OrganizationTrustedMetaData } from "@/modules/team/types";

export interface MagicLinkRequest {
  email_id: string;
}

export interface MagicLinkResponse {
  success: boolean;
  //FIXME
  payload: any;
}

export interface AuthenticateTokenRequest {
  token: string;
}

export interface ValidateSessionTokenRequest {
  session_token: string;
}

export interface TokenResponse {
  success: boolean;
  session_data: SessionData;
  payload: any;
}

export interface SessionData {
  status_code: number;
  request_id: string;
  session: Session;
  session_token: string;
  session_jwt: string;
  user: User;
}

export interface Session {
  session_id: string;
  user_id: string;
  authentication_factors: AuthenticationFactor[];
  started_at: string;
  last_accessed_at: string;
  expires_at: string;
  attributes: Attributes;
  custom_claims: CustomClaims;
}

export interface AuthenticationFactor {
  type: string;
  delivery_method: string;
  last_authenticated_at: string;
  created_at: string;
  updated_at: string;
  email_factor: EmailFactor;
  phone_number_factor: any;
  google_oauth_factor: any;
  microsoft_oauth_factor: any;
  apple_oauth_factor: any;
  webauthn_factor: any;
  authenticator_app_factor: any;
  github_oauth_factor: any;
  recovery_code_factor: any;
  facebook_oauth_factor: any;
  crypto_wallet_factor: any;
  amazon_oauth_factor: any;
  bitbucket_oauth_factor: any;
  coinbase_oauth_factor: any;
  discord_oauth_factor: any;
  figma_oauth_factor: any;
  git_lab_oauth_factor: any;
  instagram_oauth_factor: any;
  linked_in_oauth_factor: any;
  shopify_oauth_factor: any;
  slack_oauth_factor: any;
  snapchat_oauth_factor: any;
  spotify_oauth_factor: any;
  steam_oauth_factor: any;
  tik_tok_oauth_factor: any;
  twitch_oauth_factor: any;
  twitter_oauth_factor: any;
  embeddable_magic_link_factor: any;
  biometric_factor: any;
  saml_sso_factor: any;
  oidc_sso_factor: any;
  salesforce_oauth_factor: any;
  yahoo_oauth_factor: any;
}

export interface EmailFactor {
  email_id: string;
  email_address: string;
}

export interface Attributes {
  ip_address: string;
  user_agent: string;
}

export type CustomClaims = {};

export interface User {
  user_id: string;
  emails: Email[];
  status: string;
  phone_numbers: any[];
  webauthn_registrations: any[];
  providers: any[];
  totps: any[];
  crypto_wallets: any[];
  biometric_registrations: any[];
  name: Name;
  created_at: string;
  password: any;
  trusted_metadata: TrustedMetadata;
  untrusted_metadata: UntrustedMetadata;
}

export interface Email {
  email_id: string;
  email: string;
  verified: boolean;
}

export interface Name {
  first_name: string;
  middle_name: string;
  last_name: string;
}

export type TrustedMetadata = {};

export type UntrustedMetadata = {};

export enum LOGIN_STEP {
  MAIN = "main",
  EMAIL_SUCCESS = "email_success",
  SELECT_ORG = "select_org",
  // NEW_ORG = "new_org",
  ERROR = "error",
  CONTACT_ADMIN = "contact_admin",
  SSO = "sso",
  AUTHENTICATING = "authenticating",
  ACCESS_RESTRICTED = "access_restricted",
}

export type ListOfOrganizationByEmail = {
  success: boolean;
  message: string;
  payload: string[];
};

type OAuthRegistration = {
  provider_type: string;
  provider_subject: string;
  member_oauth_registration_id: string;
  profile_picture_url: string | null;
  locale: string | null;
};

type RoleSource = {
  type: string;
  details: Record<string, unknown>;
};

type Role = {
  role_id: string;
  sources: RoleSource[];
};

export type LoggedInMember = {
  organization_id: string;
  member_id: string;
  email_address: string;
  status: string;
  name: string;
  sso_registrations: any[]; // Could be defined further if needed
  is_breakglass: boolean;
  member_password_id: string;
  oauth_registrations: OAuthRegistration[];
  email_address_verified: boolean;
  mfa_phone_number_verified: boolean;
  is_admin: boolean;
  totp_registration_id: string;
  scim_registrations: any[]; // Could be defined further if needed
  mfa_enrolled: boolean;
  mfa_phone_number: string;
  default_mfa_method: string;
  roles: Role[];
  trusted_metadata: any;
  untrusted_metadata: any;
};

type SSOActiveConnection = {
  connection_id: string;
  display_name: string;
};

type RBACEmailImplicitRoleAssignment = {
  domain: string;
  role_id: string;
};

export type LoggedInOrganization = {
  organization_id: string;
  organization_name: string;
  organization_logo_url: string;
  organization_slug: string;
  sso_jit_provisioning: string;
  sso_jit_provisioning_allowed_connections: string[];
  sso_active_connections: SSOActiveConnection[];
  email_allowed_domains: string[];
  email_jit_provisioning: string;
  email_invites: string;
  auth_methods: string;
  allowed_auth_methods: string[];
  mfa_policy: string;
  rbac_email_implicit_role_assignments: RBACEmailImplicitRoleAssignment[];
  mfa_methods: string;
  allowed_mfa_methods: string[];
  scim_active_connections: any[];
  trusted_metadata: OrganizationTrustedMetaData;    
  sso_default_connection_id: string;
};

export type LoggedInMemberWithOrg = {  token_expiry: string;
  member: LoggedInMember;
  organization: LoggedInOrganization;
};

interface Membership {
  type: string;
  details: null | string;
  member: LoggedInMember;
}

export interface Organization {
  organization_id: string;
  organization_name: string;
  organization_logo_url: string;
  organization_slug: string;
  sso_jit_provisioning: string;
  sso_jit_provisioning_allowed_connections: any[];
  sso_active_connections: Array<{ connection_id: string; display_name: string }>;
  email_allowed_domains: string[];
  email_jit_provisioning: string;
  email_invites: string;
  auth_methods: string;
  allowed_auth_methods: any[];
  mfa_policy: string;
  rbac_email_implicit_role_assignments: any[];
  mfa_methods: string;
  allowed_mfa_methods: any[];
  scim_active_connections: any[];
  trusted_metadata: Record<string, any>;
  sso_default_connection_id: string | null;
}

export interface DiscoveredOrganization {
  member_authenticated: boolean;
  organization: Organization;
  membership: Membership;
  primary_required: null | string;
  mfa_required: null | string;
}

export interface OrganiaztionListResponse {
  success: boolean;
  email_address: string;
  discovered_organizations: DiscoveredOrganization[];
}

export interface GenerateMagicLinkResponse {
  details: string;
  error: string;
}
export interface InitialAuthenticationAndOrganizationList {
  success: boolean;
  message: string;
  intermediate_session_token: string;
  discovered_organizations: DiscoveredOrganization[];
}

export interface ErrorResponse {
  error_type: string;
  error_message: string;
}


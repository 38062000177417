import { del, patch, post } from "@/infra/rest";
import { InviteApiResponse, InviteTeamApiPayload, RevokeMemberApiResponse, UpdateTeamApiPayload } from "../types";

export const deleteTeamMember = (memberId: string) => {
  return del<RevokeMemberApiResponse>({
    url: `/v0/auth/member/${memberId}`,
  });
};

export const updateTeamMember = (data: UpdateTeamApiPayload) => {
  return patch<any>({
    url: `/v0/auth/member`,
    config: {
      data: data
    }
  });
};

export const inviteTeamMember = (data: InviteTeamApiPayload) => {
  return post<any, InviteApiResponse>({
    url: `/v0/auth/invite`,
    data: data
  });
};

import { NextUIProvider } from "@nextui-org/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LDProvider } from 'launchdarkly-react-client-sdk';
import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./modules/app/components/App.tsx";
import { NotificationsProvider } from "./shared/components/custom/notification/provider.tsx";

import BugsnagPerformance from "@bugsnag/browser-performance";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact, {
  BugsnagErrorBoundary,
  BugsnagPluginReactResult,
} from "@bugsnag/plugin-react";

export const queryClient = new QueryClient();

let ErrorBoundary: BugsnagErrorBoundary | undefined = undefined;
let bugsnagPlugin: BugsnagPluginReactResult | undefined;
let needBoundary = false;

// only enable bugsnag and posthog in production
export const shouldEnableInsights = () => {
  const hostname = window.location.hostname;
  const whitelist = ["zania.app", "www.zania.app"];

  return whitelist.includes(hostname);
};

if (shouldEnableInsights()) {
  Bugsnag.start({
    apiKey: "7c862cdc6a93291d2c547aedea58f346",
    plugins: [new BugsnagPluginReact()],
  });
  BugsnagPerformance.start({ apiKey: "7c862cdc6a93291d2c547aedea58f346" });
  bugsnagPlugin = Bugsnag.getPlugin("react");
  ErrorBoundary = bugsnagPlugin?.createErrorBoundary
    ? bugsnagPlugin.createErrorBoundary(React)
    : undefined;
  needBoundary = true;
} else {
  ErrorBoundary = undefined;
  bugsnagPlugin = undefined;
  needBoundary = false;
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <NextUIProvider>
        <NotificationsProvider maxNotifications={2} />
        <LDProvider clientSideID="678f7f2f964d1a0a4093fa94">
          {needBoundary && ErrorBoundary ? (
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          ) : (
            <App />
          )}
        </LDProvider>
      </NextUIProvider>
    </QueryClientProvider>
  </StrictMode>
);

/* eslint-disable no-constant-condition */
/* eslint-disable prettier/prettier */
import ErrorBoundary from "@/shared/components/error-boundary";

import { useEffect } from "react";
import { getCustomizeControlsActions } from "../../states/cusomize-controls";
import CustomizeControlsAutoSaveComponent from "./CustomizeControlsAutoSaveComponent";
import CustomizeControlsTable from "./CustomizeControlsTable";
import CustomizeControlsTabs from "./CustomizeControlsTabs";
import CustomizeControlTopSection from "./CustomizeControlTopSection";

const CustomizeControls = () => {
  useEffect(() => {
    return () => {
      getCustomizeControlsActions().reset();
    };
  }, []);

  return (
    <ErrorBoundary fallback={null}>
      <div className="h-full w-full overflow-hidden rounded-lg flex flex-col">
        <CustomizeControlTopSection />
        <CustomizeControlsTabs />
        <CustomizeControlsTable />
      </div>
      <CustomizeControlsAutoSaveComponent />
    </ErrorBoundary>
  );
};

export default CustomizeControls;

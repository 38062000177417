import { AGENT_TYPES, type AgentSubTypes, GapAssessmentTypes } from "../types";

interface ControlData {
  key: string;
  value: string | number | boolean;
}

export const getControlId = (
  selectedData: ControlData[] | null | undefined,
  agentType: AGENT_TYPES,
  agentSubType: AgentSubTypes,
  selectedId: string
): string | null => {
  if (!selectedData) return null;

  switch (agentType) {
    case AGENT_TYPES.RISK_ASSESSMENT: {
      return selectedData.find((item) => item.key === "id")?.value as string;
    }
    case AGENT_TYPES.GAP_ASSESSMENT: {
      switch (agentSubType) {
        case GapAssessmentTypes.SOC2_TYPE1:
        case GapAssessmentTypes.SOC2_TYPE2:
        case GapAssessmentTypes.SOC2:
        case GapAssessmentTypes.DORA: {
          return selectedData.find((item) => item.key === "id")?.value as string;
        }
        default:
          return selectedId;
      }
    }
    case AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT: {
      return selectedData.find((item) => item.key === "key")?.value as string;
    }
    case AGENT_TYPES.QUESTIONNAIRE: {
      return selectedData.find((item) => item.key === "question_id")?.value as string;
    }
    default:
      return selectedId;
  }
};